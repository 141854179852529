import React, { useState, useEffect, Fragment, useRef } from "react";
import { Select, Button, Modal, Spin, Pagination, Table } from "antd";

import { Tabs, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
// import MultiRangeSlider from "../../contacts/MultiRangeSlider"
import parse from "html-react-parser";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
// import Video_icon from "../../assets/images/icons/video_icon.svg";
import Video_icon from "../../assets/images/icons/VideoButton.jpg";
import Printicon from "../../assets/images/icons/printicon.svg";
import drophint from "../../assets/images/choose-setting/drophint.png";
import calender from "../../assets/images/choose-setting/calender.svg";
import emailicon from "../../assets/images/choose-setting/emailicon.svg";
import infoblack from "../../assets/images/choose-setting/infoblack.svg";

import leftArrow from "../../assets/images/choose-setting/left__arrow.png";
import rightArrow from "../../assets/images/choose-setting/right__arrow.png";

import shareBlue from "../../assets/images/choose-setting/share__blue.png";
import shareFacebook from "../../assets/images/choose-setting/shareIcons_02.png";
import sharePintrest from "../../assets/images/choose-setting/shareIcons_03.png";
import shareTwitter from "../../assets/images/choose-setting/shareIcons_04.png";
import InstaIcon from "../../assets/images/icons/insta__icon.svg";
import FaceBookLike from "../../assets/images/icons/facebook-like.jpg";

import DtlFacebook from "../../assets/images/fb__icon.svg";
import DtlTwitter from "../../assets/images/twitter__icon.svg";
import DtlPintrest from "../../assets/images/pintrest__icon.svg";
import DtlInstagram from "../../assets/images/instragm__icon.svg";
import DtlGooglePlus from "../../assets/images/google__plus__icon.svg";

import GIA from "../../assets/images/gia.jpg";
import AGS from "../../assets/images/ags.jpg";
import EGL from "../../assets/images/egl.jpg";
import IGI from "../../assets/images/igi.jpg";
import AllImg from "../../assets/images/img5.jpg";

import { handleHideViewPage } from "../../actions/PageNavigations/PageNavigations";
import { useDispatch, useSelector } from "react-redux";
import SelectYourDiamondService from "../../services/select-your-diamond.service";
import { NotificationManager } from "react-notifications";
import {
  handleComaparePageIds,
  handleSetDiamondDetails,
} from "../../actions/selectyourdiamond/selectYourDiamond";
import {
  handleShowViewPage,
  handleSetPage,
} from "../../actions/PageNavigations/PageNavigations";
import Eye_icon from "../../assets/images/eye__icon.svg";
import Info_icon from "../../assets/images/info_icon.png";

import { handleSwitchChange } from "../../actions/PreviewColor/previewColor";
import colorPanelService from "../../services/color-panel.service";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";

export const CompleteYourRing = (props) => {
  const navigate = useNavigate();
  const search = useLocation().search;
  const scrollViewRef = useRef();
  const { Option } = Select;
  const Details = useSelector((state) => state.selectYourDiamondReducer);
  const loginDetails = useSelector((state) => state.loginDetailsReducer);
  const dispatch = useDispatch();
  const [diamondDetails, setDiamondDetails] = useState({});
  const [dealerId, setDealerId] = useState();
  const [diamondInfoDetails, setDiamondInfoDetails] = useState({});
  const [viewStateLoading, setViewStateLoading] = useState(false);
  const [iframeLoading, setIframeLoading] = useState(true);
  const [blockSelect, setBlockSelect] = useState("imageFileName");
  const [showSocialIcons, setShowSocialIcons] = useState(false);
  const [loading, setLoading] = useState(false);
  const [locationLoading, setLocationLoading] = useState(false);
  const [list, setList] = useState([]);
  const [rowDetails, setRowDetails] = useState({});
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(6);
  const [TotalRowCount, setTotalRowCount] = useState(0);
  const [shapeList, setShapeList] = useState();
  const [pageName, setPageName] = useState("");
  const [showDealerInfo, setShowDealerInfo] = useState(false);
  const [dealerInfo, setDealerInfo] = useState({});
  const [dealerInfo1, setDealerInfo1] = useState({});
  const [locationData, setLocationData] = useState([]);
  const [locationDataLoading, setLocationDataLoading] = useState(false);
  const [hoverInfo, setHoverInfo] = useState(false);
  const [hoverDealerId, setDealerHoverId] = useState();
  const [optionLoading, setShowOptionsLoading] = useState(false);
  const [optionData, setOptionData] = useState({});
  const [similarToogle, setSimilarToogle] = useState(true);

  const isSocialPage = new URLSearchParams(search).get("isSocialPage");
  const paramdealerId = new URLSearchParams(search).get("DealerID");
  const diamondId = new URLSearchParams(search).get("diamondId");
  const socialpageNo = new URLSearchParams(search).get("pageNo");
  const socialpageSize = new URLSearchParams(search).get("pageSize");
  const isAllowed = new URLSearchParams(search).get("isAllowed");
  const socialpageName = new URLSearchParams(search).get("pageName");
  const socialshapeFilters = new URLSearchParams(search).get("shapeFilters");
  const socialtxtCutlet = new URLSearchParams(search).get("txtCutlet");
  const socialtxtClarity = new URLSearchParams(search).get("txtClarity");
  const socialintColorPriority = new URLSearchParams(search).get(
    "intColorPriority "
  );
  const shapeFilters = new URLSearchParams(search).get("shapeFilters");
  const [tableClassAllow, setTableClassAllow] = useState(false);

  /* Color Individually Start */

  const [isLightHeaderColor, setIsLightHeaderColor] = useState(false);
  const [isLightButtonColor, setIsLightButtonColor] = useState(false);
  const [isLightHoverColor, setIsLightHoverColor] = useState(false);
  const [wrapperLoading, setWrapperLoading] = useState(false);

  const previewcolor = useSelector((state) => state.previewColorReducer);
  const [varColorObj, setVarColorObj] = useState({
    callToActionColor: "",
    hoverColor: "",
    columnHeaderAccentColor: "",
    linkColor: "",
  });
  const [toggle, setToggle] = useState(true);
  useEffect(() => {
    if (paramdealerId) {
      handleGetColorsData(loginDetails.dealerId);
    }
  }, [previewcolor.toogle]);

  const handleGetColorsData = async (id) => {
    let inputData = {
      dealerID: Number(id),
      moduleName: "DiamondLink",
      hoverEffect: "",
      columnHeaderAccent: "",
      linkColor: "",
      actionButton: "",
    };
    setWrapperLoading(true);
    try {
      await colorPanelService
        .GetHoverEffect(inputData)
        .then((response) => {
          let msg = response.data.message;
          let initialColorObj =
            response.data.responseData.initialHoverEffectList[0];
          let newColorObj =
            response.data.responseData.ringBuilder_HoverEffect_List[0];
          let colorObj = {
            hoverEffect: "",
            columnHeaderAccent: "",
            linkColor: "",
            actionButton: "",
          };

          if (msg == "Success") {
            if (initialColorObj) {
              var tempobj = colorObj;
              tempobj.hoverEffect = newColorObj
                ? newColorObj.hoverEffect == ""
                  ? initialColorObj.hoverEffect
                  : newColorObj.hoverEffect
                : initialColorObj.hoverEffect;
              tempobj.columnHeaderAccent = newColorObj
                ? newColorObj.columnHeaderAccent == ""
                  ? initialColorObj.columnHeaderAccent
                  : newColorObj.columnHeaderAccent
                : initialColorObj.columnHeaderAccent;
              tempobj.linkColor = newColorObj
                ? newColorObj.linkColor == ""
                  ? initialColorObj.linkColor
                  : newColorObj.linkColor
                : initialColorObj.linkColor;
              tempobj.actionButton = newColorObj
                ? newColorObj.actionButton == ""
                  ? initialColorObj.actionButton
                  : newColorObj.actionButton
                : initialColorObj.actionButton;
              debugger;
              console.log(tempobj);
              console.log(initialColorObj);
              if (tempobj) {
                setVarColorObj({
                  ...varColorObj,
                  callToActionColor: tempobj.actionButton,
                  hoverColor: tempobj.hoverEffect,
                  columnHeaderAccentColor: tempobj.columnHeaderAccent,
                  linkColor: tempobj.linkColor,
                });
                lightOrDark(tempobj.columnHeaderAccent, "header");
                lightOrDark(tempobj.callToActionColor, "button");
                lightOrDark(tempobj.hoverColor, "hover");
              } else {
                setVarColorObj({
                  ...varColorObj,
                  callToActionColor: initialColorObj.actionButton,
                  hoverColor: initialColorObj.hoverEffect,
                  columnHeaderAccentColor: initialColorObj.columnHeaderAccent,
                  linkColor: initialColorObj.linkColor,
                });
                lightOrDark(initialColorObj.columnHeaderAccent, "header");
                lightOrDark(initialColorObj.callToActionColor, "button");
                lightOrDark(initialColorObj.hoverColor, "hover");
              }
            }
          }
          setWrapperLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setWrapperLoading(false);
        });
    } catch (error) {
      console.log(error);
      setWrapperLoading(false);
    }
  };

  function lightOrDark(color, type) {
    // Variables for red, green, blue values
    var r, g, b, hsp;

    if (color) {
      // Check the format of the color, HEX or RGB?
      if (color.match(/^rgb/)) {
        // If RGB --> store the red, green, blue values in separate variables
        color = color.match(
          /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
        );

        r = color[1];
        g = color[2];
        b = color[3];
      } else {
        // If hex --> Convert it to RGB: http://gist.github.com/983661
        color = +(
          "0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&")
        );

        r = color >> 16;
        g = (color >> 8) & 255;
        b = color & 255;
      }

      // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
      hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

      // Using the HSP value, determine whether the color is light or dark
      // if hsp> 127.5 then it is dark
      if (hsp > 127.5) {
        if (type == "header") {
          setIsLightHeaderColor(true);
        } else if (type == "button") {
          setIsLightButtonColor(true);
        } else {
          setIsLightHoverColor(true);
        }
      } else {
        if (type == "header") {
          setIsLightHeaderColor(false);
        } else if (type == "button") {
          setIsLightButtonColor(false);
        } else {
          setIsLightHoverColor(false);
        }
      }
    }
  }

  const Wrapper = styled.div`
    & .whole__shapes .shapes__block .image:hover {
      border: 1px solid ${varColorObj.hoverColor};
    }

    & .whole__shapes .shapes__block:hover .image {
      border: 1px solid ${varColorObj.hoverColor};
    }

    & .whole__shapes .shapes__block .image .selected {
      border: 1px solid ${varColorObj.linkColor};
    }

    & .whole__shapes .shapes__block.selected .image {
      border: 1px solid ${varColorObj.linkColor};
    }

    & .img__block .imgselct__div:hover {
      border-bottom: 2px solid ${varColorObj.hoverColor};
    }

    & .img__block .imgselct__div.selected {
      border-bottom: 2px solid ${varColorObj.linkColor};
    }

    & .selection__block .selection__box:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background-color: ${varColorObj.hoverColor};
    }
    & .selection__block .selection__box.selected {
      border: 1px solid ${varColorObj.linkColor};
      background-color: ${varColorObj.linkColor};
    }

    & .ant-table-thead > tr > th {
      background: ${varColorObj.columnHeaderAccentColor} !important;
      font-family: "LexendDeca-Semibold";
      height: 50px;
      color: ${isLightHeaderColor ? "#3b4452" : "#ffffff"} !important;
      line-height: 14px;
    }

    & .ant-btn-primary {
      background: ${varColorObj.callToActionColor} !important;
      border-color: ${varColorObj.callToActionColor} !important;
    }
    & .ant-btn-primary:hover {
      background: ${varColorObj.hoverColor} !important;
      border-color: ${varColorObj.hoverColor} !important;
    }

    & .primary-btn {
      background: ${varColorObj.callToActionColor} !important;
      border-radius: 5px !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .primary-btn:hover {
      background: ${varColorObj.hoverColor} !important;
      border-radius: 5px !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .ant-btn {
      background: ${varColorObj.callToActionColor} !important;
      border-radius: 5px !important;
      color: ${isLightButtonColor ? "#3b4452" : "#fff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .ant-btn:hover {
      background: ${varColorObj.hoverColor} !important;
      border-radius: 5px !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }
    & .ant-input-search-button {
      background: #fff !important;
      border: 1px solid #d9d9d9;
      border-radius: 0 !important;
      height: 32px;
      border-left: 0;
    }

    & .ant-input-search-button:hover {
      background: #fff !important;
      border: 1px solid #d9d9d9;
      border-radius: 0 !important;
      height: 32px;
      border-left: 0;
    }
    & .linkText {
      color: ${varColorObj.linkColor};
      text-decoration: none;
      cursor: pointer;
    }

    & .linkText:hover {
      color: ${varColorObj.hoverColor};
    }

    & .iframe__block .itemdetails__btns p:hover img {
      filter: invert(64%) sepia(29%) saturate(4028%) hue-rotate(164deg)
        brightness(93%) contrast(89%);
      color: ${varColorObj.hoverColor};
    }

    & .iframe__block .itemdetails__btns p:hover span {
      color: ${varColorObj.hoverColor};
    }

    & .ant-pagination-item-active {
      border: 1px solid ${varColorObj.linkColor} !important;
    }

    & .ant-pagination-item:hover {
      border: 1px solid ${varColorObj.hoverColor};
    }
    & .nav-tabs .nav-link.active {
      background: ${varColorObj.columnHeaderAccentColor} !important;
      color: ${isLightHeaderColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .list_icon.active {
      border: 1px solid ${varColorObj.callToActionColor};
      background: ${varColorObj.callToActionColor} !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .grid_icon.active {
      border: 1px solid ${varColorObj.callToActionColor};
      background: ${varColorObj.callToActionColor} !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .grid_icon:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background: ${varColorObj.hoverColor} !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .list_icon:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background: ${varColorObj.hoverColor} !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .ant-input-search-button {
      color: rgba(0, 0, 0, 0.45) !important;
    }
    & .ant-input-search-button:hover {
      color: rgba(0, 0, 0, 0.45) !important;
    }
  `;

  /* Color Individually Ends */

  const handleSocialLinks = (url) => {
    if (url.includes("https")) {
      window.open(url, "_blank");
    } else if (url.includes("http")) {
      window.open(url, "_blank");
    } else {
      window.open("https://" + url, "_blank");
    }
  };

  useEffect(() => {
    if (isSocialPage == "True") {
      debugger;
      const diamondDetails = {
        shapeFilters: socialshapeFilters,
        txtCutlet: socialtxtCutlet,
        txtClarity: socialtxtClarity,
        intColorPriority: socialintColorPriority,
        pageName: socialpageName,
      };
      debugger;
      setDealerId(paramdealerId);
      setDiamondDetails(diamondDetails);
      handleGetDiamondLinkFiltersViewStateData(paramdealerId, diamondId);
      handleGetSimilarDiamond(paramdealerId, diamondDetails, socialpageNo);
      handleGetDealerLocationData(paramdealerId, diamondId);
      GetLocationLoadDetails(paramdealerId);
      handleGetOptionsDetails(paramdealerId);
      handleGetColorsData(paramdealerId);
      console.log("Social Link Activated");
    } else {
      debugger;
      if (loginDetails) {
        if (loginDetails.dealerId) {
          setDealerId(loginDetails.dealerId);
          if (Details.diamondDetails) {
            setDiamondDetails(Details.diamondDetails);
            handleGetDiamondLinkFiltersViewStateData(
              loginDetails.dealerId,
              Details.diamondDetails.intDiamondId
            );
            handleGetOptionsDetails(loginDetails.dealerId);
            handleGetSimilarDiamond(
              loginDetails.dealerId,
              Details.diamondDetails,
              pageNo
            );
          }
          handleGetDealerLocationData(
            loginDetails.dealerId,
            Details.diamondDetails.intDiamondId
          );
          GetLocationLoadDetails(loginDetails.dealerId);
        }
      }
    }
  }, [similarToogle]);

  const handleGetOptionsDetails = async (id) => {
    let inputData = {
      dealerID: Number(id),
      type: "DiamondLink",
    };
    setShowOptionsLoading(true);
    try {
      await SelectYourDiamondService.GetOptionsDetails(inputData)
        .then((response) => {
          setShowOptionsLoading(false);
          let msg = response.data.message;
          let responseData = response.data.responseData[0];
          if (responseData) {
            setOptionData(responseData);
          } else {
            setOptionData({});
          }
        })
        .catch((error) => {
          console.log(error);
          setShowOptionsLoading(false);
        });
    } catch (error) {
      console.log(error);
      setShowOptionsLoading(false);
    }
  };

  const hideSpinner = () => {
    setIframeLoading(false);
  };

  const handleGetDealerLocationData = (id, retailerID) => {
    try {
      let inputData = {
        dealerID: id.toString(),
        myRetailerID: Number(720),
      };
      setLocationDataLoading(true);
      SelectYourDiamondService.GetDealerLocationData(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            setLocationData(responseData);
            setLocationDataLoading(false);
          } else {
            setLocationData({});
            setLocationDataLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLocationDataLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLocationDataLoading(false);
    }
  };

  const [locationDetails, setLocationDetails] = useState([]);
  const GetLocationLoadDetails = (id) => {
    let inputData = {
      dealerID: id,
    };
    setLocationLoading(true);
    try {
      SelectYourDiamondService.GetLocationDetails(inputData)
        .then((response) => {
          setLocationLoading(false);
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            setLocationDetails(responseData.Table);

            setLocationLoading(false);
          } else {
            setLocationDetails([]);
            setLocationLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLocationLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLocationLoading(false);
    }
  };

  const handleGetSimilarDiamond = (dealerId, DiamondDetails, page) => {
    setRowDetails(DiamondDetails);
    setShapeList(DiamondDetails.shapeFilters);
    setPageName(DiamondDetails.pageName);
    handleSimilarDiamondList(
      dealerId,
      DiamondDetails,
      page,
      DiamondDetails.pageName
    );
  };

  function onChange(page, pageSize) {
    if (page !== pageNo) {
      setPageNo(page);
      var obj = rowDetails;
      obj["pageName"] = "Mined";
      obj["shapeFilters"] = shapeList;
      handleSimilarDiamondList(dealerId, rowDetails, page, pageName);
    }
  }

  const handleSimilarDiamondList = (id, DiamondDetails, page, PageName) => {
    debugger;
    console.log(DiamondDetails);

    var ValColor = 0;
    var ValClarity = 0;
    var ValCut = 0;
    if (DiamondDetails.intColorPriority == "D") {
      ValColor = 68;
    } else if (DiamondDetails.intColorPriority == "E") {
      ValColor = 69;
    } else if (DiamondDetails.intColorPriority == "F") {
      ValColor = 70;
    } else if (DiamondDetails.intColorPriority == "G") {
      ValColor = 71;
    } else if (DiamondDetails.intColorPriority == "h") {
      ValColor = 72;
    } else if (DiamondDetails.intColorPriority == "I") {
      ValColor = 73;
    } else if (DiamondDetails.intColorPriority == "J") {
      ValColor = 74;
    } else if (DiamondDetails.intColorPriority == "K") {
      ValColor = 74;
    } else if (DiamondDetails.intColorPriority == "L") {
      ValColor = 74;
    } else if (DiamondDetails.intColorPriority == "M") {
      ValColor = 74;
    } else {
      ValColor = 75;
    }
    console.log(ValColor);
    var ValColorLess = ValColor - 1;
    var ValColorMore = ValColor + 1;
    var ColorList = "";
    if (ValColorLess <= 68) {
      ColorList = ValColor + "," + ValColorMore;
    } else if (ValColorMore > 77) {
      ColorList = ValColorLess + "," + ValColor;
    } else if (ValColorMore > 77 && ValColorLess <= 68) {
      ColorList = ValColor;
    } else {
      ColorList = ValColorLess + "," + ValColor + "," + ValColorMore;
    }
    console.log(ColorList);

    if (DiamondDetails.txtClarity == "IF") {
      ValClarity = 1;
    } else if (DiamondDetails.txtClarity == "VVS1") {
      ValClarity = 2;
    } else if (DiamondDetails.txtClarity == "VVS2") {
      ValClarity = 3;
    } else if (DiamondDetails.txtClarity == "VS1") {
      ValClarity = 4;
    } else if (DiamondDetails.txtClarity == "VS2") {
      ValClarity = 5;
    } else if (DiamondDetails.txtClarity == "SI1") {
      ValClarity = 6;
    } else if (DiamondDetails.txtClarity == "SI2") {
      ValClarity = 7;
    } else if (DiamondDetails.txtClarity == "SI3") {
      ValClarity = 8;
    } else if (DiamondDetails.txtClarity == "I1") {
      ValClarity = 9;
    } else if (DiamondDetails.txtClarity == "I2") {
      ValClarity = 10;
    } else {
      ValClarity = 11;
    }
    var ClarityList = "";
    var ValClarityLess = ValClarity - 1;
    var ValClarityMore = ValClarity + 1;

    if (ValClarityLess <= 1) {
      ClarityList = ValClarity + "," + ValClarityMore;
    } else if (ValClarityMore > 10) {
      ClarityList = ValClarityLess + "," + ValClarity;
    } else if (ValClarityMore > 1 && ValClarityLess <= 10) {
      ClarityList = ValClarityLess + "," + ValClarity + "," + ValClarityMore;
    } else {
      ClarityList = ValClarityLess + "," + ValClarity + "," + ValClarityMore;
    }
    console.log(ClarityList);
    if (DiamondDetails.txtCutGrade == "Ideal") {
      ValCut = 1;
    } else if (DiamondDetails.txtCutGrade == "Excellent") {
      ValCut = 2;
    } else if (DiamondDetails.txtCutGrade == "Very Good") {
      ValCut = 3;
    } else if (DiamondDetails.txtCutGrade == "Good") {
      ValCut = 4;
    } else if (DiamondDetails.txtCutGrade == "Fair") {
      ValCut = 5;
    } else if (DiamondDetails.txtCutGrade == "Poor") {
      ValCut = 6;
    } else {
      ValCut = 7;
    }

    var CutList = "";
    var ValCutLess = ValCut - 1;
    var ValCutMore = ValCut + 1;

    if (ValCutLess <= 1) {
      CutList = ValCut + "," + ValCutMore;
    } else if (ValCutMore > 7) {
      CutList = "";
    } else if (ValCutMore > 1 && ValCutLess <= 7) {
      CutList = ValCutMore + "," + ValCut + "," + ValCutLess;
    } else {
      CutList = ValCutMore + "," + ValCut + "," + ValCutLess;
    }

    try {
      let inputData;
      if (PageName == "Mined") {
        inputData = {
          dealerID: id.toString(),
          shapeList:
            DiamondDetails.shapeFilters.length > 0
              ? DiamondDetails.shapeFilters.toString()
              : "",
          priceMin: "",
          priceMax: "",
          caratMin: "0",
          caratMax: "23.85",
          colorMin: "68",
          colorMax: "75",
          clarityMin: "1", //FROM INTIAL FILTER
          clarityMax: "3", //FROM INTIAL FILTER
          cutgradeMin: "1",
          cutgradeMax: "6",
          symmetryMin: "1",
          symmetryMax: "5",
          polishMin: "1",
          polishMax: "5",
          fluorescenceMin: "1",
          fluorescenceMax: "6",
          certificate: "",
          skuNo: "",
          pairCode: "",
          pageSize: "6",
          currentPage: page.toString(),
          orderBy: "SortOrder",
          orderType: "Asc",
          detailsLink: "DiamondDetail.aspx",
          dID: "",
          depthMin: "0",
          depthMax: "6390",
          tableMin: "0",
          tableMax: "98",
          caratPriceMin: "0",
          caratPriceMax: "100000",
          themeName: "whiteTheme",
          showPrice: "1",
          bindWhat: "All",
          polishList: "",
          symmetryList: "",
          fluorescenceList: "",
          extraQStr: "&SID=&Shape=&CTW=&Size=",
          cutGradeList: CutList.toString(), //Logic from above input
          colorList: ColorList.toString(), // Logic from above input
          clarityList: ClarityList.toString(), //Logic from above input
          bindCheckbox: "1",
          sOrigin: "",
          isdiamond: "0",
          custID: "",
          settingSKU: "",
          countryTaxRate: "",
          vendorID: "",
          track: "0",
          isFB: "0",
          IsMLDL: "0",
          myRetailerID: "0",
          showAdvance: false,
          hasVideo: "True",
        };
      }
      if (PageName == "Lab") {
        inputData = {
          dealerID: id.toString(),
          shapeList:
            DiamondDetails.shapeFilters.length > 0
              ? DiamondDetails.shapeFilters.toString()
              : "",
          priceMin: "",
          priceMax: "",
          caratMin: "0",
          caratMax: "23.85",
          colorMin: "68",
          colorMax: "75",
          clarityMin: "1", //FROM INTIAL FILTER
          clarityMax: "3", //FROM INTIAL FILTER
          cutgradeMin: "1",
          cutgradeMax: "6",
          symmetryMin: "1",
          symmetryMax: "5",
          polishMin: "1",
          polishMax: "5",
          fluorescenceMin: "1",
          fluorescenceMax: "6",
          intensityList: "",
          intensityMax: "10",
          intensityMin: "1",
          certificate: "",
          skuNo: "",
          pairCode: "",
          pageSize: "6",
          currentPage: page.toString(),
          orderBy: "SortOrder",
          orderType: "Asc",
          detailsLink: "DiamondDetail.aspx",
          dID: "",
          depthMin: "0",
          depthMax: "6390",
          tableMin: "0",
          tableMax: "98",
          caratPriceMin: "0",
          caratPriceMax: "100000",
          themeName: "whiteTheme",
          showPrice: "1",
          bindWhat: "All",
          polishList: "",
          symmetryList: "",
          fluorescenceList: "",
          extraQStr: "&SID=&Shape=&CTW=&Size=",
          cutGradeList: CutList.toString(), //Logic from above input

          clarityList: ClarityList.toString(), //Logic from above input
          bindCheckbox: "1",
          CustID: "",
          dealerCompnayName: "",
          fancyColor: ColorList.toString(), // Logic from above input

          countryTaxRate: "",
          vendorID: "",
          track: "0",
          isFB: "0",

          myRetailerID: "0",

          hasVideo: "True",
          parent_url: "",
          isMLDL: "",
        };
      }
      if (PageName == "FancyColor") {
        inputData = {
          dealerID: id.toString(),
          shape:
            DiamondDetails.shapeFilters.length > 0
              ? DiamondDetails.shapeFilters.toString()
              : "",
          priceMin: "",
          priceMax: "",
          caratMin: "0",
          caratMax: "23.85",
          colorMin: "68",
          colorMax: "75",
          clarityMin: "1", //FROM INTIAL FILTER
          clarityMax: "3", //FROM INTIAL FILTER
          cutgradeMin: "1",
          cutgradeMax: "6",
          symmetryMin: "1",
          symmetryMax: "5",
          polishMin: "1",
          polishMax: "5",
          fluorescenceMin: "1",
          fluorescenceMax: "6",
          cutgradeMin: "1",
          cutgradeMax: "6",
          cutGradeList: "",
          certificate: "",
          skuNo: "",
          pairCode: "",
          pageSize: "6",
          currentPage: page.toString(),
          orderBy: "SortOrder",
          orderType: "Asc",
          detailsLink: "DiamondDetail.aspx",
          dID: "",
          depthMin: "0",
          depthMax: "6390",
          tableMin: "0",
          tableMax: "98",
          caratPriceMin: "0",
          caratPriceMax: "100000",
          themeName: "whiteTheme",
          showPrice: "1",
          isMLDL: "0",
          bindWhat: "All",
          polishList: "",
          symmetryList: "",
          fluorescenceList: "",
          extraQStr: "&SID=&Shape=&CTW=&Size=",
          cutGradeList: CutList.toString(), //Logic from above input
          colorList: ColorList.toString(), // Logic from above input
          clarityList: ClarityList.toString(), //Logic from above input
          bindCheckbox: "1",
          custID: "",
          vendorID: "",
          track: "0",
          isFB: "0",
          parent_url: "",
          myRetailerID: "0",
          showAdvance: false,
          hasVideo: "True",
          dealerCompnayName: "",
          themeName: "WhiteTheme",
          countryTaxRate: "",
          countryCode: "",
        };
      }

      setLoading(true);
      SelectYourDiamondService.GetSimilarDiamondsByPage(inputData, PageName)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var FirstRow = response.data.responseData[0];
          if (message == "Success") {
            if (responseData.length > 0) {
              setList(responseData);
              setTotalRowCount(responseData[0].intTotalRecords);
              setLoading(false);
            } else {
              setList([]);
              setTotalRowCount(0);
              setLoading(false);
            }
          } else {
            setList([]);
            setTotalRowCount(0);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleGetDiamondLinkFiltersViewStateData = (dealerId, diamondId) => {
    try {
      let inputData = {
        dealerID: dealerId.toString(),
        dInventoryID: diamondId.toString(),
      };
      setViewStateLoading(true);
      SelectYourDiamondService.GetDiamondLinkFiltersViewStateData(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            setDiamondInfoDetails(responseData);
            setViewStateLoading(false);
          } else {
            setDiamondInfoDetails({});
            setViewStateLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setViewStateLoading(false);
        });
    } catch (error) {
      console.log(error);
      setViewStateLoading(false);
    }
  };

  const images = [
    {
      original:
        "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.jpg",
      thumbnail:
        "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.jpg",
    },
    {
      original:
        "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.side.jpg",
      thumbnail:
        "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.side.jpg",
    },
    {
      original:
        "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.alt.jpg",
      thumbnail:
        "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.alt.jpg",
    },
    {
      original:
        "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.side.alt.jpg",
      thumbnail:
        "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.side.alt.jpg",
    },
    {
      original:
        "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.jpg",
      thumbnail:
        "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.jpg",
    },
    {
      original:
        "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.side.jpg",
      thumbnail:
        "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.side.jpg",
    },
  ];

  function renderLeftNav(onClick, disabled) {
    return (
      <button
        type="button"
        className="image-gallery-left-nav"
        aria-label="Prev Slide"
        disabled={disabled}
        onClick={onClick}
      >
        <img src={leftArrow} />
      </button>
    );
  }

  function renderRightNav(onClick, disabled) {
    return (
      <button
        type="button"
        className="image-gallery-right-nav"
        aria-label="Next Slide"
        disabled={disabled}
        onClick={onClick}
      >
        <img src={rightArrow} />
      </button>
    );
  }

  // Modal Functions
  const [requestMoreInfoModal, setRequestMoreInfoModal] = useState(false);
  const [requestCertModal, setRequestMoreCertModal] = useState(false);
  const [dropHintModal, showDropHintModal] = useState(false);
  const [emailFriendModal, setEmailFriendModal] = useState(false);
  const [isModalVisible4, setIsModalVisible4] = useState(false);
  const [scheduleViewingModal, setScheduleViewingModal] = useState(false);
  const [isModalVisible6, setIsModalVisible6] = useState(false);
  const [viewCertModal, setviewCertModal] = useState(false);
  const [internalUseModal, setInternalUseModal] = useState(false);

  const [requestMoreInfoModalLoading, setRequestMoreInfoModalLoading] =
    useState(false);
  const [requestCertModalLoading, setRequestMoreCertModalLoading] =
    useState(false);
  const [dropHintModalLoading, setshowDropHintModalLoading] = useState(false);
  const [emailFriendModalLoading, setEmailFriendModalLoading] = useState(false);
  const [scheduleViewingModalLoading, setScheduleViewingModalLoading] =
    useState(false);
  const [viewCertModalLoading, setviewCertModalLoading] = useState(false);
  const [internalUseModalLoading, setInternalUseModalLoading] = useState(false);

  const [dropHintInputData, setDropHintInputData] = useState({
    name: "",
    email: "",
    recipientName: "",
    recipientEmail: "",
    giftReason: "",
    giftDeadline: "",
    personalMessage: "",
  });
  const [dropHintValidation, setDropHintValidation] = useState({
    nameVal: "",
    emailVal: "",
    recipientNameVal: "",
    recipientEmailVal: "",
    giftReasonVal: "",
    giftDeadlineVal: "",
    personalMessageVal: "",
  });

  const [requestMoreInfoInputData, setRequestMoreInfoInputData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    contactPreference: "",
    personalMessage: "",
  });

  const [requestMoreInfoValidation, setRequestMoreInfoValidation] = useState({
    nameVal: "",
    emailVal: "",
    phoneNumberVal: "",
    contactPreferenceVal: "",
    personalMessageVal: "",
  });

  const [emailFriendInputData, setEmailFriendInputData] = useState({
    name: "",
    email: "",
    friendName: "",
    friendEmail: "",
    personalMessage: "",
  });

  const [emailFriendInputValidation, setEmailFriendInputDataValidation] =
    useState({
      nameVal: "",
      emailVal: "",
      friendNameVal: "",
      friendEmailVal: "",
      personalMessageVal: "",
    });

  const [scheduleViewingInputData, setScheduleViewingInputData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    location: "",
    appointmentDate: "",
    appointmentTime: "",
    address: "",
    message: "",
  });

  const [scheduleViewingInputValidation, setScheduleViewingInputValidation] =
    useState({
      nameVal: "",
      emailVal: "",
      phoneNumberVal: "",
      locationVal: "",
      appointmentDateVal: "",
      appointmentTimeVal: "",
      addressVal: "",
      messageVal: "",
    });

  const [certInfoData, setcertInfoData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    comments: "",
  });

  const [certInfoValidation, setcertInfoDataValidation] = useState({
    nameVal: "",
    emailVal: "",
    phoneNumberVal: "",
    commentsVal: "",
  });

  const [internalUseData, setInternalUseData] = useState({
    password: "",
  });

  const [internalUseValidation, setInternalUseValidation] = useState({
    passwordVal: "",
  });

  const showRequestMoreInfoModal = () => {
    setRequestMoreInfoModal(true);
  };
  const closeRequestMoreInfoModal = () => {
    setRequestMoreInfoModal(false);
    setRequestMoreInfoInputData({
      name: "",
      email: "",
      phoneNumber: "",
      contactPreference: "",
      personalMessage: "",
    });
    setRequestMoreInfoValidation({
      nameVal: "",
      emailVal: "",
      phoneNumberVal: "",
      contactPreferenceVal: "",
      personalMessageVal: "",
    });
  };

  const showCertInfoModal = () => {
    setRequestMoreCertModal(true);
  };
  const closeCertInfoModal = () => {
    setRequestMoreCertModal(false);
    setRequestMoreInfoInputData({
      name: "",
      email: "",
      phoneNumber: "",
      comments: "",
    });
    setcertInfoDataValidation({
      nameVal: "",
      emailVal: "",
      phoneNumberVal: "",
      commentsVal: "",
    });
  };

  const showDropAHintModal = () => {
    showDropHintModal(true);
  };
  const closeDropHintModal = () => {
    showDropHintModal(false);
    setDropHintInputData({
      name: "",
      email: "",
      recipientName: "",
      recipientEmail: "",
      giftReason: "",
      giftDeadline: "",
      personalMessage: "",
    });
    setDropHintValidation({
      nameVal: "",
      emailVal: "",
      recipientNameVal: "",
      recipientEmailVal: "",
      giftReasonVal: "",
      giftDeadlineVal: "",
      personalMessageVal: "",
    });
  };

  const showCertModal = () => {
    setviewCertModal(true);
  };
  const closeCertModal = () => {
    setviewCertModal(false);
    setcertInfoDataValidation({
      nameVal: "",
      emailVal: "",
      phoneNumberVal: "",
      commentsVal: "",
    });
  };

  const showEmailFriendModal = () => {
    setEmailFriendModal(true);
  };
  const hideEmailFriendModal = () => {
    setEmailFriendModal(false);
    setEmailFriendInputData({
      name: "",
      email: "",
      friendName: "",
      friendEmail: "",
      personalMessage: "",
    });
    setEmailFriendInputDataValidation({
      nameVal: "",
      emailVal: "",
      friendNameVal: "",
      friendEmailVal: "",
      personalMessageVal: "",
    });
  };

  const showModal4 = () => {
    setIsModalVisible4(true);
  };
  const handleCancel4 = () => {
    setIsModalVisible4(false);
  };

  const showScheduleViewingModal = () => {
    setScheduleViewingModal(true);
  };
  const closeScheduleViewingModal = () => {
    setScheduleViewingModal(false);

    setScheduleViewingInputData({
      name: "",
      email: "",
      phoneNumber: "",
      location: "",
      appointmentDate: "",
      appointmentTime: "",
      address: "",
      message: "",
    });
    setScheduleViewingInputValidation({
      nameVal: "",
      emailVal: "",
      phoneNumberVal: "",
      locationVal: "",
      appointmentDateVal: "",
      appointmentTimeVal: "",
      addressVal: "",
      messageVal: "",
    });
  };

  const showModal6 = () => {
    setIsModalVisible6(true);
  };
  const handleCancel6 = () => {
    setIsModalVisible6(false);
  };

  const handleBackButton = (e) => {
    console.log(pageName);
    var newpageName =
      pageName == "Lab"
        ? "Lab Grown"
        : pageName == "FancyColor"
        ? "Fancy Colored"
        : pageName;

    dispatch(handleSetPage(newpageName));
    if (pageName == "Fancy Colored") {
      navigate("/Fancy" + `?DealerLink=${paramdealerId}`);
    } else if (pageName == "Lab") {
      navigate("/Lab" + `?DealerLink=${paramdealerId}`);
    } else {
      navigate("/Mined" + `?DealerLink=${paramdealerId}`);
    }
    dispatch(handleComaparePageIds(""));
  };

  const handleDropHintChange = (e, name, type) => {
    setDropHintInputData({
      ...dropHintInputData,
      [name]: e.target.value,
    });
  };

  const handleDropHintValidation = () => {
    const {
      name,
      email,
      recipientName,
      recipientEmail,
      giftReason,
      giftDeadline,
      personalMessage,
    } = dropHintInputData;
    const dropHintValidation = {
      nameVal: "",
      emailVal: "",
      recipientNameVal: "",
      recipientEmailVal: "",
      giftReasonVal: "",
      giftDeadlineVal: "",
      personalMessageVal: "",
    };
    let isValid = true;

    if (!name.trim()) {
      isValid = false;
      dropHintValidation.nameVal = "First Name is Compulsory";
    }
    if (!recipientName.trim()) {
      isValid = false;
      dropHintValidation.recipientNameVal = "Recipient Name is Compulsory";
    }

    var validRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!email.trim()) {
      isValid = false;
      dropHintValidation.emailVal = "Email address is compulsory";
    } else if (!recipientEmail.match(validRegex)) {
      isValid = false;
      dropHintValidation.emailVal = "Email address is invalid";
    } else {
      dropHintValidation.emailVal = "";
    }

    if (!recipientEmail.trim()) {
      isValid = false;
      dropHintValidation.recipientEmailVal = "Email address is compulsory";
    } else if (!email.match(validRegex)) {
      isValid = false;
      dropHintValidation.recipientEmailVal = "Email address is invalid";
    } else {
      dropHintValidation.recipientEmailVal = "";
    }

    if (!personalMessage.trim()) {
      isValid = false;
      dropHintValidation.personalMessageVal = "Personal Message is Compulsory";
    }
    if (!giftReason.trim()) {
      isValid = false;
      dropHintValidation.giftReasonVal = "Gift Reason is Compulsory";
    }
    if (!giftDeadline.trim()) {
      isValid = false;
      dropHintValidation.giftDeadlineVal = "Gift Deadline is Compulsory";
    }

    if (!isValid) {
      setDropHintValidation(dropHintValidation);
    }

    return isValid;
  };
  const handleGetDiamondLinkFiltersDropHint = () => {
    const isValid = handleDropHintValidation();
    if (!isValid) {
      return false;
    }
    try {
      let inputData = {
        loadFromMasterlink: true,
        strDiamondLinkURL: "",
        dealerID: dealerId.toString(),
        hintYourEmail: dropHintInputData.email,
        hintYourName: dropHintInputData.name,
        hintRecptEmail: dropHintInputData.recipientEmail,
        hintRecptName: dropHintInputData.recipientName,
        hintRecptReason: dropHintInputData.giftReason,
        hintRecpMessage: dropHintInputData.personalMessage,
        hintRecptGiftDeadline: dropHintInputData.giftDeadline,
        sid: "",
        did: diamondInfoDetails.intDiamondId.toString(),
        shape: diamondInfoDetails.txtShape,
        ctw: "",
        step: "",
        themeName: "",
      };
      setshowDropHintModalLoading(true);
      SelectYourDiamondService.GetDiamondLinkFiltersDropHint(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message == "Success") {
            NotificationManager.success(
              "Your request has been sent to GemFind."
            );
            setshowDropHintModalLoading(false);
            setDropHintInputData({
              name: "",
              email: "",
              recipientName: "",
              recipientEmail: "",
              giftReason: "",
              giftDeadline: "",
              personalMessage: "",
            });
            setDropHintValidation({
              nameVal: "",
              emailVal: "",
              recipientNameVal: "",
              recipientEmailVal: "",
              giftReasonVal: "",
              giftDeadlineVal: "",
              personalMessageVal: "",
            });
            showDropHintModal(false);
          } else {
            NotificationManager.error(
              "Your request has not been sent to GemFind."
            );
            setshowDropHintModalLoading(false);
            showDropHintModal(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setshowDropHintModalLoading(false);
          showDropHintModal(false);
        });
    } catch (error) {
      console.log(error);
      setshowDropHintModalLoading(false);
      showDropHintModal(false);
    }
  };

  const handleSubmitDropHint = () => {
    console.log(dropHintInputData);
    handleGetDiamondLinkFiltersDropHint();
  };

  const handleRequestCertificateValidation = () => {
    const { name, email, phoneNumber, comments } = certInfoData;
    const certInfoValidation = {
      nameVal: "",
      emailVal: "",
      phoneNumberVal: "",
      commentsVal: "",
    };
    let isValid = true;

    if (!name.trim()) {
      isValid = false;
      certInfoValidation.nameVal = "First Name is Compulsory";
    }

    var validRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!email.trim()) {
      isValid = false;
      certInfoValidation.emailVal = "Email address is compulsory";
    } else if (!email.match(validRegex)) {
      isValid = false;
      certInfoValidation.emailVal = "Email address is invalid";
    } else {
      certInfoValidation.emailVal = "";
    }

    if (!phoneNumber.trim()) {
      isValid = false;
      certInfoValidation.phoneNumberVal = "Phone number is compulsory";
    } else if (
      (phoneNumber.length >= 10 && phoneNumber.includes("-")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes(",")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes("+"))
    ) {
      certInfoValidation.phoneNumberVal = "";
    } else if (!/^(?!0{10,15})(\+\d{1,3}[-]?)?\d{10,15}$/.test(phoneNumber)) {
      isValid = false;
      certInfoValidation.phoneNumberVal = "Phone number is invalid";
    } else {
      certInfoValidation.phoneNumberVal = "";
    }

    if (!comments.trim()) {
      isValid = false;
      certInfoValidation.commentsVal = "Comments is Compulsory";
    }

    if (!isValid) {
      setcertInfoDataValidation(certInfoValidation);
    }

    return isValid;
  };

  const handleGetDiamondLinkFiltersRequestCertificate = () => {
    const isValid = handleRequestCertificateValidation();
    if (!isValid) {
      return false;
    }
    try {
      let inputData = {
        dealerID: dealerId.toString(),
        did: diamondInfoDetails.intDiamondId,
        name: certInfoData.name,
        email: certInfoData.email,
        phone: certInfoData.phoneNumber,
        comments: certInfoData.comments,
        retailerID: diamondInfoDetails.dealerID,
      };
      setRequestMoreCertModalLoading(true);
      SelectYourDiamondService.GetDiamondLinkFiltersRequestCertificate(
        inputData
      )
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            NotificationManager.success(
              "Your request has been sent to GemFind. A representative will be contacting you shortly."
            );
            setcertInfoData({
              name: "",
              email: "",
              phoneNumber: "",
              comments: "",
            });
            setcertInfoDataValidation({
              nameVal: "",
              emailVal: "",
              phoneNumberVal: "",
              commentsVal: "",
            });
            setRequestMoreCertModalLoading(false);
          } else {
            NotificationManager.success(
              "Your request has been sent to GemFind. A representative will be contacting you shortly."
            );
            setRequestMoreCertModalLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setRequestMoreCertModalLoading(false);
        });
    } catch (error) {
      console.log(error);
      setRequestMoreCertModalLoading(false);
    }
  };

  const handleSubmitRequestCert = () => {
    console.log(certInfoData);
    handleGetDiamondLinkFiltersRequestCertificate();
  };

  const handleDealerInformationValidation = () => {
    const { password } = internalUseData;
    const internalUseValidation = {
      passwordVal: "",
    };
    let isValid = true;

    if (password == "") {
      isValid = false;
      internalUseValidation.passwordVal = "Password is Compulsory";
    }

    if (!isValid) {
      setInternalUseValidation(internalUseValidation);
    }

    return isValid;
  };
  const handleGetDealerInformation = () => {
    const isValid = handleDealerInformationValidation();
    if (!isValid) {
      return false;
    }
    try {
      let inputData = {
        dealerID: dealerId.toString(),
        diamondDealerID: diamondInfoDetails.dealerID,
        password: internalUseData,
        diamondID: diamondInfoDetails.intDiamondId.toString(),
      };
      setInternalUseModalLoading(true);
      SelectYourDiamondService.GetDealerInformation(inputData)
        .then((response) => {
          var message = response.data.responseData.passwordcheck;
          var dealerInfo = response.data.responseData.getDealerInformation[0];
          var dealerInfo1 = response.data.responseData.getDiamondInformation[0];
          if (message == false) {
            NotificationManager.error("Kindly Enter valid Details");
            setInternalUseModalLoading(false);
            setShowDealerInfo(false);
            setInternalUseData({
              password: "",
            });
            setInternalUseValidation({
              passwordVal: "",
            });
          } else {
            setShowDealerInfo(true);
            setDealerInfo(dealerInfo);
            setDealerInfo1(dealerInfo1);
            setInternalUseModalLoading(false);
            console.log(dealerInfo);
          }
        })
        .catch((error) => {
          console.log(error);
          setInternalUseModalLoading(false);
        });
    } catch (error) {
      console.log(error);
      setInternalUseModalLoading(false);
    }
  };
  const handleSubmitInternalData = () => {
    console.log(internalUseData);
    handleGetDealerInformation();
  };

  const handleCancelInternalModal = () => {
    setInternalUseModal(false);
    setShowDealerInfo(false);
    setInternalUseData({
      password: "",
    });
    setInternalUseValidation({
      passwordVal: "",
    });
  };

  const handleCertInfoChange = (e, name, type) => {
    setcertInfoData({
      ...certInfoData,
      [name]: e.target.value,
    });
  };

  const handleRequestMoreInfoChange = (e, name, type) => {
    setRequestMoreInfoInputData({
      ...requestMoreInfoInputData,
      [name]: e.target.value,
    });
  };

  const handleRequestMoreInfoValidation = () => {
    const { name, email, phoneNumber, contactPreference, personalMessage } =
      requestMoreInfoInputData;
    const requestMoreInfoValidation = {
      nameVal: "",
      emailVal: "",
      phoneNumberVal: "",
      contactPreferenceVal: "",
      personalMessageVal: "",
    };
    let isValid = true;

    if (!name.trim()) {
      isValid = false;
      requestMoreInfoValidation.nameVal = "First Name is Compulsory";
    }

    var validRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!email) {
      isValid = false;
      requestMoreInfoValidation.emailVal = "Email address is compulsory";
    } else if (!email.match(validRegex)) {
      isValid = false;
      requestMoreInfoValidation.emailVal = "Email address is invalid";
    } else {
      requestMoreInfoValidation.emailVal = "";
    }

    if (!phoneNumber.trim()) {
      isValid = false;
      requestMoreInfoValidation.phoneNumberVal = "Phone number is compulsory";
    } else if (
      (phoneNumber.length >= 10 && phoneNumber.includes("-")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes(",")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes("+"))
    ) {
      requestMoreInfoValidation.phoneNumberVal = "";
    } else if (!/^(?!0{10,15})(\+\d{1,3}[-]?)?\d{10,15}$/.test(phoneNumber)) {
      isValid = false;
      requestMoreInfoValidation.phoneNumberVal = "Phone number is invalid";
    } else {
      requestMoreInfoValidation.phoneNumberVal = "";
    }

    if (!personalMessage.trim()) {
      isValid = false;
      requestMoreInfoValidation.personalMessageVal =
        "Personal Message is Compulsory";
    }

    if (!isValid) {
      setRequestMoreInfoValidation(requestMoreInfoValidation);
    }

    return isValid;
  };
  const handleGetDiamondLinkFiltersRequestMoreInfo = () => {
    const isValid = handleRequestMoreInfoValidation();
    if (!isValid) {
      return false;
    }
    try {
      let inputData = {
        dealerID: dealerId.toString(),
        name: requestMoreInfoInputData.name,
        email: requestMoreInfoInputData.email,
        phone: requestMoreInfoInputData.phoneNumber,
        comments: requestMoreInfoInputData.comments,
        did: diamondInfoDetails.intDiamondId.toString(),
        retailerID: diamondInfoDetails.dealerID,

        byEmail: true,
      };
      setRequestMoreInfoModalLoading(true);
      SelectYourDiamondService.GetDiamondLinkFiltersRequestMoreInfo(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message == "Success") {
            NotificationManager.success(
              "Your request has been sent to GemFind. A representative will be contacting you shortly."
            );
            setRequestMoreInfoModalLoading(false);
            setRequestMoreInfoInputData({
              name: "",
              email: "",
              phoneNumber: "",
              contactPreference: "",
              personalMessage: "",
            });
            setRequestMoreInfoValidation({
              nameVal: "",
              emailVal: "",
              phoneNumberVal: "",
              contactPreferenceVal: "",
              personalMessageVal: "",
            });
            closeRequestMoreInfoModal();
            setRequestMoreInfoModal(false);
          } else {
            NotificationManager.error(
              "Your request has not been sent to GemFind"
            );

            setRequestMoreInfoModalLoading(false);
            setRequestMoreInfoModal(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setRequestMoreInfoModalLoading(false);
          setRequestMoreInfoModal(false);
        });
    } catch (error) {
      console.log(error);
      setRequestMoreInfoModalLoading(false);
      setRequestMoreInfoModal(false);
    }
  };

  const handleSubmitRequestMoreInfo = () => {
    console.log(requestMoreInfoInputData);
    handleGetDiamondLinkFiltersRequestMoreInfo();
  };

  const handleEmailFriendChange = (e, name) => {
    setEmailFriendInputData({
      ...emailFriendInputData,
      [name]: e.target.value,
    });
  };

  const handleEmailToFriendValidation = () => {
    const { name, email, friendName, friendEmail, personalMessage } =
      emailFriendInputData;
    const emailFriendInputValidation = {
      nameVal: "",
      emailVal: "",
      friendNameVal: "",
      friendEmailVal: "",
      personalMessageVal: "",
    };
    let isValid = true;

    if (!name.trim()) {
      isValid = false;
      emailFriendInputValidation.nameVal = "Name is Compulsory";
    }

    if (!friendName.trim()) {
      isValid = false;
      emailFriendInputValidation.friendNameVal = "Friend Name is Compulsory";
    }

    var validRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!email.trim()) {
      isValid = false;
      emailFriendInputValidation.emailVal = "Email address is compulsory";
    } else if (!email.match(validRegex)) {
      isValid = false;
      emailFriendInputValidation.emailVal = "Email address is invalid";
    } else {
      emailFriendInputValidation.emailVal = "";
    }

    if (!friendEmail.trim()) {
      isValid = false;
      emailFriendInputValidation.friendEmailVal = "Email address is compulsory";
    } else if (!friendEmail.match(validRegex)) {
      isValid = false;
      emailFriendInputValidation.friendEmailVal = "Email address is invalid";
    } else {
      emailFriendInputValidation.friendEmailVal = "";
    }

    if (!personalMessage.trim()) {
      isValid = false;
      emailFriendInputValidation.personalMessageVal =
        "Personal Message is Compulsory";
    }

    if (!isValid) {
      setEmailFriendInputDataValidation(emailFriendInputValidation);
    }

    return isValid;
  };

  const handleGetDiamondLinkFiltersEmailToFriend = () => {
    const isValid = handleEmailToFriendValidation();
    if (!isValid) {
      return false;
    }
    try {
      let inputData = {
        yourEmail: emailFriendInputData.email,
        tellFriendComment: emailFriendInputData.personalMessage,
        yourName: emailFriendInputData.name,
        yourFriendEmail: emailFriendInputData.friendEmail,
        yourFriendName: emailFriendInputData.friendName,
        did: diamondInfoDetails.intDiamondId.toString(),
        retailerID: diamondInfoDetails.dealerID,
        byEmail: true,
      };
      setEmailFriendModalLoading(true);
      SelectYourDiamondService.GetDiamondLinkFiltersEmailToFriend(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message == "Success") {
            NotificationManager.success(
              "An email has been sent to your friend,Thanks for sharing!"
            );
            setEmailFriendModalLoading(false);
            setEmailFriendInputData({
              name: "",
              email: "",
              friendName: "",
              friendEmail: "",
              personalMessage: "",
            });
            setEmailFriendInputDataValidation({
              nameVal: "",
              emailVal: "",
              friendNameVal: "",
              friendEmailVal: "",
              personalMessageVal: "",
            });
            setEmailFriendModal(false);
          } else {
            NotificationManager.error(
              "An email has not been  sent to your friend,Thanks for sharing!"
            );
            setEmailFriendModalLoading(false);
            setEmailFriendModal(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setEmailFriendModalLoading(false);
          setEmailFriendModal(false);
        });
    } catch (error) {
      console.log(error);
      setEmailFriendModalLoading(false);
      setEmailFriendModal(false);
    }
  };

  const handleSubmitEmailFriend = () => {
    console.log(emailFriendInputData);
    handleGetDiamondLinkFiltersEmailToFriend();
  };

  const handleScheduleViewingChange = (e, name, type) => {
    if (type == "select") {
      setScheduleViewingInputData({
        ...scheduleViewingInputData,
        [name]: e,
      });
    } else {
      setScheduleViewingInputData({
        ...scheduleViewingInputData,
        [name]: e.target.value,
      });
    }
  };

  const handleScheduleviewingValidation = () => {
    const {
      name,
      email,
      phoneNumber,
      location,
      appointmentDate,
      appointmentTime,
      address,
      message,
    } = scheduleViewingInputData;
    const scheduleViewingInputValidation = {
      nameVal: "",
      emailVal: "",
      phoneNumberVal: "",
      locationVal: "",
      appointmentDateVal: "",
      appointmentTimeVal: "",
      addressVal: "",
      messageVal: "",
    };
    let isValid = true;

    if (!name) {
      isValid = false;
      scheduleViewingInputValidation.nameVal = "Name is Compulsory";
    }

    if (!location) {
      isValid = false;
      scheduleViewingInputValidation.locationVal = "Location is Compulsory";
    }

    if (!appointmentDate) {
      isValid = false;
      scheduleViewingInputValidation.appointmentDateVal =
        "Appointment Date is Compulsory";
    }

    if (!appointmentTime) {
      isValid = false;
      scheduleViewingInputValidation.appointmentTimeVal =
        "Appointment Time is Compulsory";
    }

    if (!address) {
      isValid = false;
      scheduleViewingInputValidation.addressVal =
        "Appointment Time is Compulsory";
    }

    if (!message) {
      isValid = false;
      scheduleViewingInputValidation.messageVal =
        "Appointment Time is Compulsory";
    }

    var validRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!email) {
      isValid = false;
      scheduleViewingInputValidation.emailVal = "Email address is compulsory";
    } else if (!email.match(validRegex)) {
      isValid = false;
      scheduleViewingInputValidation.emailVal = "Email address is invalid";
    } else {
      scheduleViewingInputValidation.emailVal = "";
    }
    if (!phoneNumber) {
      isValid = false;
      scheduleViewingInputValidation.phoneNumberVal =
        "Phone number is compulsory";
    } else if (
      (phoneNumber.length >= 10 && phoneNumber.includes("-")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes(",")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes("+"))
    ) {
      scheduleViewingInputValidation.phoneNumberVal = "";
    } else if (!/^(?!0{10,15})(\+\d{1,3}[-]?)?\d{10,15}$/.test(phoneNumber)) {
      isValid = false;
      scheduleViewingInputValidation.phoneNumberVal = "Phone number is invalid";
    } else {
      scheduleViewingInputValidation.phoneNumberVal = "";
    }

    setScheduleViewingInputValidation(scheduleViewingInputValidation);

    return isValid;
  };

  const handleGetDiamondLinkFiltersScheduleviewing = () => {
    const isValid = handleScheduleviewingValidation();
    if (!isValid) {
      return false;
    }
    try {
      let inputData = {
        dealerID: dealerId.toString(),

        did: diamondInfoDetails.intDiamondId.toString(),
        retailerID: diamondInfoDetails.dealerID.toString(),
        emailChk: scheduleViewingInputData.email,
        schedulPnone: scheduleViewingInputData.phoneNumber,
        appntMsg: scheduleViewingInputData.message,
        schedulName: scheduleViewingInputData.name,
        appntDate: scheduleViewingInputData.appointmentDate,
        hndAppntTime: scheduleViewingInputData.appointmentTime,
        shape: "",
        ctw: "",
        strDiamondLinkURL: "",
        step: "",
        themeName: "",
        loadFromMasterlink: true,
      };
      setScheduleViewingModalLoading(true);
      SelectYourDiamondService.GetDiamondLinkFiltersScheduleviewing(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message == "Success") {
            NotificationManager.success(
              "Your request has been sent to GemFind."
            );
            setScheduleViewingInputData({
              name: "",
              email: "",
              phoneNumber: "",
              location: "",
              appointmentDate: "",
              appointmentTime: "",
              address: "",
              message: "",
            });
            setScheduleViewingModalLoading(false);
            closeScheduleViewingModal();
            setviewCertModal(false);
          } else {
            NotificationManager.success(
              "Your request has been sent to GemFind."
            );
            setScheduleViewingModalLoading(false);
            setviewCertModal(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setScheduleViewingModalLoading(false);
          setviewCertModal(false);
        });
    } catch (error) {
      console.log(error);
      setScheduleViewingModalLoading(false);
      setviewCertModal(false);
    }
  };
  const handleSubmitScheduleViewing = () => {
    console.log(scheduleViewingInputData);
    handleGetDiamondLinkFiltersScheduleviewing();
  };

  function handlePrintDiamondList() {
    window.print();
    // var disp_setting =
    //   "toolbar=yes,location=no,directories=yes,menubar=yes,resizable=yes,";
    // disp_setting += "scrollbars=yes,width=1095, height=600, left=100, top=25";
    // var docprint = window.open("", "", disp_setting);
    // docprint.document.open();
    // var htmlContent = document.getElementById("printsection").innerHTML;

    // htmlContent +=
    //   '<style>.subheading {font-size: 16px;font-family: "LexendDeca-SemiBold";margin: 1rem 0;}.print__section .top__header {display: flex;justify-content: space-between;align-items: center;}.print__section .header__logo img {width:160px;}.print__section table thead {background-color:#efefef;}.print__section .table__content {overflow: hidden;text-overflow: ellipsis;margin-bottom:0;max-width:80px;}.print__section .bottom__footer {background: #e9e8ea;padding: 10px;}.print__section .bottom__footer img {width:160px;}</style>';
    // htmlContent +=
    //   '<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">';

    // docprint.document.write(htmlContent);
    // docprint.document.close();

    // setTimeout(() => {
    //   docprint.print();
    // }, 5000);
    // docprint.focus();
  }

  const handleSetRowDetils = (e, record) => {
    handleViewDetails(record);
  };

  const handleViewDetails = (details) => {
    var obj = details;
    obj["pageName"] = pageName;
    obj["shapeFilters"] = shapeList;
    // dispatch(handleShowViewPage());
    // dispatch(handleSetDiamondDetails(obj));
    // handleGetSimilarDiamond(dealerId, details, pageNo);
    // handleGetDiamondLinkFiltersViewStateData(dealerId, details.intDiamondId);

    navigate(
      "/viewDiamondDetails" +
        `?isSocialPage=True&DealerID=${obj.gfLinkID}&diamondId=${obj.intDiamondId}&pageNo=1&isAllowed=True&pageName=${obj.pageName}&shapeFilters=${shapeFilters}&txtCutlet=${obj.txtCulet}&txtClarity=${obj.txtClarity}&intColorPriority=${obj.intColorPriority}`
    );
    setSimilarToogle(!similarToogle);
    console.log("");
  };

  const columns = [
    {
      title: "Shape",
      dataIndex: "txtShape",
      sorter: (a, b) => {},
      showSorterTooltip: false,
      width: 350,
      render: (item, row) => {
        return (
          <div className="shape__img">
            <img src={row.imageFileName} alt="" />
            <span>{row.txtShape}</span>
          </div>
        );
      },
    },
    {
      title: "SKU",
      dataIndex: "intDiamondId",
    },
    {
      title: "Carat",
      dataIndex: "fltCarat",
    },
    {
      title: "Color",
      dataIndex: "txtColor",
    },
    {
      title: "Clarity",
      dataIndex: "txtClarity",
    },
    // {
    //   title: "Cut",
    //   dataIndex: "txtCutGrade",
    // },
    {
      title: "Intensity",
      dataIndex: "txtIntensity",
      className: "hideInMobile",
      width: 150,
    },
    {
      title: "Depth",
      dataIndex: "fltDepth",
    },
    {
      title: "Table",
      dataIndex: "fltTable",
      className: "hideInMobile",
    },
    {
      title: "Polish",
      dataIndex: "txtPolish",
      className: "hideInMobile",
    },
    {
      title: "Measurement",
      dataIndex: "txtMeasurements",
      className: "hideInMobile",
    },
    {
      title: "Cert.",
      dataIndex: "txtCertificate",
    },
    {
      title: "Price",
      dataIndex: "realPrice",
      render: (item, row) => {
        return (
          <div>
            {row.realPrice == "0" ? (
              <>
                Call <span className="hideInMobile">For Price</span>
              </>
            ) : (
              <span>
                $
                {parseInt(row.realPrice)
                  .toFixed()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </span>
            )}
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (index, row) => (
        <div>
          <div className="action__btns">
            {/* <div className="image__block">
              <img src={Info_icon} alt="" />
            </div>
            <div className="image__block">
              <img
                src={Video_icon}
                alt=""
              />
            </div> */}
            <div className="image__block">
              <img
                src={Eye_icon}
                alt=""
                onClick={() => handleViewDetails(row)}
              />
            </div>
            <div className="image__block hideInMobile">
              <img
                src={Info_icon}
                alt=""
                onMouseOver={() => handleHoverData(row)}
                onMouseOut={() => handleHoverCancelData(row)}
              />
            </div>
          </div>
          <div
            className={
              hoverInfo == true && row.intDiamondId == hoverDealerId
                ? "info__tooltip"
                : "display-none"
            }
          >
            <div className="close__div">{/* <img src={Close} alt="" /> */}</div>
            <p>
              <span>Depth</span>
              <span>1{row.fltDepth == "" ? "NA" : row.fltDepth + "%"}</span>
            </p>
            <p>
              <span>Table</span>
              <span>{row.fltTable == "" ? "NA" : row.fltTable + "%"}</span>
            </p>
            <p>
              <span>Polish</span>
              <span>{row.txtPolish}</span>
            </p>
            <p>
              <span>Symmetry</span>
              <span>{row.txtSymmetry}</span>
            </p>
            <p>
              <span>Measurement</span>
              <span>{row.txtMeasurements}</span>
            </p>
          </div>
        </div>
      ),
    },
  ];

  const handleHoverData = (row) => {
    setHoverInfo(true);
    setDealerHoverId(row.intDiamondId);
    setTableClassAllow(true);
  };
  const handleHoverCancelData = (row) => {
    setHoverInfo(false);
    setDealerHoverId();
    setTableClassAllow(false);
  };

  const handleScrollToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  console.log(diamondInfoDetails);
  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  const handleAddToCart = (url) => {
    if (url.includes("https")) {
      window.open(url, "_blank");
    } else if (url.includes("http")) {
      window.open(url, "_blank");
    } else {
      window.open("https://" + url, "_blank");
    }
  };
  return (
    <React.Fragment>
      <body onLoad={handleScrollToTop}>
        <Wrapper>
          <Spin spinning={wrapperLoading}>
            <div className="row m-0">
              <div className="col-md-12">
                <span
                  className="back__btn linkText"
                  onClick={(e) => handleBackButton(e)}
                >
                  {" "}
                  {"<< Back"}{" "}
                </span>
              </div>
            </div>

            <div className="allprod_section jwlsrch__section app__preview choose__setting">
              <div className="row m-0">
                <div className="col-md-12">
                  <div className="prodetail__section">
                    <div className="form__fields">
                      <div className="col-md-12">
                        <div
                          className="prod__maindiv app__preview p-0 border-0 "
                          id="printsection"
                        >
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="row">
                                <div className="col-lg-12">
                                  <div className="whole__shapes available__shapes th__shapes">
                                    <div
                                      className={
                                        blockSelect == "imageFileName"
                                          ? "shapes__block selected"
                                          : "shapes__block"
                                      }
                                    >
                                      {diamondInfoDetails.imageFileName ? (
                                        <div className="image">
                                          <img
                                            onClick={() =>
                                              setBlockSelect("imageFileName")
                                            }
                                            src={
                                              diamondInfoDetails.imageFileName
                                            }
                                            style={{
                                              width: "50px",
                                              height: "50px",
                                            }}
                                          />
                                        </div>
                                      ) : null}
                                    </div>
                                    <div
                                      className={
                                        blockSelect == "htmlLink"
                                          ? "shapes__block selected"
                                          : "shapes__block"
                                      }
                                    >
                                      {diamondInfoDetails.htmlLink ? (
                                        <div className="image">
                                          <img
                                            onClick={() =>
                                              setBlockSelect("htmlLink")
                                            }
                                            src={diamondInfoDetails.htmlLink}
                                            style={{
                                              width: "50px",
                                              height: "50px",
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        <div className="image">
                                          <img
                                            onClick={() =>
                                              setBlockSelect("htmlLink")
                                            }
                                            src={diamondInfoDetails.htmlLink}
                                            style={{
                                              width: "50px",
                                              height: "50px",
                                            }}
                                          />
                                        </div>
                                      )}
                                    </div>

                                    <div
                                      className={
                                        blockSelect == "videoFileName"
                                          ? "shapes__block selected"
                                          : "shapes__block"
                                      }
                                    >
                                      {diamondInfoDetails.videoFileName ? (
                                        <div className="image">
                                          <img
                                            onClick={() =>
                                              setBlockSelect("videoFileName")
                                            }
                                            src={Video_icon}
                                            style={{
                                              width: "50px",
                                              height: "50px",
                                            }}
                                          />
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>

                                <div className="col-lg-12">
                                  <div className="big__img thimage__slider">
                                    {blockSelect == "imageFileName" ? (
                                      <img
                                        src={diamondInfoDetails.imageFileName}
                                        className="img-fluid"
                                      />
                                    ) : blockSelect == "videoFileName" ? (
                                      <video
                                        type="video/youtube"
                                        controls
                                        autoPlay
                                        key={diamondInfoDetails.videoFileName}
                                        style={{
                                          width: "100%",
                                          height: "450px",
                                        }}
                                      >
                                        <source
                                          src={diamondInfoDetails.videoFileName}
                                        />
                                      </video>
                                    ) : // <iframe
                                    //   width="100%"
                                    //   height="450"
                                    //   src={diamondInfoDetails.videoFileName}
                                    // />
                                    blockSelect == "htmlLink" ? (
                                      <img
                                        width={"100%"}
                                        className="img-fluid"
                                        src={diamondInfoDetails.htmlLink}
                                      />
                                    ) : null}

                                    <div className="share__block">
                                      {optionData.showFacebookShare == false &&
                                      optionData.showPinterestShare == false &&
                                      optionData.showTwitterShare == false &&
                                      optionData.showInstagramShare == false &&
                                      optionData.showFacebookLike == false ? (
                                        <>
                                          <div></div>
                                        </>
                                      ) : (
                                        <>
                                          <div className="share__button">
                                            <img
                                              src={shareBlue}
                                              onClick={() =>
                                                setShowSocialIcons(
                                                  !showSocialIcons
                                                )
                                              }
                                            />
                                          </div>
                                        </>
                                      )}

                                      <div
                                        className={
                                          showSocialIcons == true
                                            ? "social__button display-flex"
                                            : "social__button display-none"
                                        }
                                      >
                                        <img
                                          className={
                                            optionData.showFacebookShare == true
                                              ? "display-block"
                                              : "display-none"
                                          }
                                          onClick={() =>
                                            handleSocialLinks(
                                              diamondInfoDetails.faceBookLink
                                            )
                                          }
                                          src={shareFacebook}

                                          // className="display-block"
                                        />

                                        <img
                                          className={
                                            optionData.showPinterestShare ==
                                            true
                                              ? "display-block"
                                              : "display-none"
                                          }
                                          onClick={() =>
                                            handleSocialLinks(
                                              diamondInfoDetails.pinterestLink
                                            )
                                          }
                                          src={sharePintrest}
                                        />
                                        <img
                                          src={shareTwitter}
                                          className={
                                            optionData.showTwitterShare == true
                                              ? "display-block"
                                              : "display-none"
                                          }
                                          onClick={() =>
                                            handleSocialLinks(
                                              diamondInfoDetails.twitterLink
                                            )
                                          }
                                        />
                                        <img
                                          src={InstaIcon}
                                          className={
                                            optionData.showInstagramShare ==
                                            true
                                              ? "display-block"
                                              : "display-none"
                                          }
                                          onClick={() =>
                                            handleSocialLinks(
                                              diamondInfoDetails.instaLink
                                            )
                                          }
                                        />
                                        <img
                                          className={
                                            optionData.showFacebookLike == true
                                              ? "display-block"
                                              : "display-none"
                                          }
                                          src={FaceBookLike}
                                          onClick={() =>
                                            handleSocialLinks(
                                              diamondInfoDetails.faceBookLike
                                            )
                                          }
                                          // className="display-block"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {/* <div className="col-lg-12">
                            <div className="thimage__slider">
                              <ImageGallery
                                items={images}
                                renderLeftNav={renderLeftNav}
                                renderRightNav={renderRightNav}
                              />
                              <div className="video__icon" onClick={showModal6}>
                                <i
                                  class="fa fa-video-camera"
                                  aria-hidden="true"
                                ></i>
                              </div>

                              <div className="share__block">
                                <div className="share__button">
                                  <img src={shareBlue} />
                                </div>
                                <div className="social__button">
                                  <img src={shareFacebook} />
                                  <img src={sharePintrest} />
                                  <img src={shareTwitter} />
                                </div>
                              </div>
                            </div>
                          </div> */}

                                <div className="col-md-12">
                                  <div className="price__block text-center mb-3">
                                    <h4 className="subheading">
                                      {diamondInfoDetails.price ? (
                                        diamondInfoDetails.price == "0" ? (
                                          "Call For Price"
                                        ) : (
                                          <>
                                            US$
                                            {parseInt(diamondInfoDetails.price)
                                              .toFixed()
                                              .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                              )}
                                          </>
                                        )
                                      ) : (
                                        "NA"
                                      )}
                                    </h4>
                                    {optionData.showAddToCartButton == 1 ? (
                                      <button
                                        onClick={() =>
                                          handleAddToCart(
                                            diamondInfoDetails.addToCartLink
                                          )
                                        }
                                        className="primary-btn"
                                      >
                                        Add To Cart
                                      </button>
                                    ) : optionData.showAddToCartButton == 0 ? (
                                      <button
                                        onClick={() =>
                                          showRequestMoreInfoModal()
                                        }
                                        className="primary-btn"
                                      >
                                        Request More Info
                                      </button>
                                    ) : optionData.showAddToCartButton == 2 ? (
                                      <button
                                        onClick={() =>
                                          showScheduleViewingModal()
                                        }
                                        className="primary-btn"
                                      >
                                        Schedule A Viewing
                                      </button>
                                    ) : null}
                                  </div>

                                  <div className="diamond__detail mb-3">
                                    <div className="row">
                                      <div className="col-md-12 mb-2">
                                        <div>
                                          <span>Diamond Grading Report</span>
                                          {diamondInfoDetails.certificateLink ==
                                          "" ? (
                                            <React.Fragment>
                                              {optionData.requestCer == true ? (
                                                <span
                                                  className="linkText ml-2"
                                                  onClick={() =>
                                                    showCertInfoModal()
                                                  }
                                                >
                                                  Request Certificate
                                                </span>
                                              ) : null}
                                            </React.Fragment>
                                          ) : (
                                            <span
                                              className="linkText ml-2"
                                              onClick={() => showCertModal()}
                                            >
                                              View
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                      <div className="col-md-2">
                                        {diamondInfoDetails.certificate !==
                                        "" ? (
                                          diamondInfoDetails.certificate ==
                                          "GIA" ? (
                                            <img src={GIA} width="70px" />
                                          ) : diamondInfoDetails.certificate ==
                                            "IGI" ? (
                                            <img src={IGI} width="70px" />
                                          ) : diamondInfoDetails.certificate ==
                                            "EGL" ? (
                                            <img src={EGL} width="70px"></img>
                                          ) : diamondInfoDetails.certificate ==
                                            "AGS" ? (
                                            <img src={AGS} width="70px"></img>
                                          ) : (
                                            <img
                                              src={AllImg}
                                              width="70px"
                                            ></img>
                                          )
                                        ) : null}
                                      </div>
                                      <div className="col-md-10">
                                        <p>
                                          This diamond is graded by GIA. This
                                          provides you an authoritative analysis
                                          of your diamond. It also verifies that
                                          your diamond meets all the specific
                                          quality requirements.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                              <div className="prodesc__div">
                                <Tabs
                                  defaultActiveKey="home"
                                  id="uncontrolled-tab-example"
                                  className="tab__div border-0"
                                >
                                  <Tab eventKey="home" title="Item Details">
                                    <Spin spinning={viewStateLoading}>
                                      <div className="tab__contentdiv border__block p-0">
                                        <div className="head__div">
                                          <h4>
                                            {diamondInfoDetails.carat}-Carat{" "}
                                            {diamondInfoDetails.cut} Diamond
                                          </h4>
                                        </div>
                                        <div className="desc__maindiv">
                                          <div className="row top__content m-0">
                                            <div className="col-md-12 mt-3">
                                              <p>
                                                This{" "}
                                                {diamondInfoDetails.color
                                                  ? diamondInfoDetails.color
                                                  : "NA"}
                                                -color, and{" "}
                                                {diamondInfoDetails.clarity
                                                  ? diamondInfoDetails.clarity
                                                  : "NA"}
                                                -clarity diamond comes
                                                accompanied by a diamond grading
                                                report from{" "}
                                                {diamondInfoDetails.certificate
                                                  ? diamondInfoDetails.certificate
                                                  : "NA"}
                                              </p>
                                              <p>
                                                SKU#{" "}
                                                <span className="linkText">
                                                  {diamondInfoDetails.skuNo
                                                    ? diamondInfoDetails.skuNo
                                                    : "NA"}
                                                </span>
                                              </p>
                                            </div>
                                            <div className="col-md-3">
                                              <div className="detailblk">
                                                <p className="font__bold">
                                                  Report
                                                </p>
                                                <p>
                                                  {diamondInfoDetails.certificate
                                                    ? diamondInfoDetails.certificate
                                                    : "NA"}
                                                </p>
                                              </div>
                                            </div>

                                            <div className="col-md-3">
                                              <div className="detailblk">
                                                <p className="font__bold">
                                                  Cut
                                                </p>
                                                <p>
                                                  {diamondInfoDetails.cutGrade
                                                    ? diamondInfoDetails.cutGrade
                                                    : "NA"}
                                                </p>
                                              </div>
                                            </div>

                                            <div className="col-md-3">
                                              <div className="detailblk">
                                                <p className="font__bold">
                                                  Color
                                                </p>
                                                <p>
                                                  {diamondInfoDetails.color
                                                    ? diamondInfoDetails.color
                                                    : "NA"}
                                                </p>
                                              </div>
                                            </div>

                                            <div className="col-md-3">
                                              <div className="detailblk">
                                                <p className="font__bold">
                                                  Clarity
                                                </p>
                                                <p>
                                                  {" "}
                                                  {diamondInfoDetails.clarity
                                                    ? diamondInfoDetails.clarity
                                                    : "NA"}
                                                </p>
                                              </div>
                                            </div>
                                            {/* <div className="col-md-3">
                                            <div className="detailblk">
                                              <p className="font__bold">
                                                Depth%
                                              </p>
                                              <p>
                                                {" "}
                                                {diamondInfoDetails.depth
                                                  ? diamondInfoDetails.depth
                                                  : "NA"}
                                              </p>
                                            </div>
                                          </div>

                                          <div className="col-md-3">
                                            <div className="detailblk">
                                              <p className="font__bold">
                                                Table%
                                              </p>
                                              <p>
                                                {" "}
                                                {diamondInfoDetails.tableMes
                                                  ? diamondInfoDetails.tableMes
                                                  : "NA"}
                                              </p>
                                            </div>
                                          </div>

                                          <div className="col-md-3">
                                            <div className="detailblk">
                                              <p className="font__bold">
                                                Polish
                                              </p>
                                              <p>
                                                {" "}
                                                {diamondInfoDetails.polish
                                                  ? diamondInfoDetails.polish
                                                  : "NA"}
                                              </p>
                                            </div>
                                          </div>

                                          <div className="col-md-3">
                                            <div className="detailblk">
                                              <p className="font__bold">
                                                Girdle
                                              </p>
                                              <p>
                                                {" "}
                                                {diamondInfoDetails.girdle
                                                  ? diamondInfoDetails.girdle
                                                  : "NA"}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="col-md-3">
                                            <div className="detailblk">
                                              <p className="font__bold">
                                                Symmetry
                                              </p>
                                              <p>
                                                {" "}
                                                {diamondInfoDetails.symmetry
                                                  ? diamondInfoDetails.symmetry
                                                  : "NA"}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="col-md-3">
                                            <div className="detailblk">
                                              <p className="font__bold">
                                                Measurements
                                              </p>
                                              <p>
                                                {" "}
                                                {diamondInfoDetails.measurements
                                                  ? diamondInfoDetails.measurements
                                                  : "NA"}
                                              </p>
                                            </div>
                                          </div> */}

                                            <div className="col-md-12">
                                              <div className="detailblk">
                                                <p className="font__bold">
                                                  <span className="subheading">
                                                    {diamondInfoDetails.price ? (
                                                      diamondInfoDetails.price ==
                                                      "0" ? (
                                                        "Call For Price"
                                                      ) : (
                                                        <>
                                                          US$
                                                          {parseInt(
                                                            diamondInfoDetails.price
                                                          )
                                                            .toFixed()
                                                            .replace(
                                                              /\B(?=(\d{3})+(?!\d))/g,
                                                              ","
                                                            )}
                                                        </>
                                                      )
                                                    ) : (
                                                      "NA"
                                                    )}
                                                  </span>
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="desc__maindiv">
                                          <div className="row top__content m-0">
                                            <div className="row itemdetails__btns p-0">
                                              {optionData.dropaHint == true ? (
                                                <div className="col-sm-12 col-md-4">
                                                  <p
                                                    onClick={showDropAHintModal}
                                                  >
                                                    <span className="img__box">
                                                      <img src={drophint} />
                                                    </span>

                                                    <span className="linkText">
                                                      Drop A Hint
                                                    </span>
                                                  </p>
                                                </div>
                                              ) : null}

                                              <div className="col-sm-12 col-md-4">
                                                <p
                                                  onClick={
                                                    showRequestMoreInfoModal
                                                  }
                                                >
                                                  <span className="img__box">
                                                    <img src={infoblack} />
                                                  </span>
                                                  <span className="linkText">
                                                    Request More Info
                                                  </span>
                                                </p>
                                              </div>
                                            </div>

                                            <div className="row itemdetails__btns p-0">
                                              {optionData.emailAFriend ==
                                              true ? (
                                                <div className="col-sm-12 col-md-4">
                                                  <p
                                                    onClick={
                                                      showEmailFriendModal
                                                    }
                                                  >
                                                    <span className="img__box">
                                                      <img src={emailicon} />
                                                    </span>
                                                    <span className="linkText">
                                                      E-Mail A Friend
                                                    </span>
                                                  </p>
                                                </div>
                                              ) : null}

                                              {optionData.isScheduleViewing ==
                                              true ? (
                                                <div className="col-sm-12 col-md-4">
                                                  <p
                                                    onClick={
                                                      showScheduleViewingModal
                                                    }
                                                  >
                                                    <span className="img__box">
                                                      <img src={calender} />
                                                    </span>
                                                    <span className="linkText">
                                                      Schedule Viewing
                                                    </span>
                                                  </p>
                                                </div>
                                              ) : null}
                                            </div>

                                            <div className="row itemdetails__btns p-0">
                                              <div className="col-sm-12 col-md-4">
                                                <p
                                                  onClick={() => {
                                                    handlePrintDiamondList();
                                                  }}
                                                >
                                                  <span className="img__box">
                                                    <img src={Printicon} />
                                                  </span>
                                                  <span className="linkText">
                                                    Print Details
                                                  </span>
                                                </p>
                                              </div>
                                            </div>

                                            <div className="col-md-12">
                                              <p>
                                                Have a question regarding this
                                                item? Our specialists are
                                                available to assist you.
                                              </p>
                                            </div>
                                            <div className="col-md-12">
                                              <Spin
                                                spinning={locationDataLoading}
                                              >
                                                {locationData.length > 0 &&
                                                  locationData.map((x) => {
                                                    return (
                                                      <div className="row">
                                                        {optionData.showAddresses ==
                                                        false ? null : (
                                                          <p className="font__bold mb-0">
                                                            {parse(
                                                              x.locationName
                                                            )}
                                                          </p>
                                                        )}

                                                        {optionData.showContact ==
                                                        false ? null : (
                                                          <p className="font__bold mb-0">
                                                            {x.phone}
                                                          </p>
                                                        )}

                                                        <a
                                                          // href={x.emailID}
                                                          // target="_blank"
                                                          href={`mailto:${
                                                            x && x.emailID
                                                          }`}
                                                          className="link-Text font__bold"
                                                        >
                                                          {x.emailID}
                                                        </a>
                                                      </div>
                                                    );
                                                  })}
                                              </Spin>
                                            </div>

                                            <div className="col-md-12">
                                              <div className="dtl__socialsection">
                                                <button className="dtl__socialbtn fb__btn">
                                                  <img src={DtlFacebook} />
                                                </button>

                                                <button className="dtl__socialbtn pintrest">
                                                  <img src={DtlPintrest} />
                                                </button>

                                                <button className="dtl__socialbtn tweeter">
                                                  <img src={DtlTwitter} />
                                                </button>

                                                <button className="dtl__socialbtn instagram">
                                                  <img src={DtlInstagram} />
                                                </button>

                                                <button className="dtl__socialbtn googlePlus">
                                                  <img src={DtlGooglePlus} />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Spin>
                                  </Tab>
                                  <Tab
                                    eventKey="profile"
                                    title="Specifications"
                                  >
                                    <Spin spinning={viewStateLoading}>
                                      <div className="tab__contentdiv border__block p-0">
                                        <div className="head__div">
                                          <h4>Diamond Details</h4>
                                        </div>
                                        <div className="desc__maindiv">
                                          <div className="desc__div">
                                            <span className="head__txt">
                                              Stock Number
                                            </span>
                                            <span className="desc__txt">
                                              Number{" "}
                                              {diamondInfoDetails.skuNo
                                                ? diamondInfoDetails.skuNo
                                                : ""}
                                            </span>
                                          </div>
                                          <div className="desc__div">
                                            <span className="head__txt">
                                              Price
                                            </span>

                                            <span className="desc__txt">
                                              {diamondInfoDetails.price ? (
                                                diamondInfoDetails.price ==
                                                "0" ? (
                                                  "Call For Price"
                                                ) : (
                                                  <>
                                                    $
                                                    {parseInt(
                                                      diamondInfoDetails.price
                                                    )
                                                      .toFixed()
                                                      .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                      )}
                                                  </>
                                                )
                                              ) : (
                                                "NA"
                                              )}
                                            </span>
                                          </div>

                                          <div className="desc__div">
                                            <span className="head__txt">
                                              Carat Weight{" "}
                                            </span>
                                            <span className="desc__txt">
                                              {diamondInfoDetails.clarity
                                                ? diamondInfoDetails.clarity
                                                : "NA"}
                                            </span>
                                          </div>
                                          <div className="desc__div">
                                            <span className="head__txt">
                                              Cut{" "}
                                            </span>
                                            <span className="desc__txt">
                                              {diamondInfoDetails.cutGrade
                                                ? diamondInfoDetails.cutGrade
                                                : "NA"}
                                            </span>
                                          </div>
                                          <div className="desc__div">
                                            <span className="head__txt">
                                              Color{" "}
                                            </span>
                                            <span className="desc__txt">
                                              {diamondInfoDetails.color
                                                ? diamondInfoDetails.color
                                                : "NA"}
                                            </span>
                                          </div>
                                          <div className="desc__div">
                                            <span className="head__txt">
                                              Depth%
                                            </span>
                                            <span className="desc__txt">
                                              {diamondInfoDetails.depth
                                                ? diamondInfoDetails.depth
                                                : "NA"}
                                            </span>
                                          </div>
                                          <div className="desc__div">
                                            <span className="head__txt">
                                              Table%
                                            </span>
                                            <span className="desc__txt">
                                              {diamondInfoDetails.tableMes
                                                ? diamondInfoDetails.tableMes
                                                : "NA"}
                                            </span>
                                          </div>
                                          <div className="desc__div">
                                            <span className="head__txt">
                                              Polish
                                            </span>
                                            <span className="desc__txt">
                                              {diamondInfoDetails.polish
                                                ? diamondInfoDetails.polish
                                                : "NA"}
                                            </span>
                                          </div>
                                          <div className="desc__div">
                                            <span className="head__txt">
                                              Girdle
                                            </span>
                                            <span className="desc__txt">
                                              {diamondInfoDetails.girdle
                                                ? diamondInfoDetails.girdle
                                                : "NA"}
                                            </span>
                                          </div>
                                          <div className="desc__div">
                                            <span className="head__txt">
                                              Symmetry{" "}
                                            </span>
                                            <span className="desc__txt">
                                              {diamondInfoDetails.symmetry
                                                ? diamondInfoDetails.symmetry
                                                : "NA"}
                                            </span>
                                          </div>
                                          <div className="desc__div">
                                            <span className="head__txt">
                                              Measurements
                                            </span>
                                            <span className="desc__txt">
                                              {diamondInfoDetails.measurements
                                                ? diamondInfoDetails.measurements
                                                : "NA"}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </Spin>
                                  </Tab>
                                </Tabs>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="row">
                          <div className="col-md-12 mb-3">
                            {optionData.internalLinkDL == false ? null : (
                              <span
                                className="linkText"
                                onClick={() => setInternalUseModal(true)}
                              >
                                For Internal Use Only
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="gview__div">
                              {/* Similar Table to be displayed here -----Jash note */}
                              <span className="font__bold mr-2 similardiamond__fontsize">
                                {TotalRowCount} Similar Diamonds
                              </span>
                            </div>
                          </div>
                        </div>

                        <div>
                          <Spin spinning={loading}>
                            <div className="col-md-12 mt-2">
                              <div className="lviewprod__tbl info__tooltip__table">
                                <Table
                                  columns={columns}
                                  className={
                                    tableClassAllow == true
                                      ? "info__tooltip__table overflow-visible"
                                      : "info__tooltip__table"
                                  }
                                  onRow={(record, recordIndex) => ({
                                    onClick: (event) => {
                                      handleSetRowDetils(event, record);
                                    },
                                  })}
                                  rowClassName={(record) =>
                                    record.intDiamondId ==
                                    rowDetails.intDiamondId
                                      ? "row-active"
                                      : ""
                                  }
                                  dataSource={list}
                                  scroll={{ x: 600 }}
                                  pagination={false}
                                />
                              </div>

                              <div className="table__bottom__btn single__item">
                                <Pagination
                                  className="text-right"
                                  current={pageNo}
                                  pageSize={pageSize}
                                  total={TotalRowCount}
                                  onChange={onChange}
                                />
                              </div>
                            </div>
                          </Spin>
                        </div>
                        <div className="custom__table print__table">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col">Shape</th>
                                <th scope="col">Carats</th>
                                <th scope="col">Color</th>
                                <th scope="col">Clarity</th>
                                <th scope="col">Cut</th>
                                <th scope="col">Depth</th>
                                <th scope="col">Table</th>
                                <th scope="col">Polish</th>
                                <th scope="col">Symmetry</th>
                                <th scope="col">Measurement</th>
                                <th scope="col">Certificate</th>
                                <th scope="col">Price</th>
                              </tr>
                            </thead>
                            <tbody>
                              {list.map((row) => (
                                <tr>
                                  <td>
                                    <img
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                      }}
                                      src={row.imageFileName}
                                      alt=""
                                    />
                                    <span>{row.txtShape}</span>
                                  </td>
                                  <td>
                                    <span>{row.fltCarat}</span>
                                  </td>
                                  <td>
                                    <span>{row.txtColor}</span>
                                  </td>
                                  <td>
                                    <span>{row.txtClarity}</span>
                                  </td>
                                  <td>
                                    <span>{row.txtCutGrade}</span>
                                  </td>
                                  <td>
                                    <span>{row.fltDepth}</span>
                                  </td>
                                  <td>
                                    <span>{row.fltTable}</span>
                                  </td>
                                  <td>
                                    <span>{row.txtPolish}</span>
                                  </td>
                                  <td>
                                    <span>{row.txtSymmetry}</span>
                                  </td>
                                  <td>
                                    <span>{row.txtMeasurements}</span>
                                  </td>
                                  <td>
                                    <span>{row.txtCertificate}</span>
                                  </td>
                                  <td>
                                    <span>
                                      {row.realPrice ? "$" : null}
                                      {row.realPrice}
                                    </span>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Modal Section Starts */}
            {/* Request More Info Modal Starts */}
            <Modal
              title="Request More Information"
              visible={requestMoreInfoModal}
              style={{ top: 20 }}
              width={700}
              className="request_info"
              onCancel={closeRequestMoreInfoModal}
              footer={[
                <Button key="back" onClick={closeRequestMoreInfoModal}>
                  Cancel
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  loading={requestMoreInfoModalLoading}
                  onClick={handleSubmitRequestMoreInfo}
                >
                  Request
                </Button>,
              ]}
            >
              <div className="row">
                <div className="col-md-12">
                  <p>Our specialist will contact you.</p>
                </div>
                <div class="col-md-12">
                  <div className="table__block">
                    <div className="row p-0 border-0 form__fields mh-auto">
                      <div className="col-md-6">
                        <div className="input__block">
                          <label>
                            Your Name <span class="mandatory">*</span>
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Your Name"
                            value={requestMoreInfoInputData.name}
                            onChange={(e) =>
                              handleRequestMoreInfoChange(e, "name", "input")
                            }
                          />
                          <div>
                            {requestMoreInfoValidation.nameVal && (
                              <p className="error-color-red">
                                {requestMoreInfoValidation.nameVal}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input__block">
                          <label>
                            Your E-Mail Address <span class="mandatory">*</span>
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Your E-Mail Address"
                            value={requestMoreInfoInputData.email}
                            onChange={(e) =>
                              handleRequestMoreInfoChange(e, "email", "input")
                            }
                          />
                          <div>
                            {requestMoreInfoValidation.emailVal && (
                              <p className="error-color-red">
                                {requestMoreInfoValidation.emailVal}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input__block">
                          <label>
                            Your Phone Number <span class="mandatory">*</span>
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Your Phone Number"
                            value={requestMoreInfoInputData.phoneNumber}
                            onChange={(e) =>
                              handleRequestMoreInfoChange(
                                e,
                                "phoneNumber",
                                "input"
                              )
                            }
                          />
                          <div>
                            {requestMoreInfoValidation.phoneNumberVal && (
                              <p className="error-color-red">
                                {requestMoreInfoValidation.phoneNumberVal}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input__block">
                          <label>
                            Contact Preference <span class="mandatory">*</span>
                          </label>
                          <div className="row">
                            <div className="radio__block col-6">
                              <input
                                type="radio"
                                value="byEmail"
                                checked={
                                  requestMoreInfoInputData.contactPreference ==
                                  "byEmail"
                                    ? true
                                    : false
                                }
                                onChange={(e) =>
                                  handleRequestMoreInfoChange(
                                    e,
                                    "contactPreference",
                                    "radio"
                                  )
                                }
                              />
                              <label>By Email</label>
                            </div>
                            <div className="radio__block col-6">
                              <input
                                type="radio"
                                value="byPhone"
                                checked={
                                  requestMoreInfoInputData.contactPreference ==
                                  "byPhone"
                                    ? true
                                    : false
                                }
                                onChange={(e) =>
                                  handleRequestMoreInfoChange(
                                    e,
                                    "contactPreference",
                                    "radio"
                                  )
                                }
                              />
                              <label>By Phone</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="input__block">
                          <label>Write A Personal Message Here ...</label>
                          <textarea
                            value={requestMoreInfoInputData.personalMessage}
                            placeholder="Write A Personal Message Here ..."
                            onChange={(e) =>
                              handleRequestMoreInfoChange(
                                e,
                                "personalMessage",
                                "radio"
                              )
                            }
                          ></textarea>
                          <div>
                            {requestMoreInfoValidation.personalMessageVal && (
                              <p className="error-color-red">
                                {requestMoreInfoValidation.personalMessageVal}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
            {/* Request More Info Modal Ends */}
            {/* Drop A Hint Modal Starts */}
            <Modal
              title="Drop A Hint"
              visible={dropHintModal}
              style={{ top: 20 }}
              width={700}
              className="drop_a_hint"
              onCancel={closeDropHintModal}
              footer={[
                <Button key="back" onClick={closeDropHintModal}>
                  Cancel
                </Button>,
                <Button
                  loading={dropHintModalLoading}
                  key="submit"
                  type="primary"
                  onClick={handleSubmitDropHint}
                >
                  Drop Hint
                </Button>,
              ]}
            >
              <div className="row">
                <div class="col-md-12">
                  <p>Because you deserve this.</p>
                  <div className="table__block">
                    <div className="row p-0 border-0 form__fields mh-auto">
                      <div className="col-md-6">
                        <div className="input__block">
                          <label>Your Name</label>
                          <input
                            type="text"
                            placeholder="Enter Your Name"
                            value={dropHintInputData.name}
                            onChange={(e) =>
                              handleDropHintChange(e, "name", "input")
                            }
                          />
                          <div>
                            {dropHintValidation.nameVal && (
                              <p className="error-color-red">
                                {dropHintValidation.nameVal}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input__block">
                          <label>Your E-Mail</label>
                          <input
                            type="text"
                            placeholder="Enter Your E-Mail"
                            value={dropHintInputData.email}
                            onChange={(e) =>
                              handleDropHintChange(e, "email", "input")
                            }
                          />
                          <div>
                            {dropHintValidation.emailVal && (
                              <p className="error-color-red">
                                {dropHintValidation.emailVal}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input__block">
                          <label>Hint Recipient's Name</label>
                          <input
                            type="text"
                            placeholder="Enter Hint Recipient's Name"
                            value={dropHintInputData.recipientName}
                            onChange={(e) =>
                              handleDropHintChange(e, "recipientName", "input")
                            }
                          />
                          <div>
                            {dropHintValidation.recipientNameVal && (
                              <p className="error-color-red">
                                {dropHintValidation.recipientNameVal}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input__block">
                          <label>Hint Recipient's E-Mail</label>
                          <input
                            type="text"
                            placeholder="Enter Hint Recipient's E-Mail"
                            value={dropHintInputData.recipientEmail}
                            onChange={(e) =>
                              handleDropHintChange(e, "recipientEmail", "input")
                            }
                          />
                          <div>
                            {dropHintValidation.recipientEmailVal && (
                              <p className="error-color-red">
                                {dropHintValidation.recipientEmailVal}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input__block">
                          <label>Reason For This Gift</label>
                          <input
                            type="text"
                            placeholder="Enter Reason For This Gift"
                            value={dropHintInputData.giftReason}
                            onChange={(e) =>
                              handleDropHintChange(e, "giftReason", "input")
                            }
                          />
                          <div>
                            {dropHintValidation.giftReasonVal && (
                              <p className="error-color-red">
                                {dropHintValidation.giftReasonVal}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input__block">
                          <label>Gift Deadline</label>
                          <input
                            type="date"
                            value={dropHintInputData.giftDeadline}
                            onChange={(e) =>
                              handleDropHintChange(e, "giftDeadline", "date")
                            }
                            min={disablePastDate()}
                          />
                          <div>
                            {dropHintValidation.giftDeadlineVal && (
                              <p className="error-color-red">
                                {dropHintValidation.giftDeadlineVal}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="input__block">
                          <label>Add A Personal Message Here ...</label>
                          <textarea
                            value={dropHintInputData.personalMessage}
                            placeholder="Add A Personal Message Here ..."
                            onChange={(e) =>
                              handleDropHintChange(
                                e,
                                "personalMessage",
                                "input"
                              )
                            }
                          ></textarea>
                          <div>
                            {dropHintValidation.personalMessageVal && (
                              <p className="error-color-red">
                                {dropHintValidation.personalMessageVal}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
            {/* Drop A Hint Modal Ends */}
            {/* E-Mail A Friend Modal Starts */}
            <Modal
              title="E-Mail A Friend"
              visible={emailFriendModal}
              width={700}
              style={{ top: 20 }}
              className="email_a_friend"
              onCancel={hideEmailFriendModal}
              footer={[
                <Button key="back" onClick={hideEmailFriendModal}>
                  Cancel
                </Button>,
                <Button
                  key="submit"
                  loading={emailFriendModalLoading}
                  type="primary"
                  onClick={handleSubmitEmailFriend}
                >
                  Send To Friend
                </Button>,
              ]}
            >
              <div className="row">
                <div class="col-md-12">
                  <div className="table__block">
                    <div className="row p-0 border-0 form__fields mh-auto">
                      <div className="col-md-6">
                        <div className="input__block">
                          <label>Your Name</label>
                          <input
                            type="text"
                            placeholder="Enter Your Name"
                            value={emailFriendInputData.name}
                            onChange={(e) =>
                              handleEmailFriendChange(e, "name", "input")
                            }
                          />
                          <div>
                            {emailFriendInputValidation.nameVal && (
                              <p className="error-color-red">
                                {emailFriendInputValidation.nameVal}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input__block">
                          <label>Your E-Mail</label>
                          <input
                            type="text"
                            placeholder="Enter Your E-Mail"
                            value={emailFriendInputData.email}
                            onChange={(e) =>
                              handleEmailFriendChange(e, "email", "input")
                            }
                          />
                          <div>
                            {emailFriendInputValidation.nameVal && (
                              <p className="error-color-red">
                                {emailFriendInputValidation.nameVal}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input__block">
                          <label>Your Friend's Name</label>
                          <input
                            type="text"
                            placeholder="Enter Your Friend's Name"
                            value={emailFriendInputData.friendName}
                            onChange={(e) =>
                              handleEmailFriendChange(e, "friendName", "input")
                            }
                          />
                          <div>
                            {emailFriendInputValidation.friendNameVal && (
                              <p className="error-color-red">
                                {emailFriendInputValidation.friendNameVal}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input__block">
                          <label>Your Friend's E-Mail</label>
                          <input
                            type="text"
                            placeholder="Enter Your Friend's E-Mail"
                            value={emailFriendInputData.friendEmail}
                            onChange={(e) =>
                              handleEmailFriendChange(e, "friendEmail", "input")
                            }
                          />
                          <div>
                            {emailFriendInputValidation.friendEmailVal && (
                              <p className="error-color-red">
                                {emailFriendInputValidation.friendEmailVal}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="input__block">
                          <label>Add A Personal Message Here ...</label>
                          <textarea
                            value={emailFriendInputData.personalMessage}
                            placeholder="Add A Personal Message Here ..."
                            onChange={(e) =>
                              handleEmailFriendChange(
                                e,
                                "personalMessage",
                                "input"
                              )
                            }
                          ></textarea>
                          <div>
                            {emailFriendInputValidation.personalMessageVal && (
                              <p className="error-color-red">
                                {emailFriendInputValidation.personalMessageVal}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
            {/* E-Mail A Friend Modal Ends */}
            {/* Print Details Modal Starts */}
            <Modal
              title="Print Details"
              visible={isModalVisible4}
              style={{ top: 20 }}
              width={700}
              className="print_details"
              onCancel={handleCancel4}
            >
              <div className="row">
                <div class="col-md-12">
                  <div className="table__block">
                    <div className="row p-0 border-0 form__fields mh-auto">
                      <div className="col-md-6">
                        <div className="input__block">
                          <label>Your Name</label>
                          <input type="text" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input__block">
                          <label>Your E-Mail Address</label>
                          <input type="text" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input__block">
                          <label>Your Company Name</label>
                          <input type="text" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input__block">
                          <label>Your Phone Number</label>
                          <input type="text" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input__block">
                          <label>Your Store Phone Number</label>
                          <input type="text" />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="input__block">
                          <label>Write A Personal Message ...</label>
                          <textarea></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
            {/* Print Details Modal Ends */}
            {/* Schedule Viewing Modal Starts */}
            <Modal
              title="Schedule A Viewing"
              style={{ top: 20 }}
              width={700}
              visible={scheduleViewingModal}
              className="schedule_viewing"
              onCancel={closeScheduleViewingModal}
              footer={[
                <Button key="back" onClick={closeScheduleViewingModal}>
                  Cancel
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  onClick={handleSubmitScheduleViewing}
                >
                  Schedule
                </Button>,
              ]}
            >
              <div className="row">
                <div class="col-md-12">
                  <p>See this item and more in our store</p>
                  <div className="table__block">
                    <div className="row p-0 border-0 form__fields mh-auto">
                      <div className="col-md-6">
                        <div className="input__block">
                          <label>Your Name</label>
                          <input
                            type="text"
                            placeholder="Enter Your Name"
                            value={scheduleViewingInputData.name}
                            onChange={(e) =>
                              handleScheduleViewingChange(e, "name", "input")
                            }
                          />
                          <div>
                            {scheduleViewingInputValidation.nameVal && (
                              <p className="error-color-red">
                                {scheduleViewingInputValidation.nameVal}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input__block">
                          <label>Your Email</label>
                          <input
                            type="text"
                            placeholder="Enter Your Email"
                            value={scheduleViewingInputData.email}
                            onChange={(e) =>
                              handleScheduleViewingChange(e, "email", "input")
                            }
                          />
                          <div>
                            {scheduleViewingInputValidation.emailVal && (
                              <p className="error-color-red">
                                {scheduleViewingInputValidation.emailVal}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input__block">
                          <label>Your Phone Number</label>
                          <input
                            type="text"
                            placeholder="Enter Your Phone Number"
                            value={scheduleViewingInputData.phoneNumber}
                            onChange={(e) =>
                              handleScheduleViewingChange(
                                e,
                                "phoneNumber",
                                "input"
                              )
                            }
                          />
                          <div>
                            {scheduleViewingInputValidation.phoneNumberVal && (
                              <p className="error-color-red">
                                {scheduleViewingInputValidation.phoneNumberVal}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input__block">
                          <label>Location</label>
                          <Select
                            className="border__grey"
                            placeholder="Select Location"
                            optionFilterProp="children"
                            onChange={(e) =>
                              handleScheduleViewingChange(
                                e,
                                "location",
                                "select"
                              )
                            }
                            showSearch
                            value={scheduleViewingInputData.location}
                          >
                            {/* {locationData.length > 0 &&
                            locationData.map((item) => {
                              return (
                                <Option value={item.locationName}>
                                  {item.locationName}
                                </Option>
                              );
                            })} */}
                            <Option value="">Select Location</Option>
                            {locationDetails &&
                              locationDetails.map((item, i) => {
                                return (
                                  <Option value={item.LocationId}>
                                    {item.LocationName}
                                  </Option>
                                );
                              })}
                          </Select>

                          <div>
                            {scheduleViewingInputValidation.locationVal && (
                              <p className="error-color-red">
                                {scheduleViewingInputValidation.locationVal}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input__block">
                          <label>When are you available?</label>
                          <input
                            type="date"
                            value={scheduleViewingInputData.appointmentDate}
                            onChange={(e) =>
                              handleScheduleViewingChange(
                                e,
                                "appointmentDate",
                                "date"
                              )
                            }
                            min={disablePastDate()}
                          />
                          <div>
                            {scheduleViewingInputValidation.appointmentDateVal && (
                              <p className="error-color-red">
                                {
                                  scheduleViewingInputValidation.appointmentDateVal
                                }
                              </p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input__block">
                          <label>Appointment Time</label>
                          <input
                            type="time"
                            value={scheduleViewingInputData.appointmentTime}
                            onChange={(e) =>
                              handleScheduleViewingChange(
                                e,
                                "appointmentTime",
                                "time"
                              )
                            }
                          />
                          <div>
                            {scheduleViewingInputValidation.appointmentTimeVal && (
                              <p className="error-color-red">
                                {
                                  scheduleViewingInputValidation.appointmentTimeVal
                                }
                              </p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="input__block">
                          <label>Add A Personal Message Here ...</label>
                          <textarea
                            value={scheduleViewingInputData.address}
                            placeholder="Add A Personal Message Here ..."
                            onChange={(e) =>
                              handleScheduleViewingChange(e, "address", "input")
                            }
                          ></textarea>
                          <div>
                            {scheduleViewingInputValidation.addressVal && (
                              <p className="error-color-red">
                                {scheduleViewingInputValidation.addressVal}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="input__block">
                          <label>Add A Message Here ...</label>
                          <textarea
                            value={scheduleViewingInputData.message}
                            onChange={(e) =>
                              handleScheduleViewingChange(e, "message", "input")
                            }
                          ></textarea>
                          <div>
                            {scheduleViewingInputValidation.messageVal && (
                              <p className="error-color-red">
                                {scheduleViewingInputValidation.messageVal}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
            {/* Schedule Viewing Modal Ends */}
            <Modal
              title="Certificate"
              visible={viewCertModal}
              style={{ top: 20 }}
              className="cert_modal"
              onCancel={closeCertModal}
              footer={[
                <Button key="back" onClick={closeCertModal}>
                  Cancel
                </Button>,
                // <Button key="submit" type="primary" onClick={handleSubmitDropHint}>
                //   Drop Hint
                // </Button>,
              ]}
            >
              <div className="row">
                <div>
                  <Spin spinning={iframeLoading}>
                    <iframe
                      width="100%"
                      height="450"
                      onLoad={hideSpinner}
                      src={diamondInfoDetails.certificateLink}
                    />
                  </Spin>
                </div>
              </div>
            </Modal>
            <Modal
              title="Request Certificate"
              visible={requestCertModal}
              style={{ top: 20 }}
              className="request_info"
              onCancel={closeCertInfoModal}
              footer={[
                <Button key="back" onClick={closeCertInfoModal}>
                  Cancel
                </Button>,
                <Button
                  key="submit"
                  loading={requestCertModalLoading}
                  type="primary"
                  onClick={handleSubmitRequestCert}
                >
                  Request
                </Button>,
              ]}
            >
              <div className="row">
                <div class="col-md-12">
                  <div className="table__block">
                    <div className="row p-0 border-0 form__fields mh-auto">
                      <div className="col-md-6">
                        <div className="input__block">
                          <label>Name</label>
                          <input
                            type="text"
                            value={certInfoData.name}
                            onChange={(e) =>
                              handleCertInfoChange(e, "name", "input")
                            }
                          />
                          <div>
                            {certInfoValidation.nameVal && (
                              <p className="error-color-red">
                                {certInfoValidation.nameVal}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input__block">
                          <label>E-Mail Address</label>
                          <input
                            type="text"
                            value={certInfoData.email}
                            onChange={(e) =>
                              handleCertInfoChange(e, "email", "input")
                            }
                          />
                          <div>
                            {certInfoValidation.emailVal && (
                              <p className="error-color-red">
                                {certInfoValidation.emailVal}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input__block">
                          <label>Phone Number</label>
                          <input
                            type="text"
                            value={certInfoData.phoneNumber}
                            onChange={(e) =>
                              handleCertInfoChange(e, "phoneNumber", "input")
                            }
                          />
                          <div>
                            {certInfoValidation.phoneNumberVal && (
                              <p className="error-color-red">
                                {certInfoValidation.phoneNumberVal}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="input__block">
                          <label>Comments</label>
                          <textarea
                            value={certInfoData.comments}
                            onChange={(e) =>
                              handleCertInfoChange(e, "comments", "input")
                            }
                          ></textarea>
                          <div>
                            {certInfoValidation.commentsVal && (
                              <p className="error-color-red">
                                {certInfoValidation.commentsVal}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
            {/* Thumbnail Slider Video Modal Starts */}
            <Modal
              title=""
              style={{ top: 20 }}
              visible={isModalVisible6}
              className="video__modal"
              onCancel={handleCancel6}
              footer={false}
            >
              <div className="video__section">
                <video
                  type="video/youtube"
                  width="100%"
                  height="80%"
                  src="https://up.diacam360.com/scan/90ad_1052947"
                  autoPlay
                  loop
                  controls
                ></video>
              </div>
            </Modal>
            {/* Thumbnail Slider Video Modal Ends */}
            <Modal
              title="For Internal Use Only"
              style={{ top: 20 }}
              visible={internalUseModal}
              width={700}
              className="video__modal"
              onCancel={handleCancelInternalModal}
              footer={[
                <Button key="back" onClick={handleCancelInternalModal}>
                  Cancel
                </Button>,
                <Button
                  key="submit"
                  loading={internalUseModalLoading}
                  onClick={handleSubmitInternalData}
                  className="primary-btn"
                >
                  Submit
                </Button>,
              ]}
            >
              {showDealerInfo == true ? (
                <div className="p-4">
                  <p>
                    <label className="font__bold">Vendor Name:</label>{" "}
                    {dealerInfo && dealerInfo.dealerName
                      ? dealerInfo.dealerName
                      : "NA"}
                  </p>
                  <p>
                    <label className="font__bold"> Vendor Company :</label>{" "}
                    {dealerInfo && dealerInfo.dealerCompany
                      ? dealerInfo.dealerCompany
                      : "NA"}
                  </p>
                  <p>
                    {" "}
                    <label className="font__bold">
                      {" "}
                      Vendor City/State :
                    </label>{" "}
                    {(dealerInfo && dealerInfo.cityName) ||
                    (dealerInfo && dealerInfo.stateName)
                      ? dealerInfo.cityName + "/" + dealerInfo.stateName
                      : "NA"}
                  </p>
                  <p>
                    {" "}
                    <label className="font__bold">
                      Vendor Phone Number:
                    </label>{" "}
                    {dealerInfo && dealerInfo.dealerPhone
                      ? dealerInfo.dealerPhone
                      : "NA"}
                  </p>
                  <p>
                    {" "}
                    <label className="font__bold"> Vendor E-Mail:</label>{" "}
                    {dealerInfo && dealerInfo.dealerEmail
                      ? dealerInfo.dealerEmail
                      : "NA"}
                  </p>
                  <p>
                    {" "}
                    <label className="font__bold">
                      {" "}
                      Vendor lot number of the item:
                    </label>{" "}
                    {dealerInfo1 && dealerInfo1.dealerInventoryNo
                      ? dealerInfo1.dealerInventoryNo
                      : "NA"}
                    {}
                  </p>
                  <p>
                    {" "}
                    <label className="font__bold"> WholeSale Price:</label>{" "}
                    {dealerInfo1 && dealerInfo1.costperCarat
                      ? dealerInfo1.costperCarat
                      : "NA"}
                  </p>
                  {/* <p>
                  {" "}
                  <label className="font__bold"> Third Party:</label> {}
                </p> */}
                  <p>
                    {" "}
                    <label className="font__bold"> Diamond ID:</label>{" "}
                    {dealerInfo1 && dealerInfo1.dInventoryID
                      ? dealerInfo1.dInventoryID
                      : "NA"}
                  </p>
                  {/* <p>
                  {" "}
                  <label className="font__bold"> Seller Name:</label> {}
                </p> */}
                  <p>
                    <label className="font__bold"> Address: </label>{" "}
                    {dealerInfo && dealerInfo.dealerAddress
                      ? dealerInfo.dealerAddress
                      : "NA"}
                  </p>
                </div>
              ) : (
                <div className="form__fields">
                  <div className="col-md-6">
                    <div className="input__block">
                      <label htmlFor="">GF Password</label>
                      <input
                        type="text"
                        value={internalUseData.password}
                        onChange={(e) => setInternalUseData(e.target.value)}
                      />
                      <div>
                        {internalUseValidation.passwordVal && (
                          <p className="error-color-red">
                            {internalUseValidation.passwordVal}
                          </p>
                        )}
                      </div>
                    </div>
                    <span
                      onClick={() =>
                        setInternalUseData({
                          password: "",
                        })
                      }
                    >
                      Reset Password
                    </span>
                  </div>
                </div>
              )}
            </Modal>
            {/* Modal Section Ends */}
          </Spin>
        </Wrapper>
      </body>
    </React.Fragment>
  );
};

export default CompleteYourRing;
