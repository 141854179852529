import {
  SET_RESETMINED_TAB,
  SET_RESETLAB_TAB,
  SET_RESETFANCY_TAB,
  SET_RESETREQUEST_TAB,
  SET_COMPARE_TAB_IDS,
  SET_DIAMOND_DETAILS,
  SET_SAVESEARCH_MINEDTAB,
  SET_SAVEFILTER_MINEDTAB,
  SET_SAVEEVERYTIME_MINEDTAB,
  SET_SWITCHFILTER_MINEDTAB,
  SET_SAVESEARCH_LABTAB,
  SET_SAVEFILTER_LABTAB,
  SET_SAVEEVERYTIME_LABTAB,
  SET_SWITCHFILTER_LABTAB,
  SET_SAVESEARCH_FANCYTAB,
  SET_SAVEFILTER_FANCYTAB,
  SET_SAVEEVERYTIME_FANCYTAB,
  SET_SWITCHFILTER_FANCYTAB,
  SET_COMPARE_PAGE_KEY,
  SET_RESETMINED_TOGGLE,
  SET_RESETLAB_TOGGLE,
  SET_RESETFANCY_TOGGLE,
} from "./types";

export const handleResetMinedToggle = () => (dispatch) => {
  try {
    dispatch({
      type: SET_RESETMINED_TOGGLE,
    });
  } catch (error) {}
};

export const handleResetMinedPage = (data) => (dispatch) => {
  try {
    dispatch({
      type: SET_RESETMINED_TAB,
      payload: data,
    });
  } catch (error) {}
};

export const handleResetLabPage = (data) => (dispatch) => {
  try {
    dispatch({
      type: SET_RESETLAB_TAB,
      payload: data,
    });
  } catch (error) {}
};
export const handleResetLabToggle = () => (dispatch) => {
  
  try {
    dispatch({
      type: SET_RESETLAB_TOGGLE,
    });
  } catch (error) {}
};

export const handleResetFancyPage = (data) => (dispatch) => {
  try {
    dispatch({
      type: SET_RESETFANCY_TAB,
      payload: data,
    });
  } catch (error) {}
};
export const handleResetFancyToggle = () => (dispatch) => {
  try {
    dispatch({
      type: SET_RESETFANCY_TOGGLE,
    });
  } catch (error) {}
};

export const handleResetRequestPage = () => (dispatch) => {
  try {
    dispatch({
      type: SET_RESETREQUEST_TAB,
    });
  } catch (error) {}
};

export const handleComaparePageIds = (data) => (dispatch) => {
  try {
    dispatch({
      type: SET_COMPARE_TAB_IDS,
      payload: data,
    });
  } catch (error) {}
};

export const handleCompareKey = (data) => (dispatch) => {
  try {
    dispatch({
      type: SET_COMPARE_PAGE_KEY,
      payload: data,
    });
  } catch (error) {}
};

export const handleSetDiamondDetails = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_DIAMOND_DETAILS,
      payload: data,
    });
  } catch (error) {}
};

//Using for the toogle from save search and reset
export const handleSetSaveSearchMinedTab = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_SAVESEARCH_MINEDTAB,
      payload: data,
    });
  } catch (error) {}
};

//For the reset obj to remove all values  from minedDiamondFilter
export const handleSetSaveFilterMinedTab = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_SAVEFILTER_MINEDTAB,
      payload: data,
    });
  } catch (error) {}
};

//placing everytime a onchange perform from the mined tab
export const handleSetSaveEveryTimeMinedTab = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_SAVEEVERYTIME_MINEDTAB,
      payload: data,
    });
  } catch (error) {}
};

//Swithing the values to mined filter obj
export const handleSetSwitchFilter = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_SWITCHFILTER_MINEDTAB,
    });
  } catch (error) {}
};

//Using for the toogle from save search and reset for lab
export const handleSetSaveSearchLabTab = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_SAVESEARCH_LABTAB,
      payload: data,
    });
  } catch (error) {}
};

//For the reset obj to remove all values  from labDiamondFilter
export const handleSetSaveFilterLabTab = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_SAVEFILTER_LABTAB,
      payload: data,
    });
  } catch (error) {}
};

//placing everytime a onchange perform from the lab tab
export const handleSetSaveEveryTimeLabTab = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_SAVEEVERYTIME_LABTAB,
      payload: data,
    });
  } catch (error) {}
};

//Swithing the values to lab filter obj
export const handleSetSwitchLabFilter = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_SWITCHFILTER_LABTAB,
    });
  } catch (error) {}
};

//Using for the toogle from save search and reset for lab
export const handleSetSaveSearchFancyTab = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_SAVESEARCH_FANCYTAB,
      payload: data,
    });
  } catch (error) {}
};

//For the reset obj to remove all values  from labDiamondFilter
export const handleSetSaveFilterFancyTab = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_SAVEFILTER_FANCYTAB,
      payload: data,
    });
  } catch (error) {}
};

//placing everytime a onchange perform from the lab tab
export const handleSetSaveEveryTimeFancyTab = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_SAVEEVERYTIME_FANCYTAB,
      payload: data,
    });
  } catch (error) {}
};

//Swithing the values to lab filter obj
export const handleSetSwitchFancyFilter = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_SWITCHFILTER_FANCYTAB,
    });
  } catch (error) {}
};
