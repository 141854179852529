import { SET_LOGIN_DETAILS } from "../../actions/LoginDetails/types";

const initialState = {
  dealerId: 0,
};

export default function handleLoginDetailsReducer(
  loginDetailsState = initialState,
  action
) {
  const { type, payload } = action;
  switch (type) {
    case SET_LOGIN_DETAILS:
      return {
        ...loginDetailsState,
        dealerId: payload,
      };

    default:
      return loginDetailsState;
  }
}
