import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import {
  Tooltip,
  Select,
  Table,
  Slider,
  Checkbox,
  Spin,
  Pagination,
  Modal,
  Button,
  Empty,
  Input,
} from "antd";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";

import roundDiamond from "../../../assets/images/daimond-link.jpg";

import Round from "../../../assets/images/choose-setting/round.svg";
import Radiant from "../../../assets/images/choose-setting/Radiant.svg";
import Asscher from "../../../assets/images/choose-setting/Asscher.svg";
import Cushion from "../../../assets/images/choose-setting/Cushion.svg";
import Emerald from "../../../assets/images/choose-setting/Emerald.svg";
import Heart from "../../../assets/images/choose-setting/Heart.svg";
import Marquise from "../../../assets/images/choose-setting/Marquise.svg";
import Oval from "../../../assets/images/choose-setting/Oval.svg";
import Princess from "../../../assets/images/choose-setting/Princess.svg";
import Pear from "../../../assets/images/choose-setting/Radiant.svg";
import Info_icon from "../../../assets/images/info_icon.png";
import Video_icon from "../../../assets/images/icons/video_icon.svg";
import Close from "../../../assets/images/close.png";
import Eye_icon from "../../../assets/images/eye__icon.svg";

import Rectangle from "../../../assets/images/Rectangle.png";
import {
  handleComaparePageIds,
  handleCompareKey,
  handleSetDiamondDetails,
  handleSetSaveEveryTimeMinedTab,
  handleSetSaveFilterMinedTab,
} from "../../../actions/selectyourdiamond/selectYourDiamond";
import {
  handleShowViewPage,
  handleSetPage,
} from "../../../actions/PageNavigations/PageNavigations";

import { useDispatch, useSelector } from "react-redux";
import SelectYourDiamondService from "../../../services/select-your-diamond.service";
import styled from "styled-components";
import { NotificationManager } from "react-notifications";
import NoDiamond from "../../../assets/images/no_pre.png";
import colorPanelService from "../../../services/color-panel.service";
import { useLocation, useNavigate } from "react-router-dom";

const MinedTab = (props) => {
  const navigate = useNavigate();
  const search = useLocation().search;
  const paramdealerId = new URLSearchParams(search).get("DealerLink");
  const { Search } = Input;
  const resetVal = useSelector((state) => state.selectYourDiamondReducer);
  const loginDetails = useSelector((state) => state.loginDetailsReducer);
  const CompareIds = useSelector(
    (state) => state.selectYourDiamondReducer.compareIds
  );
  const dispatch = useDispatch();
  const [productChange, setProductChange] = useState({});
  const [viewItem, setViewItem] = useState("list");
  const [hideShowFilter, setHideShowFilter] = useState(true);
  const [priceValue, setPriceValue] = useState([0, 9999999]);
  const [caratSlideValue, setCaratSlideValue] = useState([0, 22]);
  const [loading, setLoading] = useState(false);
  const [filterloading, setFilterLoading] = useState(false);
  const [reqDiamondloading, setReqDiamondLoading] = useState(false);
  const [minedList, setMinedList] = useState([]);
  const [isVideo, setIsVideo] = useState(false);
  const [videoImageModal, setVideoImageModal] = useState(false);
  const [selectedRowDetails, setSelectedRowDetails] = useState({});
  const [searchSku, setSearchSku] = useState("");
  const [viewCertModal, setviewCertModal] = useState(false);
  const [iframeLoading, setIframeLoading] = useState(true);
  const [certRow, setCertRow] = useState({});
  const [saveSearchVal, setSaveSearchVal] = useState(false);
  const [hoverInfo, setHoverInfo] = useState(false);
  const [tableClassAllow, setTableClassAllow] = useState(false);
  const [hoverDealerId, setDealerHoverId] = useState();
  const [count, setCount] = useState(0);

  const [state, setState] = useState({
    productChange: {},
    PriceMin: 0,
    PriceMax: 9999999,
    CaratMin: 0,
    CaratMax: 22,
    Shape: [],
    Cut: [],
    Color: [],
    Clarity: [],
    Polish: [],
    Fluorescence: [],
    Symmetry: [],
    Certificate: [],
    TableMin: 0,
    TableMax: 100,
    DepthMin: 0,
    DepthMax: 100,
    disabled: {},
  });
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [diamondFormData, setDiamondFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    emailAddress: "",
    comments: "",
  });
  const [validations, setValidations] = useState({
    firstNameVal: "",
    lastNameVal: "",
    phoneNumberVal: "",
    emailAddressVal: "",
    commentsVal: "",
  });
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [TotalRowCount, setTotalRowCount] = useState(0);
  const [dealerId, setDealerId] = useState();
  const [rowDetails, setRowDetails] = useState({});
  const [sortColumnName, setSortColumnName] = useState("");
  const [sortType, setSortType] = useState("asc");
  const [advanced, setAdvanced] = useState(false);
  const [filterVals, setFilterVals] = useState({
    Shape: [],
    Cut: [],
    Color: [],
    Clarity: [],
    Polish: [],
    Fluorescence: [],
    Symmetry: [],
    Certificate: [],
    TableMin: 0,
    TableMax: 100,
    DepthMin: 0,
    DepthMax: 100,
    PriceMin: 0,
    PriceMax: 9999999,
    CaratMin: 0,
    CaratMax: 22,
    disabled: {},
  });

  const [optionLoading, setShowOptionsLoading] = useState(false);
  const [optionData, setOptionData] = useState({});
  const [requestCertModal, setRequestMoreCertModal] = useState(false);
  const [certInfoData, setcertInfoData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    comments: "",
  });
  const [certInfoValidation, setcertInfoDataValidation] = useState({
    nameVal: "",
    emailVal: "",
    phoneNumberVal: "",
    commentsVal: "",
  });
  const [requestCertModalLoading, setRequestMoreCertModalLoading] =
    useState(false);

  useEffect(() => {
    if (paramdealerId) {
      handleGetOptionsDetails(paramdealerId);
      if (paramdealerId) {
        if (resetVal)
          handleGetMinedDiamondFilters(
            paramdealerId,
            pageNo,
            pageSize,
            resetVal.saveSearchMined
          );
        setDealerId(paramdealerId);
      }
    }
  }, [resetVal.saveSearchMined, resetVal.resetMinedToogle]);

  const handleGetOptionsDetails = async (id) => {
    let inputData = {
      dealerID: Number(id),
      type: "DiamondLink",
    };
    setShowOptionsLoading(true);
    try {
      await SelectYourDiamondService.GetOptionsDetails(inputData)
        .then((response) => {
          setShowOptionsLoading(false);
          let msg = response.data.message;
          let responseData = response.data.responseData[0];
          if (responseData) {
            setOptionData(responseData);
            setSortColumnName(responseData.searchSortName);
            setSortType(responseData.searchSortOrder);
          } else {
            setOptionData({});
          }
        })
        .catch((error) => {
          console.log(error);
          setShowOptionsLoading(false);
        });
    } catch (error) {
      console.log(error);
      setShowOptionsLoading(false);
    }
  };

  const handleOnChange = (row, e) => {
    const temp = minedList.map((x) => {
      if (x.intDiamondId == row.intDiamondId) {
        return { ...x, checkbox: e.target.checked == true ? true : false };
      } else return x;
    });

    let saveRow = temp.filter((obj) => obj.checkbox == true);
    setMinedList(temp);
    var CompareIds = saveRow.map((x) => x.intDiamondId);
    dispatch(handleComaparePageIds(CompareIds));
    dispatch(handleCompareKey("Mined"));
  };

  const showCertInfoModal = (details) => {
    setRequestMoreCertModal(true);
    setSelectedRowDetails(details);
  };
  const closeCertInfoModal = () => {
    setRequestMoreCertModal(false);
    setSelectedRowDetails({});
    setcertInfoData({
      name: "",
      email: "",
      phoneNumber: "",
      comments: "",
    });
    setcertInfoDataValidation({
      nameVal: "",
      emailVal: "",
      phoneNumberVal: "",
      commentsVal: "",
    });
  };
  const handleRequestCertificateValidation = () => {
    const { name, email, phoneNumber, comments } = certInfoData;
    const certInfoValidation = {
      nameVal: "",
      emailVal: "",
      phoneNumberVal: "",
      commentsVal: "",
    };
    let isValid = true;

    if (!name) {
      isValid = false;
      certInfoValidation.nameVal = "First Name is Compulsory";
    }

    var validRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!email) {
      isValid = false;
      certInfoValidation.emailVal = "Email address is compulsory";
    } else if (!email.match(validRegex)) {
      isValid = false;
      certInfoValidation.emailVal = "Email address is invalid";
    } else {
      certInfoValidation.emailVal = "";
    }

    if (!phoneNumber) {
      isValid = false;
      certInfoValidation.phoneNumberVal = "Phone number is compulsory";
    } else if (
      (phoneNumber.length >= 10 && phoneNumber.includes("-")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes(",")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes("+"))
    ) {
      certInfoValidation.phoneNumberVal = "";
    } else if (!/^(?!0{10,15})(\+\d{1,3}[-]?)?\d{10,15}$/.test(phoneNumber)) {
      isValid = false;
      certInfoValidation.phoneNumberVal = "Phone number is invalid";
    } else {
      certInfoValidation.phoneNumberVal = "";
    }

    if (!comments) {
      isValid = false;
      certInfoValidation.commentsVal = "Comments is Compulsory";
    }

    if (!isValid) {
      setcertInfoDataValidation(certInfoValidation);
    }

    return isValid;
  };

  const handleCertInfoChange = (e, name, type) => {
    setcertInfoData({
      ...certInfoData,
      [name]: e.target.value,
    });
  };

  const handleSubmitRequestCert = () => {
    console.log(certInfoData);
    handleGetDiamondLinkFiltersRequestCertificate();
  };

  const handleGetDiamondLinkFiltersRequestCertificate = () => {
    const isValid = handleRequestCertificateValidation();
    if (!isValid) {
      return false;
    }
    try {
      let inputData = {
        dealerID: dealerId.toString(),
        did: selectedRowDetails.intDiamondId,
        name: certInfoData.name,
        email: certInfoData.email,
        phone: certInfoData.phoneNumber,
        comments: certInfoData.comments,
        retailerID: selectedRowDetails.dealerID,
      };
      setRequestMoreCertModalLoading(true);
      SelectYourDiamondService.GetDiamondLinkFiltersRequestCertificate(
        inputData
      )
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            NotificationManager.success(
              "Your request has been sent to GemFind. A representative will be contacting you shortly."
            );
            setcertInfoData({
              name: "",
              email: "",
              phoneNumber: "",
              comments: "",
            });
            setcertInfoDataValidation({
              nameVal: "",
              emailVal: "",
              phoneNumberVal: "",
              commentsVal: "",
            });
            setRequestMoreCertModalLoading(false);
            setRequestMoreCertModal(false);
          } else {
            NotificationManager.success(
              "Your request has been sent to GemFind. A representative will be contacting you shortly."
            );
            setRequestMoreCertModalLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setRequestMoreCertModalLoading(false);
        });
    } catch (error) {
      console.log(error);
      setRequestMoreCertModalLoading(false);
    }
  };

  const columns = [
    {
      title: "",
      dataIndex: "checkbox",
      width: 20,
      render: (item, row) => {
        return (
          <div
            className="action__btns"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Checkbox
              name="checkbox"
              checkedChildren="Yes"
              unCheckedChildren="No"
              checked={row.checkbox == true ? true : false}
              onChange={(e) => handleOnChange(row, e, "checkbox")}
            />
          </div>
        );
      },
    },

    {
      title: "Shape",
      dataIndex: "txtShape",
      sorter: (a, b) => {},
      showSorterTooltip: false,
      width: 350,
      render: (item, row) => {
        return (
          <div className="shape__img">
            <img src={row.imageFileName} alt="" />
            <span>{row.txtShape}</span>
          </div>
        );
      },
    },
    {
      title: "SKU",
      className: optionData.showInhouseDiamonds == false ? "display-none" : "",
      dataIndex: "intDiamondId",
      sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    {
      title: "Carats",
      dataIndex: "fltCarat",
      sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    {
      title: "Color",
      dataIndex: "txtColor",
      sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    {
      title: "Clarity",
      dataIndex: "txtClarity",
      sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    {
      title: "Cut",
      dataIndex: "txtCutGrade",
      sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    {
      title: "In House",
      dataIndex: "inhouse",
      className:
        optionData.showInhouseFirst == false
          ? "display-none hideInMobile"
          : "hideInMobile",
      sorter: (a, b) => {},
      showSorterTooltip: false,
      width: 150,
    },
    // {
    //   title: "Intensity",
    //   dataIndex: "txtIntensity",
    //   sorter: (a, b) => {},
    //   showSorterTooltip: false,
    //   width: 150,
    // },
    {
      title: "Depth",
      dataIndex: "fltDepth",
      className: "hideInMobile",
      sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    {
      title: "Table",
      dataIndex: "fltTable",
      className: "hideInMobile",
      sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    {
      title: "Polish",
      dataIndex: "txtPolish",
      className: "hideInMobile",
      sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    {
      title: "Measurement",
      dataIndex: "txtMeasurements",
      className: "hideInMobile",
      sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    {
      title: "Cert.",
      dataIndex: "txtCertificate",
      className:
        optionData.showCertificateDiamondSearch == false ? "display-none" : "",
      sorter: (a, b) => {},
      showSorterTooltip: false,
      render: (item, row) => {
        return (
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {row.txtCertificate == "GIA" &&
            optionData.showGIAreportchecklink == false ? (
              <span>{row.txtCertificate}</span>
            ) : (
              <Fragment>
                {optionData.disableCertificate == true ? (
                  <span
                    className="link-Text"
                    onClick={() => handleCertModalOpen(row)}
                  >
                    {row.txtCertificate}
                  </span>
                ) : (
                  <span
                    className="link-Text"
                    onClick={() => showCertInfoModal(row)}
                  >
                    {row.txtCertificate}
                  </span>
                )}
              </Fragment>
            )}
          </div>
        );
      },
    },
    {
      title: "Price",
      dataIndex: "realPrice",
      sorter: (a, b) => {},
      showSorterTooltip: false,
      render: (item, row) => {
        return (
          <div>
            {row.realPrice == "0" ? (
              <>
                Call <span className="hideInMobile">For Price</span>
              </>
            ) : (
              <span>
                $
                {parseInt(row.realPrice)
                  .toFixed()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </span>
            )}
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (index, row) => (
        <div>
          <div className="action__btns">
            {/* <div className="image__block">
              <img src={Info_icon} alt="" />
            </div>
            <div className="image__block">handleHoverData
              <img
                src={Video_icon}
                alt=""
              />
            </div> */}
            <div className="image__block">
              <img
                src={Eye_icon}
                alt=""
                onClick={() => handleViewDetails(row)}
              />
            </div>
            <div className="image__block hideInMobile">
              <img
                src={Info_icon}
                alt=""
                onMouseOver={() => handleHoverData(row)}
                onMouseOut={() => handleHoverCancelData(row)}
              />
            </div>
          </div>
          <div
            className={
              hoverInfo == true && row.intDiamondId == hoverDealerId
                ? "info__tooltip"
                : "display-none"
            }
          >
            <p>
              <span>Depth</span>
              <span>{row.fltDepth == "" ? "NA" : row.fltDepth + "%"}</span>
            </p>
            <p>
              <span>Table</span>
              <span>{row.fltTable == "" ? "NA" : row.fltTable + "%"}</span>
            </p>
            <p>
              <span>Polish</span>
              <span>{row.txtPolish}</span>
            </p>
            <p>
              <span>Symmetry</span>
              <span>{row.txtSymmetry}</span>
            </p>
            <p>
              <span>Measurement</span>
              <span>{row.txtMeasurements}</span>
            </p>
          </div>
        </div>
      ),
    },
  ];

  const handleHoverData = (row) => {
    setHoverInfo(true);
    setDealerHoverId(row.intDiamondId);
    setTableClassAllow(true);
  };
  const handleHoverCancelData = (row) => {
    setHoverInfo(false);
    setDealerHoverId();
    setTableClassAllow(false);
  };

  const { Option } = Select;

  const handleProductChangeOpen = (intDiamondId) => {
    var newproductChange = productChange;
    newproductChange[intDiamondId] = true;
    setProductChange((prevState) => ({
      ...prevState,
      ...newproductChange,
    }));
  };

  const handleProductChangeClose = (intDiamondId) => {
    var newproductChange = productChange;
    newproductChange[intDiamondId] = false;
    setProductChange((prevState) => ({
      ...prevState,
      ...newproductChange,
    }));
  };

  const handleViewChange = (viewname) => {
    setViewItem(viewname);
  };

  const handleViewDetails = (details) => {
    var obj = details;
    obj["pageName"] = "Mined";
    obj["shapeFilters"] = filterVals.Shape;
    // dispatch(handleShowViewPage());
    // dispatch(handleSetDiamondDetails(obj));
    console.log(obj);
    navigate(
      "/viewDiamondDetails" +
        `?isSocialPage=True&DealerID=${obj.gfLinkID}&diamondId=${obj.intDiamondId}&pageNo=1&isAllowed=True&pageName=${obj.pageName}&shapeFilters=${filterVals.Shape}&txtCutlet=${obj.txtCulet}&txtClarity=${obj.txtClarity}&intColorPriority=${obj.intColorPriority}`
    );
    window.parent.postMessage("submitted form", "*");
  };
  const handleHideShowFilter = () => {
    setHideShowFilter(!hideShowFilter);
  };

  const onKeyPressEvent1 = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9.]").test(keyValue)) event.preventDefault();
    return;
  };

  const firstSliderPriceValue = (e, num) => {
    let newArr = [...priceValue];
    var StrValue = e.target.value && e.target.value.replace("$", "");
    var Value = StrValue.replace(/,/g, "");

    if (Number(parseFloat(Value.replace(/,/g, ""))) > state.PriceMax) {
      e.preventDefault();
    } else if (Number(num) == 0) {
      if (Value == "") {
        newArr[0] = 0;
        setPriceValue(newArr);
        var tempArray = filterVals;
        tempArray["PriceMin"] = 0;
        setFilterVals(tempArray);
        dispatch(handleSetSaveEveryTimeMinedTab(tempArray));
      }
      // else {
      //   newArr[0] = Value.replace(/,/g, "");
      //   setPriceValue(newArr);
      //   var tempArray = filterVals;
      //   tempArray["PriceMin"] = Value.replace(/,/g, "");
      //   setFilterVals(tempArray);
      //   dispatch(handleSetSaveEveryTimeMinedTab(tempArray));
      // }
      else {
        if (newArr[0] == 0 && Value.includes(".")) {
          newArr[0] = Value;
          setPriceValue(newArr);
        } else {
          if (newArr[0] == 0) {
            newArr[0] = parseFloat(Value);
            setPriceValue(newArr);
          } else {
            newArr[0] = Value;
            setPriceValue(newArr);
          }
        }

        var tempArray = filterVals;
        tempArray["PriceMin"] = Value;
        setFilterVals(tempArray);
        dispatch(handleSetSaveEveryTimeMinedTab(tempArray));
      }

      handleGetMinedDiamondList(
        dealerId,
        pageNo,
        pageSize,
        filterVals.Shape,
        newArr[0] == "" ? 0 : newArr[0],
        priceValue[1],
        caratSlideValue[0],
        caratSlideValue[1],
        state.Color,
        state.Clarity,
        state.Cut,
        state.Polish,
        state.Symmetry,
        state.Fluorescence,
        filterVals.DepthMin,
        filterVals.DepthMax,
        filterVals.TableMin,
        filterVals.TableMax,
        filterVals.Certificate, // Certificate
        searchSku, //Sku
        filterVals.Color, // color
        filterVals.Clarity, // clarity
        filterVals.Cut, // cut
        filterVals.Polish, // polish
        filterVals.Symmetry, //symmetry
        filterVals.Fluorescence //fluorescence
      );
    } else if (Number(num) == 1) {
      if (Value == "") {
        newArr[1] = 0;
        setPriceValue(newArr);
        setPriceValue(newArr);
        var tempArray = filterVals;
        tempArray["PriceMax"] = 0;
        setFilterVals(tempArray);
        dispatch(handleSetSaveEveryTimeMinedTab(tempArray));
      }
      // else {
      //   newArr[1] = Value.replace(/,/g, "");
      //   setPriceValue(newArr);
      //   var tempArray = filterVals;
      //   tempArray["PriceMax"] = Value.replace(/,/g, "");
      //   setFilterVals(tempArray);
      //   dispatch(handleSetSaveEveryTimeMinedTab(tempArray));
      // }
      else {
        if (newArr[1] == 0 && Value.includes(".")) {
          newArr[1] = Value;
          setPriceValue(newArr);
        } else {
          if (newArr[1] == 0) {
            newArr[1] = parseFloat(Value);
            setPriceValue(newArr);
          } else {
            newArr[1] = Value;
            setPriceValue(newArr);
          }
        }

        var tempArray = filterVals;
        tempArray["PriceMax"] = Value;
        setFilterVals(tempArray);
        dispatch(handleSetSaveEveryTimeMinedTab(tempArray));
      }
      handleGetMinedDiamondList(
        dealerId,
        pageNo,
        pageSize,
        filterVals.Shape,
        priceValue[0],
        newArr[1] == "" ? 0 : newArr[1],
        caratSlideValue[0],
        caratSlideValue[1],
        state.Color,
        state.Clarity,
        state.Cut,
        state.Polish,
        state.Symmetry,
        state.Fluorescence,
        filterVals.DepthMin,
        filterVals.DepthMax,
        filterVals.TableMin,
        filterVals.TableMax,
        filterVals.Certificate, // Certificate
        searchSku, //Sku
        filterVals.Color, // color
        filterVals.Clarity, // clarity
        filterVals.Cut, // cut
        filterVals.Polish, // polish
        filterVals.Symmetry, //symmetry
        filterVals.Fluorescence //fluorescence
      );
    }
  };

  const firstsetCaratSlideValue = (e, num) => {
    let newArr = [...caratSlideValue];
    var StrValue = e.target.value && e.target.value.replace("$", "");
    var Value = StrValue.replace(/,/g, "");
    if (Number(parseFloat(e.target.value.replace(/,/g, ""))) > state.CaratMax) {
      e.preventDefault();
    } else if (Number(num) == 0) {
      if (e.target.value == "") {
        newArr[0] = 0;
        setCaratSlideValue(newArr);
        var tempArray = filterVals;
        tempArray["CaratMin"] = 0;
        setFilterVals(tempArray);
        dispatch(handleSetSaveEveryTimeMinedTab(tempArray));
      }
      // else {
      //   newArr[0] = e.target.value.replace(/,/g, "");
      //   setCaratSlideValue(newArr);
      //   var tempArray = filterVals;
      //   tempArray["CaratMin"] = e.target.value.replace(/,/g, "");
      //   setFilterVals(tempArray);
      //   dispatch(handleSetSaveEveryTimeMinedTab(tempArray));
      // }
      else {
        if (newArr[0] == 0 && Value.includes(".")) {
          newArr[0] = Value;
          setCaratSlideValue(newArr);
        } else {
          if (newArr[0] == 0) {
            newArr[0] = parseFloat(Value);
            setCaratSlideValue(newArr);
          } else {
            newArr[0] = Value;
            setCaratSlideValue(newArr);
          }
        }

        var tempArray = filterVals;
        tempArray["CaratMin"] = Value;
        setFilterVals(tempArray);
        dispatch(handleSetSaveEveryTimeMinedTab(tempArray));
      }

      handleGetMinedDiamondList(
        dealerId,
        pageNo,
        pageSize,
        filterVals.Shape,
        priceValue[0],
        priceValue[1],
        newArr[0] == "" ? 0 : newArr[0],
        caratSlideValue[1],
        state.Color,
        state.Clarity,
        state.Cut,
        state.Polish,
        state.Symmetry,
        state.Fluorescence,
        filterVals.DepthMin,
        filterVals.DepthMax,
        filterVals.TableMin,
        filterVals.TableMax,
        filterVals.Certificate, // Certificate
        searchSku, //Sku
        filterVals.Color, // color
        filterVals.Clarity, // clarity
        filterVals.Cut, // cut
        filterVals.Polish, // polish
        filterVals.Symmetry, //symmetry
        filterVals.Fluorescence //fluorescence
      );
      console.log(caratSlideValue);
    } else if (Number(num) == 1) {
      if (e.target.value == "") {
        newArr[1] = 0;
        setCaratSlideValue(newArr);
        var tempArray = filterVals;
        tempArray["CaratMax"] = 0;
        setFilterVals(tempArray);
        dispatch(handleSetSaveEveryTimeMinedTab(tempArray));
      }
      // else {
      //   newArr[1] = e.target.value.replace(/,/g, "");
      //   setCaratSlideValue(newArr);
      //   var tempArray = filterVals;
      //   tempArray["CaratMax"] = e.target.value.replace(/,/g, "");
      //   setFilterVals(tempArray);
      //   dispatch(handleSetSaveEveryTimeMinedTab(tempArray));
      // }
      else {
        if (newArr[1] == 0 && Value.includes(".")) {
          newArr[1] = Value;
          setCaratSlideValue(newArr);
        } else {
          if (newArr[1] == 0) {
            newArr[1] = parseFloat(Value);
            setCaratSlideValue(newArr);
          } else {
            newArr[1] = Value;
            setCaratSlideValue(newArr);
          }
        }

        var tempArray = filterVals;
        tempArray["CaratMax"] = Value;
        setFilterVals(tempArray);
        dispatch(handleSetSaveEveryTimeMinedTab(tempArray));
      }

      handleGetMinedDiamondList(
        dealerId,
        pageNo,
        pageSize,
        filterVals.Shape,
        priceValue[0],
        priceValue[1],
        caratSlideValue[0],
        newArr[1] == "" ? 0 : newArr[1],
        state.Color,
        state.Clarity,
        state.Cut,
        state.Polish,
        state.Symmetry,
        state.Fluorescence,
        filterVals.DepthMin,
        filterVals.DepthMax,
        filterVals.TableMin,
        filterVals.TableMax,
        filterVals.Certificate, // Certificate
        searchSku, //Sku
        filterVals.Color, // color
        filterVals.Clarity, // clarity
        filterVals.Cut, // cut
        filterVals.Polish, // polish
        filterVals.Symmetry, //symmetry
        filterVals.Fluorescence //fluorescence
      );
    }
  };

  const handleLabGrownFilterChange = (val, name, type) => {
    let tempArray = filterVals[name];
    if (type == "filter") {
      if (tempArray.includes(val)) {
        tempArray = tempArray.filter((item) => item !== val);
      } else {
        tempArray.push(val);
        console.log(tempArray);
      }
      console.log(filterVals, "-------");
      // if (name == "Polish") {
      //
      //   if (count == 0) {
      //     setFilterVals({
      //       ...filterVals,
      //       ["Polish"]: tempArray,
      //       ["Symmetry"]: [0],
      //     });
      //   } else {
      //     setFilterVals({
      //       ...filterVals,
      //       ["Polish"]: tempArray,
      //     });
      //   }
      // } else if (name == "Symmetry") {
      //
      //   if (count == 0) {
      //     setFilterVals({
      //       ...filterVals,
      //       ["Polish"]: [0],
      //       ["Symmetry"]: tempArray,
      //     });
      //   } else {
      //     setFilterVals({
      //       ...filterVals,
      //       ["Symmetry"]: tempArray,
      //     });
      //   }
      // } else {
      setFilterVals({
        ...filterVals,
        [name]: tempArray,
      });
      // }
      setCount(1);
      console.log(filterVals);
      if (name == "Shape") {
        handleGetMinedDiamondList(
          dealerId,
          pageNo,
          pageSize,
          tempArray,
          priceValue[0],
          priceValue[1],
          caratSlideValue[0],
          caratSlideValue[1],
          state.Color,
          state.Clarity,
          state.Cut,
          state.Polish,
          state.Symmetry,
          state.Fluorescence,
          filterVals.DepthMin,
          filterVals.DepthMax,
          filterVals.TableMin,
          filterVals.TableMax,
          filterVals.Certificate, // Certificate
          searchSku, //Sku
          filterVals.Color, // color
          filterVals.Clarity, // clarity
          filterVals.Cut, // cut
          filterVals.Polish, // polish
          filterVals.Symmetry, //symmetry
          filterVals.Fluorescence //fluorescence
        );
      }
      if (name == "Cut") {
        handleGetMinedDiamondList(
          dealerId,
          pageNo,
          pageSize,
          filterVals.Shape,
          priceValue[0],
          priceValue[1],
          caratSlideValue[0],
          caratSlideValue[1],
          state.Color,
          state.Clarity,
          state.Cut,
          state.Polish,
          state.Symmetry,
          state.Fluorescence,
          filterVals.DepthMin,
          filterVals.DepthMax,
          filterVals.TableMin,
          filterVals.TableMax,
          filterVals.Certificate, // Certificate
          searchSku, //Sku
          filterVals.Color, // color
          filterVals.Clarity, // clarity
          tempArray, // cut
          filterVals.Polish, // polish
          filterVals.Symmetry, //symmetry
          filterVals.Fluorescence //fluorescence
        );
      }
      if (name == "Color") {
        handleGetMinedDiamondList(
          dealerId,
          pageNo,
          pageSize,
          filterVals.Shape,
          priceValue[0],
          priceValue[1],
          caratSlideValue[0],
          caratSlideValue[1],
          state.Color,
          state.Clarity,
          state.Cut,
          state.Polish,
          state.Symmetry,
          state.Fluorescence,
          filterVals.DepthMin,
          filterVals.DepthMax,
          filterVals.TableMin,
          filterVals.TableMax,
          filterVals.Certificate, // Certificate
          searchSku, //Sku
          tempArray, // color
          filterVals.Clarity, // clarity
          filterVals.Cut, // cut
          filterVals.Polish, // polish
          filterVals.Symmetry, //symmetry
          filterVals.Fluorescence //fluorescence
        );
      }
      if (name == "Clarity") {
        handleGetMinedDiamondList(
          dealerId,
          pageNo,
          pageSize,
          filterVals.Shape,
          priceValue[0],
          priceValue[1],
          caratSlideValue[0],
          caratSlideValue[1],
          state.Color,
          state.Clarity,
          state.Cut,
          state.Polish,
          state.Symmetry,
          state.Fluorescence,
          filterVals.DepthMin,
          filterVals.DepthMax,
          filterVals.TableMin,
          filterVals.TableMax,
          filterVals.Certificate, // Certificate
          searchSku, //Sku
          filterVals.Color, // color
          tempArray, // clarity
          filterVals.Cut, // cut
          filterVals.Polish, // polish
          filterVals.Symmetry, //symmetry
          filterVals.Fluorescence //fluorescence
        );
      }

      if (name === "Polish") {
        handleGetMinedDiamondList(
          dealerId,
          pageNo,
          pageSize,
          filterVals.Shape,
          priceValue[0],
          priceValue[1],
          caratSlideValue[0],
          caratSlideValue[1],
          state.Color,
          state.Clarity,
          state.Cut,
          state.Polish,
          state.Symmetry,
          state.Fluorescence,
          filterVals.DepthMin,
          filterVals.DepthMax,
          filterVals.TableMin,
          filterVals.TableMax,
          filterVals.Certificate, // Certificate
          searchSku, //Sku
          filterVals.Color, // color
          filterVals.Clarity, // clarity
          filterVals.Cut, // cut
          tempArray, // polish
          filterVals.Symmetry, //symmetry
          filterVals.Fluorescence //fluorescence
        );
      }
      if (name == "Fluorescence") {
        handleGetMinedDiamondList(
          dealerId,
          pageNo,
          pageSize,
          filterVals.Shape,
          priceValue[0],
          priceValue[1],
          caratSlideValue[0],
          caratSlideValue[1],
          state.Color,
          state.Clarity,
          state.Cut,
          state.Polish,
          state.Symmetry,
          state.Fluorescence,
          filterVals.DepthMin,
          filterVals.DepthMax,
          filterVals.TableMin,
          filterVals.TableMax,
          filterVals.Certificate, // Certificate
          searchSku, //Sku
          filterVals.Color, // color
          filterVals.Clarity, // clarity
          filterVals.Cut, // cut
          filterVals.Polish, // polish
          filterVals.Symmetry, //symmetry
          tempArray //fluorescence
        );
      }
      if (name == "Symmetry") {
        handleGetMinedDiamondList(
          dealerId,
          pageNo,
          pageSize,
          filterVals.Shape,
          priceValue[0],
          priceValue[1],
          caratSlideValue[0],
          caratSlideValue[1],
          state.Color,
          state.Clarity,
          state.Cut,
          state.Polish,
          state.Symmetry,
          state.Fluorescence,
          filterVals.DepthMin,
          filterVals.DepthMax,
          filterVals.TableMin,
          filterVals.TableMax,
          filterVals.Certificate, // Certificate
          searchSku, //Sku
          filterVals.Color, // color
          filterVals.Clarity, // clarity
          filterVals.Cut, // cut
          filterVals.Polish, // polish
          tempArray, //symmetry
          filterVals.Fluorescence //fluorescence
        );
      }
    } else {
      setFilterVals({
        ...filterVals,
        [name]: val,
      });
      if (name == "DepthMin") {
        handleGetMinedDiamondList(
          dealerId,
          pageNo,
          pageSize,
          filterVals.Shape,
          priceValue[0],
          priceValue[1],
          caratSlideValue[0],
          caratSlideValue[1],
          state.Color,
          state.Clarity,
          state.Cut,
          state.Polish,
          state.Symmetry,
          state.Fluorescence,
          val,
          filterVals.DepthMax,
          filterVals.TableMin,
          filterVals.TableMax,
          filterVals.Certificate, // Certificate
          searchSku, //Sku
          filterVals.Color, // color
          filterVals.Clarity, // clarity
          filterVals.Cut, // cut
          filterVals.Polish, // polish
          filterVals.Symmetry, //symmetry
          filterVals.Fluorescence //fluorescence
        );
      }
      if (name == "DepthMax") {
        handleGetMinedDiamondList(
          dealerId,
          pageNo,
          pageSize,
          filterVals.Shape,
          priceValue[0],
          priceValue[1],
          caratSlideValue[0],
          caratSlideValue[1],
          state.Color,
          state.Clarity,
          state.Cut,
          state.Polish,
          state.Symmetry,
          state.Fluorescence,
          filterVals.DepthMin,
          val,
          filterVals.TableMin,
          filterVals.TableMax,
          filterVals.Certificate, // Certificate
          searchSku, //Sku
          filterVals.Color, // color
          filterVals.Clarity, // clarity
          filterVals.Cut, // cut
          filterVals.Polish, // polish
          filterVals.Symmetry, //symmetry
          filterVals.Fluorescence //fluorescence
        );
      }
      if (name == "TableMin") {
        handleGetMinedDiamondList(
          dealerId,
          pageNo,
          pageSize,
          filterVals.Shape,
          priceValue[0],
          priceValue[1],
          caratSlideValue[0],
          caratSlideValue[1],
          state.Color,
          state.Clarity,
          state.Cut,
          state.Polish,
          state.Symmetry,
          state.Fluorescence,
          filterVals.DepthMin,
          filterVals.DepthMax,
          val,
          filterVals.TableMax,
          filterVals.Certificate, // Certificate
          searchSku, //Sku
          filterVals.Color, // color
          filterVals.Clarity, // clarity
          filterVals.Cut, // cut
          filterVals.Polish, // polish
          filterVals.Symmetry, //symmetry
          filterVals.Fluorescence //fluorescence
        );
      }
      if (name == "TableMax") {
        handleGetMinedDiamondList(
          dealerId,
          pageNo,
          pageSize,
          filterVals.Shape,
          priceValue[0],
          priceValue[1],
          caratSlideValue[0],
          caratSlideValue[1],
          state.Color,
          state.Clarity,
          state.Cut,
          state.Polish,
          state.Symmetry,
          state.Fluorescence,
          filterVals.DepthMin,
          filterVals.DepthMax,
          filterVals.TableMin,
          val,
          filterVals.Certificate, // Certificate
          searchSku, //Sku
          filterVals.Color, // color
          filterVals.Clarity, // clarity
          filterVals.Cut, // cut
          filterVals.Polish, // polish
          filterVals.Symmetry, //symmetry
          filterVals.Fluorescence //fluorescence
        );
      }
    }

    var vals = filterVals;
    vals[name] = tempArray;
    dispatch(handleSetSaveEveryTimeMinedTab(vals));
  };

  const handleInputDiamondDataChange = (e, name) => {
    setDiamondFormData({
      ...diamondFormData,
      [e.target.name]: e.target.value,
    });
    if (e.target.name == "firstName") {
      setValidations((prevdata) => ({
        ...prevdata,
        firstNameVal: "",
      }));
    }
    if (e.target.name == "lastName") {
      setValidations((prevdata) => ({
        ...prevdata,
        lastNameVal: "",
      }));
    }
    if (e.target.name == "phoneNumber") {
      setValidations((prevdata) => ({
        ...prevdata,
        phoneNumberVal: "",
      }));
    }
    if (e.target.name == "emailAddress") {
      setValidations((prevdata) => ({
        ...prevdata,
        emailAddressVal: "",
      }));
    }
    if (e.target.name == "comments") {
      setValidations((prevdata) => ({
        ...prevdata,
        commentsVal: "",
      }));
    }
  };

  const handleInputDiamondDataReset = () => {
    setDiamondFormData({
      firstName: "",
      lastName: "",
      phoneNumber: "",
      emailAddress: "",
      comments: "",
    });
    setValidations({
      firstNameVal: "",
      lastNameVal: "",
      phoneNumberVal: "",
      emailAddressVal: "",
      commentsVal: "",
    });
  };

  const handleValidation = () => {
    const { firstName, lastName, phoneNumber, emailAddress, comments } =
      diamondFormData;
    const validations = {
      firstNameVal: "",
      lastNameVal: "",
      phoneNumberVal: "",
      emailAddressVal: "",
      commentsVal: "",
    };
    let isValid = true;

    if (!firstName.trim()) {
      isValid = false;
      validations.firstNameVal = "First Name is Compulsory";
    }
    if (!lastName.trim()) {
      isValid = false;
      validations.lastNameVal = "Last Name is Compulsory";
    }

    if (!phoneNumber.trim()) {
      isValid = false;
      validations.phoneNumberVal = "Phone number is compulsory";
    } else if (
      (phoneNumber.length >= 10 && phoneNumber.includes("-")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes(",")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes("+"))
    ) {
      validations.phoneNumberVal = "";
    } else if (!/^(?!0{10,15})(\+\d{1,3}[-]?)?\d{10,15}$/.test(phoneNumber)) {
      isValid = false;
      validations.phoneNumberVal = "Phone number is invalid";
    } else {
      validations.phoneNumberVal = "";
    }

    var validRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!emailAddress.trim()) {
      isValid = false;
      validations.emailAddressVal = "Email address is compulsory";
    } else if (!emailAddress.match(validRegex)) {
      isValid = false;
      validations.emailAddressVal = "Email address is invalid";
    } else {
      validations.emailAddressVal = "";
    }
    if (!comments.trim()) {
      isValid = false;
      validations.commentsVal = "Comments is Compulsory";
    }

    if (!isValid) {
      setValidations(validations);
    }

    return isValid;
  };

  const handleInputDiamondDataRequest = () => {
    let arrShapes =
      filterVals.Shape.length > 0 ? filterVals.Shape.map((x) => x) : null;
    let arrCutGrades =
      state.Cut.length > 0 ? state.Cut.map((x) => Number(x.fnCutGrade)) : null;
    let arrColors =
      state.Color.length > 0 ? state.Color.map((x) => Number(x.fnColor)) : null;
    let arrPolishs =
      state.Polish.length > 0
        ? state.Polish.map((x) => Number(x.fnPolish))
        : null;
    let arrClaritys =
      state.Clarity.length > 0
        ? state.Clarity.map((x) => Number(x.fnClarity))
        : null;
    let arrSymmetrys =
      state.Symmetry.length > 0
        ? state.Symmetry.map((x) => Number(x.fnSymmetry))
        : null;
    let arrFluorescences =
      state.Fluorescence.length > 0
        ? state.Fluorescence.map((x) => Number(x.fnFluorescence))
        : null;

    const isValid = handleValidation();
    if (!isValid) {
      return false;
    }
    try {
      let inputData = {
        dealerID: dealerId.toString(),
        firstName: diamondFormData.firstName,
        lastName: diamondFormData.lastName,
        phoneNumber: diamondFormData.phoneNumber,
        emailAddress: diamondFormData.emailAddress,
        comment: diamondFormData.comments,
        intDealerID: dealerId.toString(),
        subject: "",
        shapeList: arrShapes !== null ? arrShapes.toString() : "",
        priceMin: filterVals.PriceMin.toString(),
        priceMax: filterVals.PriceMax.toString(),
        caratMin: filterVals.CaratMin.toString(),
        caratMax: filterVals.CaratMax.toString(),
        colorMin: arrColors !== null ? Math.min(...arrColors).toString() : "",
        colorMax: arrColors !== null ? Math.max(...arrColors).toString() : "",
        clarityMin:
          arrClaritys !== null ? Math.min(...arrClaritys).toString() : "",
        clarityMax:
          arrClaritys !== null ? Math.max(...arrClaritys).toString() : "",
        cutgradeMin:
          arrCutGrades !== null ? Math.min(...arrCutGrades).toString() : "",
        cutgradeMax:
          arrCutGrades !== null ? Math.max(...arrCutGrades).toString() : "",
        certificate: filterVals.Certificate.toString(), //Certificate
        depthMin: filterVals.DepthMin.toString(),
        depthMax: filterVals.DepthMax.toString(),
        tableMin: filterVals.TableMin.toString(),
        tableMax: filterVals.TableMax.toString(),
        polishList:
          filterVals.Polish.length > 0 ? filterVals.Polish.toString() : "",
        symmetryList:
          filterVals.Symmetry.length > 0 ? filterVals.Symmetry.toString() : "",
        fluorescenceList:
          filterVals.Fluorescence.length > 0
            ? filterVals.Fluorescence.toString()
            : "",
        cutGradeList:
          filterVals.Cut.length > 0 ? filterVals.Cut.toString() : "",
        colorList:
          filterVals.Color.length > 0 ? filterVals.Color.toString() : "",
        clarityList:
          filterVals.Clarity.length > 0 ? filterVals.Clarity.toString() : "",
      };
      setReqDiamondLoading(true);
      SelectYourDiamondService.GetDiamondLinkFiltersRequest(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            NotificationManager.success("Mail has Been Send Successfully.");
            setReqDiamondLoading(false);
            setDiamondFormData({
              firstName: "",
              lastName: "",
              phoneNumber: "",
              emailAddress: "",
              comments: "",
            });
          } else {
            NotificationManager.error("Mail has Been Not Send Successful.");
            setReqDiamondLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setReqDiamondLoading(false);
        });
    } catch (error) {
      console.log(error);
      setReqDiamondLoading(false);
    }
  };

  const GetAllNumbersBetween = (x, y) => {
    var numbers = [];
    for (var i = x; i <= y; i++) {
      numbers.push(i.toString());
    }
    return numbers;
  };

  const handleGetMinedDiamondFilters = async (id, pageNo, pageSize, val) => {
    try {
      let inputData = {
        dealerLink: id.toString(),
      };
      setFilterLoading(true);
      setLoading(true);
      await SelectYourDiamondService.LabFilter(inputData)
        .then((response) => {
          var message = response.data.message;
          var optionsData = response.data.responseData;
          var responseData = response.data.responseData.filterSlider;
          var responseNewData = response.data.responseData.selectedSliderRange;
          var tempAvailableFilterData = state;
          var tempAvailableFilterValData = filterVals;
          if (message == "Success") {
            if (responseData) {
              debugger;
              setAdvanced(optionsData.advancedSearch);
              tempAvailableFilterData.PriceMin = Number(
                responseData.sldPriceMin
              );
              tempAvailableFilterData.PriceMax = Number(
                responseData.sldPriceMax
              );
              tempAvailableFilterData.CaratMin = Number(
                responseData.sldCaratMin
              );
              tempAvailableFilterData.CaratMax = Number(
                responseData.sldCaratMax
              );
              tempAvailableFilterData.Shape = responseData.shapes;
              tempAvailableFilterData.Cut = responseData.sldCutGradeMax;
              tempAvailableFilterData.Color = responseData.sldColorMax;
              tempAvailableFilterData.Clarity = responseData.sldClarityMax;
              tempAvailableFilterData.Polish = responseData.sldPolishMax;
              tempAvailableFilterData.Fluorescence =
                responseData.sldFluorescenceMax;
              tempAvailableFilterData.Symmetry = responseData.sldSymmetryMax;
              tempAvailableFilterData.TableMin = responseData.sldTableMin;
              tempAvailableFilterData.TableMax = responseData.sldTableMax;
              tempAvailableFilterData.DepthMin = responseData.sldDepthMin;
              tempAvailableFilterData.DepthMax = responseData.sldDepthMax;
              tempAvailableFilterData.Certificate =
                responseData.strCertOverrides;

              tempAvailableFilterValData.PriceMin = Number(
                responseData.sldPriceMin
              );
              tempAvailableFilterValData.PriceMax = Number(
                responseData.sldPriceMax
              );
              tempAvailableFilterValData.CaratMin = Number(
                responseData.sldCaratMin
              );
              tempAvailableFilterValData.CaratMax = Number(
                responseData.sldCaratMax
              );
              var filterCaratmin = responseData.sldCaratMin;
              var filterCaratmax = responseData.sldCaratMax;
              var filterPricemin = responseData.sldPriceMin;
              var filterPricemax = responseData.sldPriceMax;
              var filterTablemin = responseData.sldTableMin;
              var filterTablemax = responseData.sldTableMax;
              var filterDepthmin = responseData.sldDepthMin;
              var filterDepthmax = responseData.sldDepthMax;
              var arrShape = [];
              var arrCut = [];
              var arrClarity = [];
              var arrColor = [];

              var arrFluorescence = [];
              var arrSymmetry = [];
              var arrPolish = [];
              var arrCertificate = [];

              if (responseData.strCertOverrides) {
                var arrCertificate = responseData.strCertOverrides
                  .filter((x) => x.certificateCheckBox == "True")
                  .map((x) => x.certificateValue);
              }

              if (responseNewData.length > 0) {
                filterCaratmin =
                  responseNewData[0].caratMax == ""
                    ? responseData.sldCaratMin
                    : responseNewData[0].caratMin;
                filterCaratmax =
                  responseNewData[0].caratMax == ""
                    ? responseData.sldCaratMax
                    : responseNewData[0].caratMax;
                filterPricemin =
                  responseNewData[0].caratMax == ""
                    ? responseData.sldPriceMin
                    : responseNewData[0].priceMin;
                filterPricemax =
                  responseNewData[0].caratMax == ""
                    ? responseData.sldPriceMax
                    : responseNewData[0].priceMax;
                arrShape =
                  responseNewData[0].shapelist == ""
                    ? []
                    : responseNewData[0].shapelist.split(",");
                filterTablemin =
                  responseNewData[0].tableMin == ""
                    ? responseData.sldTableMin
                    : responseNewData[0].tableMin;
                filterTablemax =
                  responseNewData[0].tableMax == ""
                    ? responseData.sldTableMax
                    : responseNewData[0].tableMax;
                filterDepthmin =
                  responseNewData[0].depthMin == ""
                    ? responseData.sldDepthMin
                    : responseNewData[0].depthMin;
                filterDepthmax =
                  responseNewData[0].depthMax == ""
                    ? responseData.sldDepthMax
                    : responseNewData[0].depthMax;
                arrClarity =
                  responseNewData[0].clarityMin == "" &&
                  responseNewData[0].clarityMax == ""
                    ? []
                    : GetAllNumbersBetween(
                        responseNewData[0].clarityMin,
                        responseNewData[0].clarityMax
                      );
                arrColor =
                  responseNewData[0].colorMin == "" &&
                  responseNewData[0].colorMax == ""
                    ? []
                    : GetAllNumbersBetween(
                        responseNewData[0].colorMin,
                        responseNewData[0].colorMax
                      );
                arrFluorescence =
                  responseNewData[0].fluorescenceMin == "" &&
                  responseNewData[0].fluorescenceMax == ""
                    ? []
                    : GetAllNumbersBetween(
                        responseNewData[0].fluorescenceMin,
                        responseNewData[0].fluorescenceMax
                      );
                arrSymmetry =
                  responseNewData[0].symmetryMin == "" &&
                  responseNewData[0].symmetryMax == ""
                    ? []
                    : GetAllNumbersBetween(
                        responseNewData[0].symmetryMin,
                        responseNewData[0].symmetryMax
                      );
                arrPolish =
                  responseNewData[0].polishMin == "" &&
                  responseNewData[0].polishMax == ""
                    ? []
                    : GetAllNumbersBetween(
                        responseNewData[0].polishMin,
                        responseNewData[0].polishMax
                      );
                arrCut =
                  responseNewData[0].cutGradeMin == "" &&
                  responseNewData[0].cutGradeMax == ""
                    ? []
                    : GetAllNumbersBetween(
                        responseNewData[0].cutGradeMin,
                        responseNewData[0].cutGradeMax
                      );
              }
              tempAvailableFilterValData.Shape = arrShape;
              tempAvailableFilterValData.TableMin = filterTablemin;
              tempAvailableFilterValData.TableMax = filterTablemax;
              tempAvailableFilterValData.DepthMin = filterDepthmin;
              tempAvailableFilterValData.DepthMax = filterDepthmax;
              tempAvailableFilterValData.Clarity = arrClarity;
              tempAvailableFilterValData.Cut = arrCut;
              tempAvailableFilterValData.Color = arrColor;
              tempAvailableFilterValData.Polish = arrPolish;
              tempAvailableFilterValData.Symmetry = arrPolish;
              tempAvailableFilterValData.Fluorescence = arrFluorescence;
              tempAvailableFilterValData.Certificate = arrCertificate;
              tempAvailableFilterValData.PriceMin = Number(filterPricemin);
              tempAvailableFilterValData.PriceMax = Number(filterPricemax);
              tempAvailableFilterValData.CaratMin = Number(filterCaratmin);
              tempAvailableFilterValData.CaratMax = Number(filterCaratmax);

              setState({
                ...state,
                ...tempAvailableFilterData,
              });

              var saveSearchObj = resetVal.minedDiamondFilter;

              if (val == true && resetVal.resetMined !== true) {
                setFilterVals(saveSearchObj);
                setPriceValue([saveSearchObj.PriceMin, saveSearchObj.PriceMax]);
                setCaratSlideValue([
                  saveSearchObj.CaratMin,
                  saveSearchObj.CaratMax,
                ]);
              } else {
                var tempObj = tempAvailableFilterValData;
                tempAvailableFilterValData.Shape = [];
                tempAvailableFilterValData.Cut = [];
                tempAvailableFilterValData.Color = [];
                tempAvailableFilterValData.Clarity = [];
                tempAvailableFilterValData.Polish = [];
                tempAvailableFilterValData.Fluorescence = [];
                tempAvailableFilterValData.Symmetry = [];
                tempAvailableFilterValData.Intensity = [];
                tempAvailableFilterValData.Certificates = [];

                setFilterVals({
                  ...filterVals,
                  ...tempAvailableFilterValData,
                });
                setPriceValue([filterPricemin, filterPricemax]);
                setCaratSlideValue([filterCaratmin, filterCaratmax]);
                var tempObj = tempAvailableFilterValData;
                tempObj["disabled"] = {};
                // dispatch(handleSetSaveFilterMinedTab(tempObj));
              }

              setFilterLoading(false);

              if (val == true && resetVal.resetMined !== true) {
                handleGetMinedDiamondList(
                  id,
                  pageNo,
                  pageSize,
                  saveSearchObj.Shape, //shapes
                  saveSearchObj.PriceMin,
                  saveSearchObj.PriceMax,
                  saveSearchObj.CaratMin,
                  saveSearchObj.CaratMax,
                  responseData.sldColorMax,
                  responseData.sldClarityMax,
                  responseData.sldCutGradeMax,
                  responseData.sldPolishMax,
                  responseData.sldSymmetryMax,
                  responseData.sldFluorescenceMax,
                  saveSearchObj.DepthMin,
                  saveSearchObj.DepthMax,
                  saveSearchObj.TableMin,
                  saveSearchObj.TableMax,
                  saveSearchObj.Certificate, // Certificate
                  searchSku, //Sku
                  saveSearchObj.Color, // color
                  saveSearchObj.Clarity, // clarity
                  saveSearchObj.Cut, // cut
                  saveSearchObj.Polish, // polish
                  saveSearchObj.Symmetry, //symmetry
                  saveSearchObj.Fluorescence, //fluorescence,
                  optionsData.searchSortName,
                  optionsData.searchSortOrder
                );
              } else {
                debugger;
                handleGetMinedDiamondList(
                  id,
                  pageNo,
                  pageSize,
                  arrShape, //shapes
                  filterPricemin,
                  filterPricemax,
                  filterCaratmin,
                  filterCaratmax,
                  responseData.sldColorMax,
                  responseData.sldClarityMax,
                  responseData.sldCutGradeMax,
                  responseData.sldPolishMax,
                  responseData.sldSymmetryMax,
                  responseData.sldFluorescenceMax,
                  filterDepthmin,
                  filterDepthmax,
                  filterTablemin,
                  filterTablemax,
                  arrCertificate, // Certificate
                  searchSku, //Sku
                  arrColor, // color
                  arrClarity, // clarity
                  arrCut, // cut
                  arrPolish, // polish
                  arrSymmetry, //symmetry
                  arrFluorescence, //fluorescence
                  optionsData.searchSortName,
                  optionsData.searchSortOrder
                );
              }
            } else {
            }
          } else {
            setMinedList([]);
            setTotalRowCount(0);
            setFilterLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setFilterLoading(false);
        });
    } catch (error) {
      console.log(error);
      setFilterLoading(false);
    }
  };

  const handleGetMinedDiamondList = (
    id,
    PageNo,
    PageSize,
    shapes,
    priceMin,
    priceMax,
    caratmin,
    caratmax,
    allColor,
    allClarity,
    allCut,
    allPolish,
    allSymmetry,
    allFluorescence,
    depthmin,
    depthmax,
    tablemin,
    tablemax,
    Certificate, // Certificate
    Sku, //Sku
    colorVal, // color
    clarityVal, // clarity
    cutVal, // cut
    polishVal, // polish
    symmetryVal, //symmetry
    fluorescenceVal, //fluorescence
    column,
    type
  ) => {
    debugger;
    let arrShapes = shapes.length > 0 ? shapes.map((x) => x) : null;
    let arrCutGrades =
      allCut.length > 0 ? allCut.map((x) => Number(x.fnCutGrade)) : null;
    let arrColors =
      allColor.length > 0 ? allColor.map((x) => Number(x.fnColor)) : null;
    let arrPolishs =
      allPolish.length > 0 ? allPolish.map((x) => Number(x.fnPolish)) : null;
    let arrClaritys =
      allClarity.length > 0 ? allClarity.map((x) => Number(x.fnClarity)) : null;
    let arrSymmetrys =
      allSymmetry.length > 0
        ? allSymmetry.map((x) => Number(x.fnSymmetry))
        : null;
    let arrFluorescences =
      allFluorescence.length > 0
        ? allFluorescence.map((x) => Number(x.fnFluorescence))
        : null;

    try {
      let inputData = {
        dealerID: id.toString(),
        shapeList: arrShapes !== null ? arrShapes.toString() : "",
        priceMin: priceMin.toString(),
        priceMax: priceMax.toString(),
        caratMin: caratmin.toString(),
        caratMax: caratmax.toString(),
        colorMin: arrColors !== null ? Math.min(...arrColors).toString() : "",
        colorMax: arrColors !== null ? Math.max(...arrColors).toString() : "",
        clarityMin:
          arrClaritys !== null ? Math.min(...arrClaritys).toString() : "",
        clarityMax:
          arrClaritys !== null ? Math.max(...arrClaritys).toString() : "",
        cutgradeMin:
          arrCutGrades !== null ? Math.min(...arrCutGrades).toString() : "",
        cutgradeMax:
          arrCutGrades !== null ? Math.max(...arrCutGrades).toString() : "",
        symmetryMin:
          arrSymmetrys !== null ? Math.min(...arrSymmetrys).toString() : 0,
        symmetryMax:
          arrSymmetrys !== null ? Math.max(...arrSymmetrys).toString() : 0,
        polishMin: arrPolishs !== null ? Math.min(...arrPolishs).toString() : 0,
        polishMax: arrPolishs !== null ? Math.max(...arrPolishs).toString() : 0,
        fluorescenceMin:
          arrFluorescences !== null
            ? Math.min(...arrFluorescences).toString()
            : 0,
        fluorescenceMax:
          arrFluorescences !== null
            ? Math.max(...arrFluorescences).toString()
            : 0,
        certificate: Certificate.toString(),
        skuNo: Sku.toString(),
        pairCode: "1",
        pageSize: PageSize.toString(),
        currentPage: PageNo.toString(),

        orderBy:
          column == "txtShape" || column == "CUT"
            ? "Shape"
            : column == "txtCutGrade" || column == "CUT"
            ? "Cut"
            : column == "fltCarat" || column == "SIZE"
            ? "Size"
            : column == "txtColor" || column == "COLOR"
            ? "Color"
            : column == "txtClarity" || column == "CLARITYID"
            ? "Clarity"
            : column == "txtCutGrade" || column == "CUT"
            ? "CutGrade"
            : column == "fltDepth" || column == "DEPTH"
            ? "Depth"
            : column == "fltTable" || column == "TABLEMEASURE"
            ? "TableMeasure"
            : column == "txtPolish"
            ? "Polish"
            : column == "txtSymmetry"
            ? "Symmetry"
            : column == "txtMeasurements" || column == "MEASUREMENTS"
            ? "Measurements"
            : column == "txtCertificate" || column == "CERTIFICATE"
            ? "Certificate"
            : column == "realPrice" || column == "FINALPRICE"
            ? "CostperCarat"
            : // : optionData.searchSortName
              // ? optionData.searchSortName
              "FltPrice",
        orderType:
          type == "ascending"
            ? "ASC"
            : type == "descending"
            ? "DESC"
            : type
            ? type
            : "ASC",

        detailsLink: "DiamondDetail.aspx",
        dID: "",
        depthMin: depthmin.toString(),
        depthMax: depthmax.toString(),
        tableMin: tablemin.toString(),
        tableMax: tablemax.toString(),
        caratPriceMin: "0",
        caratPriceMax: "100000",
        themeName: "whiteTheme",
        showPrice: "1",
        bindWhat: "All",
        polishList: polishVal.length > 0 ? polishVal.toString() : "",
        symmetryList: symmetryVal.length > 0 ? symmetryVal.toString() : "",
        fluorescenceList:
          fluorescenceVal.length > 0 ? fluorescenceVal.toString() : "",
        extraQStr: "&SID=&Shape=&CTW=&Size=",
        cutGradeList: cutVal.length > 0 ? cutVal.toString() : "",
        colorList: colorVal.length > 0 ? colorVal.toString() : "",
        clarityList: clarityVal.length > 0 ? clarityVal.toString() : "",

        bindCheckbox: "1",
        sOrigin: "",
        isdiamond: "0",
        custID: "",
        settingSKU: "",
        countryTaxRate: "",
        vendorID: "",
        track: "0",
        isFB: "0",
        IsMLDL: "0",
        myRetailerID: "0",
        showAdvance: false,
        hasVideo: "True",
      };
      setLoading(true);
      SelectYourDiamondService.GetDiamondLInkMinedDiamonds(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var FirstRow = response.data.responseData[0];
          if (message == "Success") {
            if (responseData.length > 0) {
              setMinedList(responseData);
              setTotalRowCount(responseData[0].intTotalRecords);
              setRowDetails(FirstRow);
              setLoading(false);
              dispatch(handleComaparePageIds(""));
            } else {
              setMinedList([]);
              setTotalRowCount(0);
              setLoading(false);
            }
          } else {
            setMinedList([]);
            setTotalRowCount(0);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  function onChange(page, pageSize) {
    if (page !== pageNo) {
      setPageNo(page);
      handleGetMinedDiamondList(
        dealerId,
        page,
        pageSize,
        filterVals.Shape,
        priceValue[0],
        priceValue[1],
        caratSlideValue[0],
        caratSlideValue[1],
        state.Color,
        state.Clarity,
        state.Cut,
        state.Polish,
        state.Symmetry,
        state.Fluorescence,
        filterVals.DepthMin,
        filterVals.DepthMax,
        filterVals.TableMin,
        filterVals.TableMax,
        filterVals.Certificate, // Certificate
        searchSku, //Sku
        filterVals.Color, // color
        filterVals.Clarity, // clarity
        filterVals.Cut, // cut
        filterVals.Polish, // polish
        filterVals.Symmetry, //symmetry
        filterVals.Fluorescence, //fluorescence,
        sortColumnName,
        sortType
      );
    }
  }
  function onShowSizeChange(current, pageNewSize) {
    if (pageNewSize !== pageSize) {
      setPageSize(pageNewSize);
      handleGetMinedDiamondList(
        dealerId,
        current,
        pageNewSize,
        filterVals.Shape,
        priceValue[0],
        priceValue[1],
        caratSlideValue[0],
        caratSlideValue[1],
        state.Color,
        state.Clarity,
        state.Cut,
        state.Polish,
        state.Symmetry,
        state.Fluorescence,
        filterVals.DepthMin,
        filterVals.DepthMax,
        filterVals.TableMin,
        filterVals.TableMax,
        filterVals.Certificate, // Certificate
        searchSku, //Sku
        filterVals.Color, // color
        filterVals.Clarity, // clarity
        filterVals.Cut, // cut
        filterVals.Polish, // polish
        filterVals.Symmetry, //symmetry
        filterVals.Fluorescence, //fluorescence
        sortColumnName,
        sortType
      );
    }
  }

  const handleSetRowDetils = (e, record) => {
    console.log(e, record);
    setRowDetails(record);
  };

  const handleOpenVideoImageModal = (row, isVideo) => {
    setIsVideo(isVideo);
    setSelectedRowDetails(row);
    setVideoImageModal(true);
  };
  const handleCloseVideoImageModal = () => {
    setVideoImageModal(false);
  };

  const handleSearchDiamond = (value) => {
    setSearchSku(value);
    handleGetMinedDiamondList(
      dealerId,
      pageNo,
      pageSize,
      filterVals.Shape,
      priceValue[0],
      priceValue[1],
      caratSlideValue[0],
      caratSlideValue[1],
      state.Color,
      state.Clarity,
      state.Cut,
      state.Polish,
      state.Symmetry,
      state.Fluorescence,
      filterVals.DepthMin,
      filterVals.DepthMax,
      filterVals.TableMin,
      filterVals.TableMax,
      filterVals.Certificate, // Certificate
      value, //Sku
      filterVals.Color, // color
      filterVals.Clarity, // clarity
      filterVals.Cut, // cut
      filterVals.Polish, // polish
      filterVals.Symmetry, //symmetry
      filterVals.Fluorescence //fluorescence
    );
  };

  const handleFilterChangeCaratVal = (e) => {
    setCaratSlideValue(e);
  };

  const handleFilterChangePriceVal = (e) => {
    setPriceValue(e);
  };

  const onChangeMultiDrop = (e, name) => {
    let tempState = filterVals;
    if (e.includes("%")) {
      tempState.disabled[name] = true;
      tempState[name] = [];
      tempState[name].push("%");
    } else {
      tempState.disabled[name] = false;
      tempState[name] = e;
    }
    setFilterVals((prevState) => ({
      ...prevState,
      ...tempState,
    }));
    handleGetMinedDiamondList(
      dealerId,
      pageNo,
      pageSize,
      filterVals.Shape,
      priceValue[0],
      priceValue[1],
      caratSlideValue[0],
      caratSlideValue[1],
      state.Color,
      state.Clarity,
      state.Cut,
      state.Polish,
      state.Symmetry,
      state.Fluorescence,
      filterVals.DepthMin,
      filterVals.DepthMax,
      filterVals.TableMin,
      filterVals.TableMax,
      filterVals.Certificate, // Certificate
      searchSku, //Sku
      filterVals.Color, // color
      filterVals.Clarity, // clarity
      filterVals.Cut, // cut
      filterVals.Polish, // polish
      filterVals.Symmetry, //symmetry
      filterVals.Fluorescence //fluorescence
    );
    dispatch(handleSetSaveEveryTimeMinedTab(filterVals));
  };

  const handleCertModalOpen = (row) => {
    console.log(row);
    setCertRow(row);
    setviewCertModal(true);
  };
  const closeCertModal = () => {
    setCertRow({});
    setviewCertModal(false);
  };

  const hideSpinner = () => {
    setIframeLoading(false);
  };

  const handleCompareRedirect = () => {
    if (minedList.filter((obj) => obj.checkbox == true).length == 0) {
      NotificationManager.error("Kindly Select a diamond to Compare");
    } else {
      var compareIds = CompareIds.toString();
      navigate(
        "/Compare" + `?DealerLink=${paramdealerId}&CompareIds=${compareIds}`
      );
      dispatch(handleSetPage("Compare"));
    }
  };

  // Sorting

  const handleTableASCDES = (pagination, filters, sorter) => {
    var type = "";
    var column = "";
    var filter = [];
    var obj = {};

    //sorting
    if (sorter.order == "descend") {
      type = "DESC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else if (sorter.order == "ascend") {
      type = "ASC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else {
      type = "ASC";
      column = "dealerCompany";
      setSortColumnName(column);
      setSortType(type);
    }
    handleGetMinedDiamondList(
      dealerId,
      pageNo,
      pageSize,
      filterVals.Shape,
      priceValue[0],
      priceValue[1],
      caratSlideValue[0],
      caratSlideValue[1],
      state.Color,
      state.Clarity,
      state.Cut,
      state.Polish,
      state.Symmetry,
      state.Fluorescence,
      filterVals.DepthMin,
      filterVals.DepthMax,
      filterVals.TableMin,
      filterVals.TableMax,
      filterVals.Certificate, // Certificate
      searchSku, //Sku
      filterVals.Color, // color
      filterVals.Clarity, // clarity
      filterVals.Cut, // cut
      filterVals.Polish, // polish
      filterVals.Symmetry, //symmetry
      filterVals.Fluorescence, //fluorescence
      column,
      type
    );
  };

  const handleAfterChange = (e) => {
    handleGetMinedDiamondList(
      dealerId,
      pageNo,
      pageSize,
      filterVals.Shape,
      priceValue[0],
      priceValue[1],
      e[0],
      e[1],
      state.Color,
      state.Clarity,
      state.Cut,
      state.Polish,
      state.Symmetry,
      state.Fluorescence,
      filterVals.DepthMin,
      filterVals.DepthMax,
      filterVals.TableMin,
      filterVals.TableMax,
      filterVals.Certificate, // Certificate
      searchSku, //Sku
      filterVals.Color, // color
      filterVals.Clarity, // clarity
      filterVals.Cut, // cut
      filterVals.Polish, // polish
      filterVals.Symmetry, //symmetry
      filterVals.Fluorescence //fluorescence
    );
    var tempArray = filterVals;
    tempArray["CaratMin"] = e[0];
    tempArray["CaratMax"] = e[1];
    setFilterVals(tempArray);
    dispatch(handleSetSaveEveryTimeMinedTab(tempArray));
  };

  const handleAfterChangePrice = (e) => {
    handleGetMinedDiamondList(
      dealerId,
      pageNo,
      pageSize,
      filterVals.Shape,
      e[0],
      e[1],
      caratSlideValue[0],
      caratSlideValue[1],
      state.Color,
      state.Clarity,
      state.Cut,
      state.Polish,
      state.Symmetry,
      state.Fluorescence,
      filterVals.DepthMin,
      filterVals.DepthMax,
      filterVals.TableMin,
      filterVals.TableMax,
      filterVals.Certificate, // Certificate
      searchSku, //Sku
      filterVals.Color, // color
      filterVals.Clarity, // clarity
      filterVals.Cut, // cut
      filterVals.Polish, // polish
      filterVals.Symmetry, //symmetry
      filterVals.Fluorescence //fluorescence
    );
    var tempArray = filterVals;
    tempArray["PriceMin"] = e[0];
    tempArray["PriceMax"] = e[1];
    setFilterVals(tempArray);
    dispatch(handleSetSaveEveryTimeMinedTab(tempArray));
  };

  // KeyPress

  const onKeyPressEvent = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9.]").test(keyValue)) event.preventDefault();
    return;
  };

  /* Color Individually Start */

  const [isLightHeaderColor, setIsLightHeaderColor] = useState(false);
  const [isLightButtonColor, setIsLightButtonColor] = useState(false);
  const [isLightHoverColor, setIsLightHoverColor] = useState(false);
  const [wrapperLoading, setWrapperLoading] = useState(false);

  const previewcolor = useSelector((state) => state.previewColorReducer);
  const [varColorObj, setVarColorObj] = useState({
    callToActionColor: "",
    hoverColor: "",
    columnHeaderAccentColor: "",
    linkColor: "",
  });
  const [toggle, setToggle] = useState(true);
  useEffect(() => {
    if (paramdealerId) {
      handleGetColorsData(loginDetails.dealerId);
    }
  }, [previewcolor.toogle]);

  const handleGetColorsData = async (id) => {
    let inputData = {
      dealerID: Number(id),
      moduleName: "DiamondLink",
      hoverEffect: "",
      columnHeaderAccent: "",
      linkColor: "",
      actionButton: "",
    };
    setWrapperLoading(true);
    try {
      await colorPanelService
        .GetHoverEffect(inputData)
        .then((response) => {
          let msg = response.data.message;
          let initialColorObj =
            response.data.responseData.initialHoverEffectList[0];
          let newColorObj =
            response.data.responseData.ringBuilder_HoverEffect_List[0];
          let colorObj = {
            hoverEffect: "",
            columnHeaderAccent: "",
            linkColor: "",
            actionButton: "",
          };

          if (msg == "Success") {
            if (initialColorObj) {
              var tempobj = colorObj;
              tempobj.hoverEffect = newColorObj
                ? newColorObj.hoverEffect == ""
                  ? initialColorObj.hoverEffect
                  : newColorObj.hoverEffect
                : initialColorObj.hoverEffect;
              tempobj.columnHeaderAccent = newColorObj
                ? newColorObj.columnHeaderAccent == ""
                  ? initialColorObj.columnHeaderAccent
                  : newColorObj.columnHeaderAccent
                : initialColorObj.columnHeaderAccent;
              tempobj.linkColor = newColorObj
                ? newColorObj.linkColor == ""
                  ? initialColorObj.linkColor
                  : newColorObj.linkColor
                : initialColorObj.linkColor;
              tempobj.actionButton = newColorObj
                ? newColorObj.actionButton == ""
                  ? initialColorObj.actionButton
                  : newColorObj.actionButton
                : initialColorObj.actionButton;
              debugger;
              console.log(tempobj);
              console.log(initialColorObj);
              if (tempobj) {
                setVarColorObj({
                  ...varColorObj,
                  callToActionColor: tempobj.actionButton,
                  hoverColor: tempobj.hoverEffect,
                  columnHeaderAccentColor: tempobj.columnHeaderAccent,
                  linkColor: tempobj.linkColor,
                });
                lightOrDark(tempobj.columnHeaderAccent, "header");
                lightOrDark(tempobj.callToActionColor, "button");
                lightOrDark(tempobj.hoverColor, "hover");
              } else {
                setVarColorObj({
                  ...varColorObj,
                  callToActionColor: initialColorObj.actionButton,
                  hoverColor: initialColorObj.hoverEffect,
                  columnHeaderAccentColor: initialColorObj.columnHeaderAccent,
                  linkColor: initialColorObj.linkColor,
                });
                lightOrDark(initialColorObj.columnHeaderAccent, "header");
                lightOrDark(initialColorObj.callToActionColor, "button");
                lightOrDark(initialColorObj.hoverColor, "hover");
              }
            }
          }
          setWrapperLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setWrapperLoading(false);
        });
    } catch (error) {
      console.log(error);
      setWrapperLoading(false);
    }
  };

  function lightOrDark(color, type) {
    // Variables for red, green, blue values
    var r, g, b, hsp;

    if (color) {
      // Check the format of the color, HEX or RGB?
      if (color.match(/^rgb/)) {
        // If RGB --> store the red, green, blue values in separate variables
        color = color.match(
          /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
        );

        r = color[1];
        g = color[2];
        b = color[3];
      } else {
        // If hex --> Convert it to RGB: http://gist.github.com/983661
        color = +(
          "0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&")
        );

        r = color >> 16;
        g = (color >> 8) & 255;
        b = color & 255;
      }

      // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
      hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

      // Using the HSP value, determine whether the color is light or dark
      // if hsp> 127.5 then it is dark
      if (hsp > 127.5) {
        if (type == "header") {
          setIsLightHeaderColor(true);
        } else if (type == "button") {
          setIsLightButtonColor(true);
        } else {
          setIsLightHoverColor(true);
        }
      } else {
        if (type == "header") {
          setIsLightHeaderColor(false);
        } else if (type == "button") {
          setIsLightButtonColor(false);
        } else {
          setIsLightHoverColor(false);
        }
      }
    }
  }

  const Wrapper = styled.div`
    & .whole__shapes .shapes__block .image:hover {
      border: 1px solid ${varColorObj.hoverColor};
    }

    & .whole__shapes .shapes__block:hover .image {
      border: 1px solid ${varColorObj.hoverColor};
    }

    & .whole__shapes .shapes__block .image .selected {
      border: 1px solid ${varColorObj.linkColor};
    }

    & .whole__shapes .shapes__block.selected .image {
      border: 1px solid ${varColorObj.linkColor};
    }

    & .img__block .imgselct__div:hover {
      border-bottom: 2px solid ${varColorObj.hoverColor};
    }

    & .img__block .imgselct__div.selected {
      border-bottom: 2px solid ${varColorObj.linkColor};
    }

    & .selection__block .selection__box:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background-color: ${varColorObj.hoverColor};
    }
    & .selection__block .selection__box.selected {
      border: 1px solid ${varColorObj.linkColor};
      background-color: ${varColorObj.linkColor};
    }

    & .ant-table-thead > tr > th {
      background: ${varColorObj.columnHeaderAccentColor} !important;
      font-family: "LexendDeca-Semibold";
      height: 50px;
      color: ${isLightHeaderColor ? "#3b4452" : "#ffffff"} !important;
      line-height: 14px;
    }

    & .ant-btn-primary {
      background: ${varColorObj.callToActionColor} !important;
      border-color: ${varColorObj.callToActionColor} !important;
    }
    & .ant-btn-primary:hover {
      background: ${varColorObj.hoverColor} !important;
      border-color: ${varColorObj.hoverColor} !important;
    }

    & .primary-btn {
      background: ${varColorObj.callToActionColor} !important;
      border-radius: 5px !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .primary-btn:hover {
      background: ${varColorObj.hoverColor} !important;
      border-radius: 5px !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .ant-btn {
      background: ${varColorObj.callToActionColor} !important;
      border-radius: 5px !important;
      color: ${isLightButtonColor ? "#3b4452" : "#fff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .ant-btn:hover {
      background: ${varColorObj.hoverColor} !important;
      border-radius: 5px !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }
    & .ant-input-search-button {
      background: #fff !important;
      border: 1px solid #d9d9d9;
      border-radius: 0 !important;
      height: 32px;
      border-left: 0;
    }

    & .ant-input-search-button:hover {
      background: #fff !important;
      border: 1px solid #d9d9d9;
      border-radius: 0 !important;
      height: 32px;
      border-left: 0;
    }
    & .linkText {
      color: ${varColorObj.linkColor};
      text-decoration: none;
      cursor: pointer;
    }

    & .linkText:hover {
      color: ${varColorObj.hoverColor};
    }

    & .iframe__block .itemdetails__btns p:hover img {
      filter: invert(64%) sepia(29%) saturate(4028%) hue-rotate(164deg)
        brightness(93%) contrast(89%);
      color: ${varColorObj.hoverColor};
    }

    & .iframe__block .itemdetails__btns p:hover span {
      color: ${varColorObj.hoverColor};
    }

    & .ant-pagination-item-active {
      border: 1px solid ${varColorObj.linkColor} !important;
    }

    & .ant-pagination-item:hover {
      border: 1px solid ${varColorObj.hoverColor};
    }
    & .nav-tabs .nav-link.active {
      background: ${varColorObj.columnHeaderAccentColor} !important;
      color: ${isLightHeaderColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .list_icon.active {
      border: 1px solid ${varColorObj.callToActionColor};
      background: ${varColorObj.callToActionColor} !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .grid_icon.active {
      border: 1px solid ${varColorObj.callToActionColor};
      background: ${varColorObj.callToActionColor} !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .grid_icon:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background: ${varColorObj.hoverColor} !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .list_icon:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background: ${varColorObj.hoverColor} !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .ant-input-search-button {
      color: rgba(0, 0, 0, 0.45) !important;
    }
    & .ant-input-search-button:hover {
      color: rgba(0, 0, 0, 0.45) !important;
    }
  `;

  /* Color Individually Ends */

  const openLink = (url) => {
    if (url.includes("https")) {
      window.open(url, "_blank");
    } else if (url.includes("http")) {
      window.open(url, "_blank");
    } else {
      window.open("https://" + url, "_blank");
    }
  };

  return (
    <React.Fragment>
      <div className="allprod_section jwlsrch__section app__preview choose__setting">
        <div className="tabs__content">
          <div className="diamond__details">
            <div className="row border__block">
              <div className="col-md-9">
                <div className="row">
                  <Spin spinning={filterloading}>
                    <div className="form__fields filter_inpsection border-0 p-0 mt-3 mh-auto">
                      <div className="col-md-12">
                        <div className="row">
                          <Wrapper>
                            <div className="col-lg-3 col-md-6">
                              <div className="input__block mb-1">
                                <label>
                                  {" "}
                                  Shape
                                  {/* <Tooltip
                                    placement="right"
                                    title="A diamond’s shape is not the same as a diamond’s cut. The shape refers to the general outline of the stone, and not its light refractive qualities. Look for a shape that best suits the ring setting you have chosen, as well as the recipient’s preference and personality."
                                  >
                                    <img src={UnionIcon} alt="" />
                                  </Tooltip> */}
                                </label>
                              </div>
                            </div>

                            <div className="whole__shapes col-lg-12">
                              {state.Shape &&
                                state.Shape.map((item, i) => {
                                  return (
                                    <Fragment>
                                      <div
                                        className={
                                          filterVals.Shape &&
                                          filterVals.Shape.includes(
                                            item.sldShapeListShape
                                          )
                                            ? "shapes__block selected"
                                            : "shapes__block"
                                        }
                                        onClick={() =>
                                          handleLabGrownFilterChange(
                                            item.sldShapeListShape,
                                            "Shape",
                                            "filter"
                                          )
                                        }
                                      >
                                        <Tooltip
                                          placement="bottom"
                                          title={item.sldShapeListShape}
                                        >
                                          <div className="image">
                                            <img
                                              src={item.sliderShapeListShape}
                                            />
                                          </div>
                                        </Tooltip>
                                        <p>{item.sldShapeListShape}</p>
                                      </div>
                                    </Fragment>
                                  );
                                })}
                            </div>
                            <div className="col-md-12">
                              <div className="input__block mb-1">
                                <label>
                                  {" "}
                                  Cut
                                  {/* <Tooltip
                                    placement="right"
                                    title="Not to be confused with shape, a diamond’s cut rating tells you how well its proportions interact with light. By evaluating the angles and proportions of the diamond, the cut grade is designed to tell you how sparkly and brilliant your stone is. Cut grading is usually not available for fancy shapes (any shape that is not round), because the mathematical formula that determines light return becomes less reliable when different length to width ratios are factored in."
                                  >
                                    <img src={UnionIcon} alt="" />
                                  </Tooltip> */}
                                </label>
                              </div>
                              <div className="selection__block row">
                                {state.Cut &&
                                  state.Cut.map((item, i) => {
                                    return (
                                      <Fragment>
                                        <div className="col">
                                          <div
                                            className={
                                              filterVals.Cut &&
                                              filterVals.Cut.includes(
                                                item.fnCutGrade
                                              )
                                                ? "selection__box selected"
                                                : "selection__box"
                                            }
                                            onClick={() =>
                                              handleLabGrownFilterChange(
                                                item.fnCutGrade,
                                                "Cut",
                                                "filter"
                                              )
                                            }
                                          ></div>
                                          <p>{item.values}</p>
                                        </div>
                                      </Fragment>
                                    );
                                  })}
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="input__block mb-1">
                                <label>
                                  {" "}
                                  Color
                                  {/* <Tooltip
                                    placement="right"
                                    title="The color scale measures the degree of colorlessness in a diamond. D is the highest and most colorless grade, but also the most expensive. To get the most value for your budget, look for an eye colorless stone. For most diamonds, this is in the F-H range."
                                  >
                                    <img src={UnionIcon} alt="" />
                                  </Tooltip> */}
                                </label>
                              </div>
                              <div className="selection__block row">
                                {state.Color &&
                                  state.Color.map((item, i) => {
                                    return (
                                      <Fragment>
                                        <div className="col">
                                          <div
                                            className={
                                              filterVals.Color &&
                                              filterVals.Color.includes(
                                                item.fnColor
                                              )
                                                ? "selection__box selected"
                                                : "selection__box"
                                            }
                                            onClick={() =>
                                              handleLabGrownFilterChange(
                                                item.fnColor,
                                                "Color",
                                                "filter"
                                              )
                                            }
                                          ></div>
                                          <p>{item.values}</p>
                                        </div>
                                      </Fragment>
                                    );
                                  })}
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="input__block mb-1">
                                <label>
                                  {" "}
                                  Clarity
                                  {/* <Tooltip
                                    placement="right"
                                    title="The color scale measures the degree of colorlessness in a diamond. D is the highest and most colorless grade, but also the most expensive. To get the most value for your budget, look for an eye colorless stone. For most diamonds, this is in the F-H range."
                                  >
                                    <img src={UnionIcon} alt="" />
                                  </Tooltip> */}
                                </label>
                              </div>
                              <div className="selection__block row">
                                {state.Clarity &&
                                  state.Clarity.map((item, i) => {
                                    return (
                                      <Fragment>
                                        <div className="col">
                                          <div
                                            className={
                                              filterVals.Clarity &&
                                              filterVals.Clarity.includes(
                                                item.fnClarity
                                              )
                                                ? "selection__box selected"
                                                : "selection__box"
                                            }
                                            onClick={() =>
                                              handleLabGrownFilterChange(
                                                item.fnClarity,
                                                "Clarity",
                                                "filter"
                                              )
                                            }
                                          ></div>
                                          <p>{item.values}</p>
                                        </div>
                                      </Fragment>
                                    );
                                  })}
                              </div>
                            </div>
                          </Wrapper>
                          <div className="col-md-6">
                            <div className="sliderrange__maindiv">
                              <label> {"Carats"} </label>
                              {/* <SliderWrapper> */}
                              <div className="col-md-12 mt-3">
                                <Slider
                                  range
                                  value={caratSlideValue}
                                  min={state.CaratMin}
                                  max={state.CaratMax}
                                  step={0.01}
                                  onChange={(e) => {
                                    handleFilterChangeCaratVal(e);
                                  }}
                                  onAfterChange={(e) => {
                                    handleAfterChange(e);
                                  }}
                                />
                                <div className="rangeSlider__values">
                                  <div className="input__block">
                                    <input
                                      type="text"
                                      value={caratSlideValue[0].toLocaleString(
                                        "en-US"
                                      )}
                                      onChange={(e) => {
                                        firstsetCaratSlideValue(e, 0);
                                      }}
                                      onKeyPress={onKeyPressEvent1}
                                    />
                                  </div>

                                  <div className="input__block">
                                    <input
                                      type="text"
                                      value={caratSlideValue[1].toLocaleString(
                                        "en-US"
                                      )}
                                      onChange={(e) => {
                                        firstsetCaratSlideValue(e, 1);
                                      }}
                                      onKeyPress={onKeyPressEvent1}
                                    />
                                  </div>
                                </div>
                              </div>
                              {/* </SliderWrapper> */}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="sliderrange__maindiv">
                              <label> {"Price Range"} </label>
                              {/* <SliderWrapper> */}
                              <div className="col-md-12 mt-3">
                                <Slider
                                  range
                                  value={priceValue}
                                  min={state.PriceMin}
                                  max={state.PriceMax}
                                  onChange={(e) => {
                                    handleFilterChangePriceVal(e);
                                  }}
                                  onAfterChange={(e) => {
                                    handleAfterChangePrice(e);
                                  }}
                                />
                                <div className="rangeSlider__values">
                                  <div className="input__block">
                                    <input
                                      type="text"
                                      // value={
                                      //   "$" +
                                      //   priceValue[0].toLocaleString("en-US")
                                      // }
                                      value={priceValue[0]
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                      onChange={(e) => {
                                        firstSliderPriceValue(e, 0);
                                      }}
                                    />
                                  </div>

                                  <div className="input__block">
                                    <input
                                      type="text"
                                      // value={
                                      //   "$" +
                                      //   priceValue[1].toLocaleString("en-US")
                                      // }
                                      value={
                                        "$" +
                                        priceValue[1]
                                          .toString()
                                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                      }
                                      onChange={(e) => {
                                        firstSliderPriceValue(e, 1);
                                        // handleSliderCaratValue(e);
                                        // setCaratSlideValue(e);
                                      }}
                                      onKeyPress={onKeyPressEvent1}
                                    />
                                    {/* <span className="dollar__symbol">$</span> */}
                                  </div>
                                </div>
                              </div>
                              {/* </SliderWrapper> */}
                            </div>
                          </div>
                          <Wrapper>
                            <div className="col-md-12 mt-4 mb-4">
                              {advanced == true ? (
                                <div className="input__block d-flex align-item-center">
                                  <button
                                    className="primary-btn mr-1"
                                    style={{
                                      width: "25px",
                                      height: "25px",
                                      padding: "0px",
                                    }}
                                    onClick={() =>
                                      setShowAdvanced(!showAdvanced)
                                    }
                                  >
                                    {showAdvanced == true ? "-" : "+"}
                                  </button>
                                  <label>Advanced</label>
                                </div>
                              ) : null}
                            </div>
                            {showAdvanced == true ? (
                              <div>
                                <div className="col-md-12">
                                  <div className="input__block mb-1">
                                    <label>
                                      {" "}
                                      Polish
                                      {/* <Tooltip placement="right" title="">
                                        <img src={UnionIcon} alt="" />
                                      </Tooltip> */}
                                    </label>
                                  </div>
                                  <div className="selection__block row">
                                    {state.Polish &&
                                      state.Polish.map((item, i) => {
                                        return (
                                          <Fragment>
                                            <div className="col">
                                              <div
                                                className={
                                                  filterVals.Polish &&
                                                  filterVals.Polish.includes(
                                                    item.fnPolish
                                                  )
                                                    ? "selection__box selected"
                                                    : "selection__box"
                                                }
                                                onClick={() =>
                                                  handleLabGrownFilterChange(
                                                    item.fnPolish,
                                                    "Polish",
                                                    "filter"
                                                  )
                                                }
                                              ></div>
                                              <p>{item.values}</p>
                                            </div>
                                          </Fragment>
                                        );
                                      })}
                                  </div>
                                </div>

                                <div className="col-md-12">
                                  <div className="input__block mb-1">
                                    <label>
                                      {" "}
                                      Fluorescence
                                      {/* <Tooltip placement="right" title="">
                                        <img src={UnionIcon} alt="" />
                                      </Tooltip> */}
                                    </label>
                                  </div>
                                  <div className="selection__block row">
                                    {state.Fluorescence &&
                                      state.Fluorescence.map((item, i) => {
                                        return (
                                          <Fragment>
                                            <div className="col">
                                              <div
                                                className={
                                                  filterVals.Fluorescence &&
                                                  filterVals.Fluorescence.includes(
                                                    item.fnFluorescence
                                                  )
                                                    ? "selection__box selected"
                                                    : "selection__box"
                                                }
                                                onClick={() =>
                                                  handleLabGrownFilterChange(
                                                    item.fnFluorescence,
                                                    "Fluorescence",
                                                    "filter"
                                                  )
                                                }
                                              ></div>
                                              <p>{item.values}</p>
                                            </div>
                                          </Fragment>
                                        );
                                      })}
                                  </div>
                                </div>

                                <div className="col-md-12">
                                  <div className="input__block mb-1">
                                    <label>
                                      {" "}
                                      Symmetry
                                      {/* <Tooltip placement="right" title="">
                                        <img src={UnionIcon} alt="" />
                                      </Tooltip> */}
                                    </label>
                                  </div>
                                  <div className="selection__block row">
                                    {state.Symmetry &&
                                      state.Symmetry.map((item, i) => {
                                        return (
                                          <Fragment>
                                            <div className="col">
                                              <div
                                                className={
                                                  filterVals.Symmetry &&
                                                  filterVals.Symmetry.includes(
                                                    item.fnSymmetry
                                                  )
                                                    ? "selection__box selected"
                                                    : "selection__box"
                                                }
                                                onClick={() =>
                                                  handleLabGrownFilterChange(
                                                    item.fnSymmetry,
                                                    "Symmetry",
                                                    "filter"
                                                  )
                                                }
                                              ></div>
                                              <p>{item.values}</p>
                                            </div>
                                          </Fragment>
                                        );
                                      })}
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="input__block">
                                      <label>Depth</label>
                                      <div className="row">
                                        <div className="col-md-6 mobile__bottomspace">
                                          <input
                                            type="number"
                                            value={filterVals.DepthMin}
                                            onChange={(e) =>
                                              handleLabGrownFilterChange(
                                                e.target.value,
                                                "DepthMin",
                                                "input"
                                              )
                                            }
                                            onKeyPress={onKeyPressEvent}
                                          />
                                        </div>
                                        <div className="col-md-6">
                                          <input
                                            type="number"
                                            value={filterVals.DepthMax}
                                            onChange={(e) =>
                                              handleLabGrownFilterChange(
                                                e.target.value,
                                                "DepthMax",
                                                "input"
                                              )
                                            }
                                            onKeyPress={onKeyPressEvent}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="input__block">
                                      <label>Table</label>
                                      <div className="row">
                                        <div className="col-md-6 mobile__bottomspace">
                                          <input
                                            type="number"
                                            value={filterVals.TableMin}
                                            onChange={(e) =>
                                              handleLabGrownFilterChange(
                                                e.target.value,
                                                "TableMin",
                                                "input"
                                              )
                                            }
                                            onKeyPress={onKeyPressEvent}
                                          />
                                        </div>
                                        <div className="col-md-6">
                                          <input
                                            type="number"
                                            value={filterVals.TableMax}
                                            onChange={(e) =>
                                              handleLabGrownFilterChange(
                                                e.target.value,
                                                "TableMax",
                                                "input"
                                              )
                                            }
                                            onKeyPress={onKeyPressEvent}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {optionData.showCertificateDiamondSearch ==
                                    true && (
                                    <div className="col-md-4">
                                      <div className="input__block">
                                        <label>Certificates</label>
                                        <Select
                                          className="border__grey"
                                          placeholder="Certificates"
                                          optionFilterProp="children"
                                          onChange={(e) =>
                                            onChangeMultiDrop(e, "Certificate")
                                          }
                                          showSearch
                                          mode="multiple"
                                          showArrow
                                          value={filterVals.Certificate}
                                        >
                                          {state.Certificate.map((item) => {
                                            return (
                                              <Option
                                                value={item.certificateValue}
                                                disabled={
                                                  item.certificateValue == "%"
                                                    ? false
                                                    : filterVals.disabled
                                                        .Certificate
                                                }
                                              >
                                                {item.certificate}
                                              </Option>
                                            );
                                          })}
                                        </Select>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            ) : null}
                          </Wrapper>
                        </div>
                      </div>
                    </div>
                  </Spin>
                </div>
              </div>
              <div className="col-md-3 diamondimage__block">
                <Spin spinning={loading}>
                  <div className="">
                    <h3 className="subheading">Diamond Review</h3>
                    {minedList.length > 0 ? (
                      <Fragment>
                        <img
                          src={
                            rowDetails.bigImageFileName
                              ? rowDetails.bigImageFileName
                              : ""
                          }
                        />
                        <h4>
                          {rowDetails.realPrice == "0" ? (
                            "Call For Price"
                          ) : (
                            <>
                              US$
                              {parseInt(rowDetails.realPrice)
                                .toFixed()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </>
                          )}
                        </h4>
                        <p>
                          Stock Number:{" "}
                          {rowDetails.intDiamondId
                            ? rowDetails.intDiamondId
                            : null}
                        </p>

                        <div className="mb-3">
                          <button
                            className="primary-btn"
                            onClick={() => handleViewDetails(rowDetails)}
                          >
                            {" "}
                            View Details
                          </button>
                        </div>
                        {optionData.showAddToCartButtonOnSearchPage ==
                        false ? null : (
                          <div className="mb-3">
                            <button
                              className="primary-btn"
                              onClick={() => openLink(rowDetails.addToCartLink)}
                            >
                              {" "}
                              Add To Cart
                            </button>
                          </div>
                        )}
                        {optionData.showViewCartButton == false ? null : (
                          <div className="mb-3">
                            <button
                              className="primary-btn"
                              onClick={() => openLink(rowDetails.addToCartLink)}
                            >
                              {" "}
                              View Cart
                            </button>
                          </div>
                        )}
                      </Fragment>
                    ) : (
                      <>
                        <img src={NoDiamond} />
                        <h3 className="subheading">No Results Found</h3>
                        <h3 className="subheading">Use The Form Below</h3>
                      </>
                    )}
                  </div>
                </Spin>
              </div>
            </div>
          </div>
          <Wrapper>
            {minedList.length > 0 ? (
              <div className="col-md-12">
                <div className="filter_section pl-0 pr-0">
                  <div className="col-md-12 mt-3">
                    <div className="filt_optndiv">
                      <div className="gview__div">
                        <span className="font__bold mr-2 similardiamond__fontsize">
                          {TotalRowCount} Similar Diamonds
                        </span>
                        <div>
                          <span
                            className={
                              viewItem === "grid"
                                ? "fa fa-th grid_icon active"
                                : "fa fa-th grid_icon"
                            }
                            onClick={() => handleViewChange("grid")}
                          ></span>
                          <span
                            className={
                              viewItem === "list"
                                ? "fa fa-th-list list_icon active"
                                : "fa fa-th-list list_icon"
                            }
                            onClick={() => handleViewChange("list")}
                          ></span>
                        </div>

                        <span className="font__bold ml-2">
                          Compare Items (
                          {
                            minedList.filter((obj) => obj.checkbox == true)
                              .length
                          }
                          )
                        </span>
                      </div>

                      <div className="my-3 mobile-w-100">
                        <Search
                          placeholder="Search Diamond Stock #"
                          onSearch={(e) => handleSearchDiamond(e)}
                          allowClear
                          style={{ width: 250 }}
                          className="search__minedtab"
                        />
                      </div>
                    </div>
                  </div>

                  {viewItem === "grid" && (
                    <Spin spinning={loading}>
                      <div className="col-md-12">
                        <div className="prod__section">
                          <div className="row">
                            {minedList.length > 0 ? (
                              minedList.map((item, i) => {
                                return (
                                  <div className="col-lg-3 col-md-6 mb-3">
                                    {/* <Link
                                  to={"/productDetails"}
                                  state={{ productData: item }}
                                > */}
                                    <div className="prod__maindiv">
                                      <div className="display-block chkbx__div">
                                        <Checkbox
                                          name="checkbox"
                                          checkedChildren="Yes"
                                          unCheckedChildren="No"
                                          checked={
                                            item.checkbox == true ? true : false
                                          }
                                          onChange={(e) =>
                                            handleOnChange(item, e, "checkbox")
                                          }
                                        />
                                      </div>
                                      <div className="prodimg__div">
                                        {/* <img src={Rectangle} alt="" /> */}
                                        <img
                                          src={item.bigImageFileName}
                                          alt=""
                                        />
                                      </div>
                                      <div className="details__div">
                                        <span className="style__txt">
                                          {"Style No"}: {item.intDiamondId}
                                        </span>

                                        <span className="price__txt">
                                          {item.realPrice == "0" ? (
                                            "Call For Price"
                                          ) : (
                                            <>
                                              US$
                                              {parseInt(item.realPrice)
                                                .toFixed()
                                                .replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  ","
                                                )}
                                            </>
                                          )}
                                        </span>
                                      </div>
                                      <div className="editdel__div">
                                        <div
                                          onClick={() => {
                                            handleOpenVideoImageModal(
                                              item,
                                              true
                                            );
                                          }}
                                        >
                                          <img src={Video_icon} alt="" />
                                        </div>
                                      </div>
                                      {!productChange[item.intDiamondId] ? (
                                        <div
                                          className="info__div"
                                          onClick={() =>
                                            handleProductChangeOpen(
                                              item.intDiamondId
                                            )
                                          }
                                        >
                                          <img src={Info_icon} alt="" />
                                        </div>
                                      ) : (
                                        <div className="descr__div">
                                          <div className="overlay__block">
                                            <p>
                                              <span> {"Shape"} </span>
                                              <span>:</span>
                                              <span>{item.txtShape}</span>
                                            </p>
                                            <p>
                                              <span> {"Carats"} </span>
                                              <span>:</span>
                                              <span>{item.fltCarat}</span>
                                            </p>
                                            <p>
                                              <span> {"Color"} </span>
                                              <span>:</span>
                                              <span>{item.txtColor}</span>
                                            </p>
                                            <p>
                                              <span> {"Clarity"} </span>
                                              <span>:</span>
                                              <span> {item.txtClarity}</span>
                                            </p>
                                            <p>
                                              <span> {"Cut"} </span>
                                              <span>:</span>
                                              <span> {item.txtCutGrade}</span>
                                            </p>
                                            <p>
                                              <span> {"In House"} </span>
                                              <span>:</span>
                                              <span> {item.inhouse}</span>
                                            </p>

                                            <p>
                                              <span> {"Depth"} </span>
                                              <span>:</span>
                                              <span>{item.fltDepth}</span>
                                            </p>
                                            <p>
                                              <span> {"Table"} </span>
                                              <span>:</span>
                                              <span>{item.fltTable}</span>
                                            </p>
                                            <p>
                                              <span> {"Certificate"} </span>
                                              <span>:</span>
                                              <span>{item.txtCertificate}</span>
                                            </p>
                                            <p>
                                              <span> {"Polish"} </span>
                                              <span>:</span>
                                              <span>{item.txtPolish}</span>
                                            </p>
                                            <p>
                                              <span> {"Measurement"} </span>
                                              <span>:</span>
                                              <span>
                                                {item.txtMeasurements}
                                              </span>
                                            </p>
                                            <p>
                                              <span> {"Retail Price"} </span>
                                              <span>:</span>
                                              <span>
                                                {item.realPrice == "0" ? (
                                                  " Call For Price"
                                                ) : (
                                                  <span>
                                                    $
                                                    {parseInt(item.realPrice)
                                                      .toFixed()
                                                      .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                      )}
                                                  </span>
                                                )}
                                              </span>
                                            </p>
                                          </div>
                                          <div
                                            className="close__div"
                                            onClick={() =>
                                              handleProductChangeClose(
                                                item.intDiamondId
                                              )
                                            }
                                          >
                                            <img src={Close} alt="" />
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    {/* </Link> */}
                                  </div>
                                );
                              })
                            ) : (
                              <div>
                                <span className="mr-2">
                                  <span
                                    className="linkText"
                                    onClick={() => setHideShowFilter(true)}
                                  >
                                    reset
                                  </span>{" "}
                                  your filters
                                </span>
                                <div>
                                  <Empty />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </Spin>
                  )}
                  {viewItem === "list" && (
                    <Spin spinning={loading}>
                      <div className="col-md-12">
                        <div className="lviewprod__tbl info__tooltip__table">
                          <Table
                            columns={columns}
                            className={
                              tableClassAllow == true
                                ? "info__tooltip__table overflow-visible"
                                : "info__tooltip__table"
                            }
                            onChange={handleTableASCDES}
                            onRow={(record, recordIndex) => ({
                              onClick: (event) => {
                                handleSetRowDetils(event, record);
                              },
                            })}
                            rowClassName={(record) =>
                              record.intDiamondId == rowDetails.intDiamondId
                                ? "row-active"
                                : ""
                            }
                            dataSource={minedList}
                            scroll={{ x: 600 }}
                            pagination={false}
                          />
                        </div>
                        <div className="table__bottom__btn">
                          <div>
                            <button
                              className="primary-btn"
                              onClick={() => handleCompareRedirect()}
                            >
                              Add To Compare
                            </button>
                          </div>
                          <div>
                            <Pagination
                              current={pageNo}
                              pageSize={pageSize}
                              total={TotalRowCount}
                              onChange={onChange}
                              onShowSizeChange={onShowSizeChange}
                              showSizeChanger="true"
                            />
                          </div>
                        </div>
                      </div>
                    </Spin>
                  )}
                </div>
              </div>
            ) : (
              <div class="col-md-12">
                <Spin spinning={loading}>
                  <div class="table__block filter_section p-0">
                    <h5 className="subheading">
                      {" "}
                      No Diamonds Listed In That Range{" "}
                    </h5>
                    <p>
                      {" "}
                      Please enter your contact information. One of our diamond
                      specialists will contact you and assist you with your
                      needs.{" "}
                    </p>
                    <div class="row p-0 border-0 form__fields mh-auto">
                      <div class="col-md-6">
                        <div class="input__block">
                          <label> First Name </label>
                          <input
                            type="text"
                            placeholder="Enter First Name"
                            name="firstName"
                            value={diamondFormData.firstName}
                            onChange={(e) =>
                              handleInputDiamondDataChange(e, "firstName")
                            }
                          />
                          <div>
                            {validations.firstNameVal && (
                              <p className="error-color-red">
                                {validations.firstNameVal}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="input__block">
                          <label> Last Name </label>
                          <input
                            type="text"
                            placeholder="Enter Last Name"
                            name="lastName"
                            value={diamondFormData.lastName}
                            onChange={(e) =>
                              handleInputDiamondDataChange(e, "lastName")
                            }
                          />
                          <div>
                            {validations.lastNameVal && (
                              <p className="error-color-red">
                                {validations.lastNameVal}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="input__block">
                          <label> Phone Number </label>
                          <input
                            type="text"
                            placeholder="Enter Phone Number"
                            name="phoneNumber"
                            value={diamondFormData.phoneNumber}
                            onChange={(e) =>
                              handleInputDiamondDataChange(e, "phoneNumber")
                            }
                          />
                          <div>
                            {validations.phoneNumberVal && (
                              <p className="error-color-red">
                                {validations.phoneNumberVal}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="input__block">
                          <label> Email Address </label>
                          <input
                            type="text"
                            placeholder="Enter Email Address"
                            name="emailAddress"
                            value={diamondFormData.emailAddress}
                            onChange={(e) =>
                              handleInputDiamondDataChange(e, "emailAddress")
                            }
                          />
                          <div>
                            {validations.emailAddressVal && (
                              <p className="error-color-red">
                                {validations.emailAddressVal}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="input__block">
                          <label> Comments ...</label>
                          <textarea
                            name="comments"
                            value={diamondFormData.comments}
                            onChange={(e) =>
                              handleInputDiamondDataChange(e, "comments")
                            }
                            placeholder="Add Comments Here ..."
                          ></textarea>
                          <div>
                            {validations.commentsVal && (
                              <p className="error-color-red">
                                {validations.commentsVal}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="save__form">
                          <Button
                            loading={reqDiamondloading}
                            className="primary-btn mr-2"
                            onClick={handleInputDiamondDataRequest}
                          >
                            {" "}
                            Request{" "}
                          </Button>
                          <Button
                            className="primary-btn"
                            onClick={handleInputDiamondDataReset}
                          >
                            {" "}
                            Reset{" "}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Spin>
              </div>
            )}
          </Wrapper>
        </div>
      </div>

      <Modal
        title="Certificate"
        visible={viewCertModal}
        style={{ top: 20 }}
        className="cert_modal"
        onCancel={closeCertModal}
        footer={[
          <Button key="back" onClick={closeCertModal}>
            Cancel
          </Button>,
        ]}
      >
        <div className="row">
          <div>
            <Spin spinning={iframeLoading}>
              <iframe
                width="100%"
                height="450"
                onLoad={hideSpinner}
                src={certRow.certificateLink}
              />
            </Spin>
          </div>
        </div>
      </Modal>

      <Modal
        className="modalconsupld__section"
        style={{ top: 20 }}
        title={"Video"}
        width={500}
        visible={videoImageModal}
        onCancel={() => handleCloseVideoImageModal()}
        footer={[
          <Button onClick={() => handleCloseVideoImageModal()}>
            {"Cancel"}
          </Button>,
        ]}
      >
        <div className="col-lg-12">
          <div className="modal__body">
            <div className="col-lg-12">
              <div className="form__fields border-0 p-0">
                <div className="row">
                  {isVideo ? (
                    selectedRowDetails.videoFileName ? (
                      <iframe
                        width="100%"
                        height="450"
                        title="diamond-video"
                        className="VideoIframe"
                        src={selectedRowDetails.videoFileName}
                      ></iframe>
                    ) : (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )
                  ) : selectedRowDetails.imageFileName ? (
                    <img
                      src={selectedRowDetails.imageFileName}
                      alt="img-diamond"
                    />
                  ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        title="Request Certificate"
        visible={requestCertModal}
        style={{ top: 20 }}
        className="request_info"
        onCancel={closeCertInfoModal}
        footer={[
          <Button key="back" onClick={closeCertInfoModal}>
            Cancel
          </Button>,
          <Button
            key="submit"
            loading={requestCertModalLoading}
            type="primary"
            onClick={handleSubmitRequestCert}
          >
            Request
          </Button>,
        ]}
      >
        <div className="row">
          <div class="col-md-12">
            <div className="table__block">
              <div className="row p-0 border-0 form__fields mh-auto">
                <div className="col-md-6">
                  <div className="input__block">
                    <label>Name</label>
                    <input
                      type="text"
                      value={certInfoData.name}
                      onChange={(e) => handleCertInfoChange(e, "name", "input")}
                    />
                    <div>
                      {certInfoValidation.nameVal && (
                        <p className="error-color-red">
                          {certInfoValidation.nameVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>E-Mail Address</label>
                    <input
                      type="text"
                      value={certInfoData.email}
                      onChange={(e) =>
                        handleCertInfoChange(e, "email", "input")
                      }
                    />
                    <div>
                      {certInfoValidation.emailVal && (
                        <p className="error-color-red">
                          {certInfoValidation.emailVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>Phone Number</label>
                    <input
                      type="text"
                      value={certInfoData.phoneNumber}
                      onChange={(e) =>
                        handleCertInfoChange(e, "phoneNumber", "input")
                      }
                    />
                    <div>
                      {certInfoValidation.phoneNumberVal && (
                        <p className="error-color-red">
                          {certInfoValidation.phoneNumberVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="input__block">
                    <label>Comments</label>
                    <textarea
                      value={certInfoData.comments}
                      onChange={(e) =>
                        handleCertInfoChange(e, "comments", "input")
                      }
                    >
                      <div>
                        {certInfoValidation.commentsVal && (
                          <p className="error-color-red">
                            {certInfoValidation.commentsVal}
                          </p>
                        )}
                      </div>
                    </textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default MinedTab;
