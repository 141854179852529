// let config = {
//   AppsAPIURL: "https://gem-appsapi-demo.brainvire.net/api",
// };

let config = {
  AppsAPIURL: "https://jc2-apps-api.stage.jewelcloud.com/api",
  AdminAPIURL: "https://jc2-admin-api.stage.jewelcloud.com/api",
};

export default config;
