import React, { useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  Outlet,
} from "react-router-dom";
import ChooseYourSetting from "./component/ApplicationPreview/ChooseYourSetting";
import SelectYourDiamond from "./component/ApplicationPreview/SelectYourDiamond/SelectYourDiamond";
import CompleteYourRing from "./component/ApplicationPreview/CompleteYourRing";
import Layout from "./component/Layout";

import { useDispatch, useSelector } from "react-redux";
import MinedTab from "./component/ApplicationPreview/SelectYourDiamond/MinedTab";
import Lab from "./component/ApplicationPreview/SelectYourDiamond/LabGrown";
import FancyTab from "./component/ApplicationPreview/SelectYourDiamond/FancyColor";
import Compare from "./component/ApplicationPreview/SelectYourDiamond/Compare";
import Request from "./component/ApplicationPreview/SelectYourDiamond/Request";
import ColorPanel from "./component/ColorPanel";

// import ChooseSettingItemDetails from "./component/ApplicationPreview/ChooseSettingItemDetails";

const PrivateRoute = () => {
  const dispatch = useDispatch();
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

const App = () => {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route path="/viewDiamondDetails" element={<CompleteYourRing />} />
          <Route exact path="/" element={<PrivateRoute />}>
            <Route path="/Mined" element={<MinedTab />} />
            <Route path="/Lab" element={<Lab />} />
            <Route path="/Fancy" element={<FancyTab />} />
            <Route path="/Request" element={<Request />} />
            <Route path="/Compare" element={<Compare />} />
          </Route>
        </Routes>
        <ColorPanel></ColorPanel>
      </BrowserRouter>
    </React.Fragment>
  );
};

export default App;
