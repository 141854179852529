import { httpApps, httpAdminCompanyLocation } from "../helper/http-common";

class SelectYourDiamondService {
  GetSimilarDiamondsByPage(inputData, pageName) {
    if (pageName == "Mined") {
      return httpApps.post("DiamondLink/LoadDiamonds", inputData);
    } else if (pageName == "Lab") {
      return httpApps.post("DiamondLink/LabGrownDiamonds", inputData);
    } else {
      return httpApps.post(
        "DiamondLink/GetDiamondLInkFancyDiamonds",
        inputData
      );
    }
  }

  GetDiamondLInkFancyDiamonds(inputData) {
    return httpApps.post("DiamondLink/GetDiamondLInkFancyDiamonds", inputData);
  }

  GetDiamondLInkMinedDiamonds(inputData) {
    return httpApps.post("DiamondLink/LoadDiamonds", inputData);
  }
  LabGrownDiamonds(inputData) {
    return httpApps.post("DiamondLink/LabGrownDiamonds", inputData);
  }
  LoadCompareDiamondsList(inputData) {
    return httpApps.post("DiamondLink/LoadCompareDiamondsList", inputData);
  }
  GetDiamondLinkFiltersDropHint(inputData) {
    return httpApps.post(
      "DiamondLink/GetDiamondLinkFiltersDropHint",
      inputData
    );
  }
  GetDiamondLinkFiltersRequestMoreInfo(inputData) {
    return httpApps.post(
      "DiamondLink/GetDiamondLinkFiltersRequestMoreInfo",
      inputData
    );
  }
  GetDiamondLinkFiltersEmailToFriend(inputData) {
    return httpApps.post(
      "DiamondLink/GetDiamondLinkFiltersEmailToFriend",
      inputData
    );
  }
  GetDiamondLinkFiltersScheduleviewing(inputData) {
    return httpApps.post(
      "DiamondLink/GetDiamondLinkFiltersScheduleviewing",
      inputData
    );
  }
  GetnNavigationList(inputData) {
    return httpApps.post("DiamondLink/GetnNavigationList", inputData);
  }
  GetDiamondLinkFiltersViewStateData(inputData) {
    return httpApps.post(
      "DiamondLink/GetDiamondLinkFiltersViewStateData",
      inputData
    );
  }

  GetDiamondLinkFiltersRequestCertificate(inputData) {
    return httpApps.post(
      "DiamondLink/GetDiamondLinkFiltersRequestCertificate",
      inputData
    );
  }
  LabFilter(inputData) {
    return httpApps.post("DiamondLink/LabFilter", inputData);
  }
  GetDiamondLInkFancyDiamondsFiltersData(inputData) {
    return httpApps.post(
      "DiamondLink/GetDiamondLInkFancyDiamondsFiltersData",
      inputData
    );
  }
  GetDiamondLinkFiltersRequest(inputData) {
    return httpApps.post("DiamondLink/GetDiamondLinkFiltersRequest", inputData);
  }
  GetDealerInformation(inputData) {
    return httpApps.post("DiamondLink/GetDealerInformation", inputData);
  }
  GetDealerLocationData(inputData) {
    return httpApps.post("DiamondLink/GetDealerLocationData", inputData);
  }
  GetDealerSocialLinksData(inputData) {
    return httpApps.post("DiamondLink/GetDealerSocialLinksData", inputData);
  }
  GetOptionsDetails(inputData) {
    return httpApps.post("DiamondLink/GetOptionsDetails", inputData);
  }
  GetLocationDetails(inputData) {
    return httpAdminCompanyLocation.post(
      "/Profile/LocationLoadDetails",
      inputData
    );
  }
}

export default new SelectYourDiamondService();
