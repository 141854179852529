import React, { useState, useEffect } from "react";
import { notification, Spin } from "antd";

import { Tabs, Tab } from "react-bootstrap";

import SaveIcon from "../../../assets/images/choose-setting/save__icon.svg";
import ResetIcon from "../../../assets/images/choose-setting/reset__icon.svg";

import MinedTab from "./MinedTab";
import LabGrown from "./LabGrown";
import Compare from "./Compare";
import Fancy from "./FancyColor";
import Request from "./Request";

import { useSelector, useDispatch } from "react-redux";
import CompleteYourRing from "../CompleteYourRing";
import {
  handleResetMinedPage,
  handleResetLabPage,
  handleResetFancyPage,
  handleResetRequestPage,
  handleSetSaveSearchMinedTab,
  handleSetSaveFilterMinedTab,
  handleSetSwitchFilter,
  handleSetSaveSearchLabTab,
  handleSetSaveFilterLabTab,
  handleSetSwitchLabFilter,
  handleSetSaveSearchFancyTab,
  handleSetSaveFilterFancyTab,
  handleSetSwitchFancyFilter,
  handleComaparePageIds,
  handleResetLabToggle,
  handleResetFancyToggle,
  handleResetMinedToggle,
} from "../../../actions/selectyourdiamond/selectYourDiamond";

import {
  handleSetLoginDetails,
  handleResetApp,
} from "../../../actions/LoginDetails/loginDetails";
import {
  handleSetPage,
  handleHideViewPage,
} from "../../../actions/PageNavigations/PageNavigations";
import { useLocation } from "react-router-dom";
import SelectYourDiamondService from "../../../services/select-your-diamond.service";
import styled from "styled-components";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { NavLink, useNavigate } from "react-router-dom";
import colorPanelService from "../../../services/color-panel.service";

const SelectYourDiamond = () => {
  const navigate = useNavigate();
  const pageNavigation = useSelector((state) => state.pageNavigationReducer);
  const CompareIds = useSelector(
    (state) => state.selectYourDiamondReducer.compareIds
  );
  const dispatch = useDispatch();
  const search = useLocation().search;
  const [navigationData, setNavigationData] = useState([]);
  const [orderNumbers, setOrderNumbers] = useState([]);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSocial, setShowSocial] = useState({});
  const [showSocialLoading, setShowSocialLoading] = useState({});
  const [optionLoading, setShowOptionsLoading] = useState(false);
  const [saveSearchMined, setSaveSearchMined] = useState();
  const [optionData, setOptionData] = useState({});
  const dealerId = new URLSearchParams(search).get("DealerLink");
  const isReset = new URLSearchParams(search).get("ResetIFrame");
  const isChange = new URLSearchParams(search).get("isChange");
  useEffect(() => {
    dispatch(handleSetLoginDetails(dealerId));
    handleGetNavigationData(dealerId);
  }, []);
  const [key, setKey] = useState("Mined");

  const loginDetails = useSelector((state) => state.loginDetailsReducer);
  useEffect(() => {
    if (isReset == "true") {
      dispatch(handleResetApp());
      if (dealerId) {
        dispatch(handleSetLoginDetails(dealerId));
      }
    }
  }, [isReset]);

  useEffect(() => {
    if (loginDetails) {
      if (loginDetails.dealerId) {
        if (loginDetails.dealerId !== dealerId) {
          dispatch(handleResetApp());
          dispatch(handleSetLoginDetails(dealerId));
        }
      }
    }
  }, [dealerId]);

  useEffect(() => {
    if (pageNavigation) {
      setKey(pageNavigation.key);
      console.log(pageNavigation.key);
    }
    console.log(pageNavigation);
  }, [pageNavigation]);

  const handlTabChanges = (val) => {
    setKey(val);
    if (val == "Mined") {
      navigate("/Mined" + `?DealerLink=${dealerId}`);
    } else if (val == "Lab Grown") {
      navigate("/Lab" + `?DealerLink=${dealerId}`);
    } else if (val == "Fancy Colored") {
      navigate("/Fancy" + `?DealerLink=${dealerId}`);
    } else if (val == "Request") {
      navigate("/Request" + `?DealerLink=${dealerId}`);
    } else if (val == "Compare") {
      var compareIds = CompareIds.toString();
      navigate("/Compare" + `?DealerLink=${dealerId}&CompareIds=${compareIds}`);
    } else {
      navigate("/Mined" + `?DealerLink=${dealerId}`);
    }
    if (val !== "Compare") {
      dispatch(handleComaparePageIds(""));
    }
  };

  const handleTabName = () => {
    if (window.location.href.indexOf("Request") > -1) {
      setKey("Request");
    } else if (window.location.href.indexOf("Lab") > -1) {
      setKey("Lab Grown");
    } else if (window.location.href.indexOf("Fancy") > -1) {
      setKey("Fancy Colored");
    } else if (window.location.href.indexOf("Mined") > -1) {
      setKey("Mined");
    } else {
      setKey("Compare");
    }
  };

  const handleSaveSearchForPage = () => {
    if (key == "Mined") {
      console.log("RESETMINED");
      //setSaveSearchMined(true);
      //dispatch(handleResetMinedToggle());
      dispatch(handleSetSaveSearchMinedTab(true));
      dispatch(handleResetMinedPage(false));
      dispatch(handleSetSwitchFilter());
    }
    if (key == "Lab Grown") {
      console.log("RESETLAB");
      //setSaveSearchMined(true);
      //dispatch(handleResetLabToggle());
      dispatch(handleSetSaveSearchLabTab(true));
      dispatch(handleResetLabPage(false));
      dispatch(handleSetSwitchLabFilter());
    }
    if (key == "Fancy Colored") {
      console.log("RESETFANCY");
      //setSaveSearchMined(true);
      // dispatch(handleResetFancyToggle());
      dispatch(handleSetSaveSearchFancyTab(true));
      dispatch(handleResetFancyPage(false));
      dispatch(handleSetSwitchFancyFilter());
    }
    NotificationManager.success("Search Saved Successfully.");
  };

  const handleResetForPage = () => {
    if (key == "Mined") {
      console.log("RESETMINED");
      dispatch(handleResetMinedToggle());
      dispatch(handleResetMinedPage(true));
      dispatch(handleSetSaveSearchMinedTab(false));
      //dispatch(handleSetSaveFilterMinedTab(obj));
      // dispatch(handleSetSaveSearchMinedTab(true));
    }
    if (key == "Lab Grown") {
      console.log("RESETLAB");
      dispatch(handleResetLabToggle());
      dispatch(handleResetLabPage(true));
      dispatch(handleSetSaveSearchLabTab(false));
      //dispatch(handleSetSaveFilterLabTab(obj));
      // dispatch(handleSetSaveSearchLabTab(true));
    }
    if (key == "Fancy Colored") {
      console.log("RESETFANCY");
      dispatch(handleResetFancyToggle());
      dispatch(handleResetFancyPage(true));
      dispatch(handleSetSaveSearchFancyTab(false));
      //dispatch(handleSetSaveFilterFancyTab(objFancy));
      // dispatch(handleSetSaveSearchFancyTab(true));
    }
    if (key == "Request") {
      console.log("RESETREQUEST");
      dispatch(handleResetRequestPage());
    }
  };

  const handleGetNavigationData = async (id) => {
    let inputData = {
      dealerLink: parseInt(id),
    };
    setLoading(true);
    try {
      await SelectYourDiamondService.GetnNavigationList(inputData)
        .then((response) => {
          let msg = response.data.message;
          let responseData = response.data.responseData;
          // let responseData = {
          //   dealerid: 720,
          //   labData: [
          //     {
          //       setting: "True",
          //       order: "1",
          //       name: "Lab",
          //     },
          //   ],
          //   minedData: [
          //     {
          //       setting: "True",
          //       order: "2",
          //       name: "Mined",
          //     },
          //   ],
          //   advancedSearchData: [
          //     {
          //       setting: "True",
          //       order: "6",
          //       name: "Advance",
          //     },
          //   ],
          //   requestDiamondData: [
          //     {
          //       setting: "True",
          //       order: "6",
          //       name: "Request",
          //     },
          //   ],
          //   compareDiamondData: [
          //     {
          //       setting: "True",
          //       order: "6",
          //       name: "Compare",
          //     },
          //   ],
          //   fancyData: [
          //     {
          //       setting: "True",
          //       order: "2",
          //       name: "FancyColor",
          //     },
          //   ],
          // };
          if (responseData) {
            let navigationArray = [];
            // if (responseData.advancedSearchData) {
            //   navigationArray.push(responseData.advancedSearchData[0]);
            // }
            setShowAdvanced(responseData.advancedSearchData[0]);
            if (responseData.labData) {
              navigationArray.push(responseData.labData[0]);
            }
            if (responseData.fancyData) {
              navigationArray.push(responseData.fancyData[0]);
            }
            if (responseData.minedData) {
              navigationArray.push(responseData.minedData[0]);
            }
            if (responseData.requestDiamondData) {
              navigationArray.push(responseData.requestDiamondData[0]);
            }
            if (responseData.compareDiamondData) {
              navigationArray.push(responseData.compareDiamondData[0]);
            }

            let newArray = [];
            for (let i = 0; i < navigationArray.length + 1; i++) {
              newArray.push((i + 1).toString());
            }

            // let newArray = navigationArray.map((item, index) => {
            //   return (index + 1).toString();
            // });

            setOrderNumbers(newArray);
            console.log(newArray);

            let newminArr = navigationArray
              .filter((x) => x.setting == "True")
              .map((x) => {
                return x.order;
              });
            console.log(newminArr);
            var val = Math.min(...newminArr);
            console.log(val);
            let newActiveTab = navigationArray
              .filter((item) => {
                return item.setting == "True" && item.order == val;
              })[0]
              .name.toString();

            // dispatch(handleSetPage(newActiveTab));
            // setKey(newActiveTab);
            if (isChange == "True") {
              if (newActiveTab == "Mined") {
                navigate("/Mined" + `?DealerLink=${dealerId}`);
              } else if (newActiveTab == "Lab Grown") {
                navigate("/Lab" + `?DealerLink=${dealerId}`);
              } else if (newActiveTab == "Fancy Colored") {
                navigate("/Fancy" + `?DealerLink=${dealerId}`);
              } else if (newActiveTab == "Request") {
                navigate("/Request" + `?DealerLink=${dealerId}`);
              } else if (newActiveTab == "Compare") {
                navigate("/Compare" + `?DealerLink=${dealerId}`);
              } else {
                navigate("/Mined" + `?DealerLink=${dealerId}`);
              }
              dispatch(handleSetPage(newActiveTab));
              setKey(newActiveTab);
            } else {
              handleTabName();
            }

            console.log(navigationArray);
            setNavigationData(navigationArray);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  /* Color Individually Start */

  const [isLightHeaderColor, setIsLightHeaderColor] = useState(false);
  const [isLightButtonColor, setIsLightButtonColor] = useState(false);
  const [isLightHoverColor, setIsLightHoverColor] = useState(false);
  const [wrapperLoading, setWrapperLoading] = useState(false);

  const previewcolor = useSelector((state) => state.previewColorReducer);
  const [varColorObj, setVarColorObj] = useState({
    callToActionColor: "",
    hoverColor: "",
    columnHeaderAccentColor: "",
    linkColor: "",
  });
  const [toggle, setToggle] = useState(true);
  const paramdealerId = new URLSearchParams(search).get("DealerLink");
  useEffect(() => {
    if (paramdealerId) {
      handleGetColorsData(paramdealerId);
    }
  }, [previewcolor.toogle]);

  const handleGetColorsData = async (id) => {
    let inputData = {
      dealerID: Number(id),
      moduleName: "DiamondLink",
      hoverEffect: "",
      columnHeaderAccent: "",
      linkColor: "",
      actionButton: "",
    };
    setWrapperLoading(true);
    try {
      await colorPanelService
        .GetHoverEffect(inputData)
        .then((response) => {
          let msg = response.data.message;
          let initialColorObj =
            response.data.responseData.initialHoverEffectList[0];
          let newColorObj =
            response.data.responseData.ringBuilder_HoverEffect_List[0];
          let colorObj = {
            hoverEffect: "",
            columnHeaderAccent: "",
            linkColor: "",
            actionButton: "",
          };

          if (msg == "Success") {
            if (initialColorObj) {
              var tempobj = colorObj;
              tempobj.hoverEffect = newColorObj
                ? newColorObj.hoverEffect == ""
                  ? initialColorObj.hoverEffect
                  : newColorObj.hoverEffect
                : initialColorObj.hoverEffect;
              tempobj.columnHeaderAccent = newColorObj
                ? newColorObj.columnHeaderAccent == ""
                  ? initialColorObj.columnHeaderAccent
                  : newColorObj.columnHeaderAccent
                : initialColorObj.columnHeaderAccent;
              tempobj.linkColor = newColorObj
                ? newColorObj.linkColor == ""
                  ? initialColorObj.linkColor
                  : newColorObj.linkColor
                : initialColorObj.linkColor;
              tempobj.actionButton = newColorObj
                ? newColorObj.actionButton == ""
                  ? initialColorObj.actionButton
                  : newColorObj.actionButton
                : initialColorObj.actionButton;
              debugger;
              console.log(tempobj);
              console.log(initialColorObj);
              if (tempobj) {
                setVarColorObj({
                  ...varColorObj,
                  callToActionColor: tempobj.actionButton,
                  hoverColor: tempobj.hoverEffect,
                  columnHeaderAccentColor: tempobj.columnHeaderAccent,
                  linkColor: tempobj.linkColor,
                });
                lightOrDark(tempobj.columnHeaderAccent, "header");
                lightOrDark(tempobj.callToActionColor, "button");
                lightOrDark(tempobj.hoverColor, "hover");
              } else {
                setVarColorObj({
                  ...varColorObj,
                  callToActionColor: initialColorObj.actionButton,
                  hoverColor: initialColorObj.hoverEffect,
                  columnHeaderAccentColor: initialColorObj.columnHeaderAccent,
                  linkColor: initialColorObj.linkColor,
                });
                lightOrDark(initialColorObj.columnHeaderAccent, "header");
                lightOrDark(initialColorObj.callToActionColor, "button");
                lightOrDark(initialColorObj.hoverColor, "hover");
              }
              // dispatch(handleSwitchChange());
            }
          }
          setWrapperLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setWrapperLoading(false);
        });
    } catch (error) {
      console.log(error);
      setWrapperLoading(false);
    }
  };

  function lightOrDark(color, type) {
    // Variables for red, green, blue values
    var r, g, b, hsp;

    if (color) {
      // Check the format of the color, HEX or RGB?
      if (color.match(/^rgb/)) {
        // If RGB --> store the red, green, blue values in separate variables
        color = color.match(
          /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
        );

        r = color[1];
        g = color[2];
        b = color[3];
      } else {
        // If hex --> Convert it to RGB: http://gist.github.com/983661
        color = +(
          "0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&")
        );

        r = color >> 16;
        g = (color >> 8) & 255;
        b = color & 255;
      }

      // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
      hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

      // Using the HSP value, determine whether the color is light or dark
      // if hsp> 127.5 then it is dark
      if (hsp > 127.5) {
        if (type == "header") {
          setIsLightHeaderColor(true);
        } else if (type == "button") {
          setIsLightButtonColor(true);
        } else {
          setIsLightHoverColor(true);
        }
      } else {
        if (type == "header") {
          setIsLightHeaderColor(false);
        } else if (type == "button") {
          setIsLightButtonColor(false);
        } else {
          setIsLightHoverColor(false);
        }
      }
    }
  }

  const Wrapper = styled.div`
    & .whole__shapes .shapes__block .image:hover {
      border: 1px solid ${varColorObj.hoverColor};
    }

    & .whole__shapes .shapes__block:hover .image {
      border: 1px solid ${varColorObj.hoverColor};
    }

    & .whole__shapes .shapes__block .image .selected {
      border: 1px solid ${varColorObj.linkColor};
    }

    & .whole__shapes .shapes__block.selected .image {
      border: 1px solid ${varColorObj.linkColor};
    }

    & .img__block .imgselct__div:hover {
      border-bottom: 2px solid ${varColorObj.hoverColor};
    }

    & .img__block .imgselct__div.selected {
      border-bottom: 2px solid ${varColorObj.linkColor};
    }

    & .selection__block .selection__box:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background-color: ${varColorObj.hoverColor};
    }
    & .selection__block .selection__box.selected {
      border: 1px solid ${varColorObj.linkColor};
      background-color: ${varColorObj.linkColor};
    }

    & .ant-table-thead > tr > th {
      background: ${varColorObj.columnHeaderAccentColor} !important;
      font-family: "LexendDeca-Semibold";
      height: 50px;
      color: ${isLightHeaderColor ? "#3b4452" : "#ffffff"} !important;
      line-height: 14px;
    }

    & .ant-btn-primary {
      background: ${varColorObj.callToActionColor} !important;
      border-color: ${varColorObj.callToActionColor} !important;
    }
    & .ant-btn-primary:hover {
      background: ${varColorObj.hoverColor} !important;
      border-color: ${varColorObj.hoverColor} !important;
    }

    & .primary-btn {
      background: ${varColorObj.callToActionColor} !important;
      border-radius: 5px !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .primary-btn:hover {
      background: ${varColorObj.hoverColor} !important;
      border-radius: 5px !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .ant-btn {
      background: ${varColorObj.callToActionColor} !important;
      border-radius: 5px !important;
      color: ${isLightButtonColor ? "#3b4452" : "#fff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .ant-btn:hover {
      background: ${varColorObj.hoverColor} !important;
      border-radius: 5px !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }
    & .ant-input-search-button {
      background: #fff !important;
      border: 1px solid #d9d9d9;
      border-radius: 0 !important;
      height: 32px;
      border-left: 0;
    }

    & .ant-input-search-button:hover {
      background: #fff !important;
      border: 1px solid #d9d9d9;
      border-radius: 0 !important;
      height: 32px;
      border-left: 0;
    }
    & .linkText {
      color: ${varColorObj.linkColor};
      text-decoration: none;
      cursor: pointer;
    }

    & .linkText:hover {
      color: ${varColorObj.hoverColor};
    }

    & .iframe__block .itemdetails__btns p:hover img {
      filter: invert(64%) sepia(29%) saturate(4028%) hue-rotate(164deg)
        brightness(93%) contrast(89%);
      color: ${varColorObj.hoverColor};
    }

    & .iframe__block .itemdetails__btns p:hover span {
      color: ${varColorObj.hoverColor};
    }

    & .ant-pagination-item-active {
      border: 1px solid ${varColorObj.linkColor} !important;
    }

    & .ant-pagination-item:hover {
      border: 1px solid ${varColorObj.hoverColor};
    }
    & .nav-tabs .nav-link.active {
      background: ${varColorObj.columnHeaderAccentColor} !important;
      color: ${isLightHeaderColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .list_icon.active {
      border: 1px solid ${varColorObj.callToActionColor};
      background: ${varColorObj.callToActionColor} !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .grid_icon.active {
      border: 1px solid ${varColorObj.callToActionColor};
      background: ${varColorObj.callToActionColor} !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .grid_icon:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background: ${varColorObj.hoverColor} !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .list_icon:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background: ${varColorObj.hoverColor} !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .ant-input-search-button {
      color: rgba(0, 0, 0, 0.45) !important;
    }
    & .ant-input-search-button:hover {
      color: rgba(0, 0, 0, 0.45) !important;
    }
  `;

  /* Color Individually Ends */

  function getTextStyle(name) {
    if (key == name) {
      return {
        color: "#fff",
        background: `${varColorObj.columnHeaderAccentColor}`,
      };
    }
  }

  ///Color files end

  return (
    <React.Fragment>
      {/* {pageNavigation.viewPage == true ? (
        <div>
          <CompleteYourRing />
        </div>
      ) : ( */}
      <div className="allprod_section jwlsrch__section app__preview choose__setting">
        <Spin spinning={loading || wrapperLoading}>
          <div className="row">
            <div className="col-md-12">
              <div className="prodetail__section">
                <div className="col-md-12">
                  <div className="prod__maindiv app__preview p-0 border-0">
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="prodesc__div">
                          <div className="tright__btns">
                            {key == "Compare" || key == "Request" ? null : (
                              <p onClick={handleSaveSearchForPage}>
                                <img />
                                <span>Save Search</span>
                              </p>
                            )}
                            {key == "Compare" ? null : (
                              <p onClick={handleResetForPage}>
                                <img src={ResetIcon} />
                                <span>Reset</span>
                              </p>
                            )}
                          </div>
                          <div className="custom__tab">
                            <ul>
                              {orderNumbers.map((order) => {
                                return navigationData.map((tab) => {
                                  return (
                                    tab.setting == "True" &&
                                    order == tab.order && (
                                      <li
                                        style={getTextStyle(tab.name)}
                                        onClick={() =>
                                          handlTabChanges(tab.name)
                                        }
                                        title={tab.name}
                                      >
                                        <div className="mr-2 nav-btn">
                                          {tab.name == "Compare"
                                            ? key == "Compare"
                                              ? "Compare"
                                              : "Compare" +
                                                " " +
                                                "(" +
                                                CompareIds.length +
                                                ")"
                                            : tab.name == "Fancy Colored"
                                            ? tab.name.split(/(?=[A-Z])/)[0] +
                                              " " +
                                              tab.name.split(/(?=[A-Z])/)[1]
                                            : tab.name}
                                        </div>
                                      </li>
                                    )
                                  );
                                });
                              })}
                            </ul>
                          </div>

                          {/* <Tabs
                                activeKey={key}
                                defaultActiveKey={key}
                                id="uncontrolled-tab-example"
                                className="tab__div border-0"
                                onSelect={(k) => handlTabChanges(k)}
                              >
                                {orderNumbers.map((order) => {
                                  return navigationData.map((tab) => {
                                    return (
                                      tab.setting == "True" &&
                                      order == tab.order && (
                                        <div>
                                          <Tab
                                            eventKey={tab.name}
                                            title={tab.name}
                                          ></Tab>
                                        </div>
                                      )
                                    );
                                  });
                                })}
                              </Tabs> */}

                          {/* {key == "Mined" && <MinedTab />}
                              {key == "Lab Grown" && <LabGrown />}
                              {key == "Fancy Colored" && <Fancy />}
                              {key == "Request" && <Request />}
                              {key == "Compare" && <Compare />} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
      {/* )} */}
    </React.Fragment>
  );
};

export default SelectYourDiamond;
