import React, { useState, useEffect } from "react";
import {
  Tooltip,
  Select,
  Table,
  Slider,
  Checkbox,
  Spin,
  Pagination,
  Modal,
  Button,
  Empty,
  Input,
} from "antd";

import Eye_icon from "../../../assets/images/icons/eye_icon.svg";

import Delete_iconbl from "../../../assets/images/icons/delete_iconbl.svg";

import roundDiamond from "../../../assets/images/daimond-link.jpg";
import CompareImg from "../../../assets/images/compare-diamond.png";
import { useDispatch, useSelector } from "react-redux";
import SelectYourDiamondService from "../../../services/select-your-diamond.service";
import { handleSetDiamondDetails } from "../../../actions/selectyourdiamond/selectYourDiamond";
import { handleShowViewPage } from "../../../actions/PageNavigations/PageNavigations";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";

export const Compare = () => {
  const navigate = useNavigate();
  const search = useLocation().search;
  const dispatch = useDispatch();
  const CompareIds = useSelector(
    (state) => state.selectYourDiamondReducer.compareIds
  );
  const CompareKey = useSelector(
    (state) => state.selectYourDiamondReducer.compareKey
  );
  const ParamCompareIds = new URLSearchParams(search).get("CompareIds");
  const loginDetails = useSelector((state) => state.loginDetailsReducer);
  const [keyValue, setKeyValue] = useState([
    "image",
    "txtShape",
    "intDiamondId",
    "fltCarat",
    "txtColor",
    "fltTable",
    "modifiedDate",
    "txtPolish",
    "txtSymmetry",
    "txtClarity",
    "txtFluorescence",
    "fltDepth",
    "txtMeasurements",
    "txtCertificate",
    "txtCutGrade",
    "realPrice",
    "action",
  ]);
  const [loading, setLoading] = useState(false);
  const [compareList, setCompareList] = useState([]);
  const [dealerId, setDealerId] = useState();

  const [viewCertModal, setviewCertModal] = useState(false);
  const [iframeLoading, setIframeLoading] = useState(true);
  const [certRow, setCertRow] = useState({});
  const paramdealerId = new URLSearchParams(search).get("DealerLink");

  useEffect(() => {
    if (paramdealerId) {
      if (ParamCompareIds) {
        handleLoadCompareDiamondsList(paramdealerId, ParamCompareIds);
      }
      setDealerId(paramdealerId);
    }
  }, []);

  const handleLoadCompareDiamondsList = (id, CompareIds) => {
    try {
      let inputData = {
        dealerID: id.toString(),
        diamondsList: CompareIds.toString(),
        themeName: "WhiteTheme",
        showPrice: "1",
        detailViewURL: "DiamondDetail.aspx",
        cType: "D",
        extraQStr: "&SID=&Shape=&CTW=&Size=&ThemeName=WhiteTheme",
        isdiamond: 0,
        custID: "",
        countryTaxRate: "",
        vendorID: "",
        track: "0",
        isFB: 0,
        isMLDL: 0,
        myRetailerID: "0",
        template: "",
        isLabGrown: false,
      };
      setLoading(true);
      SelectYourDiamondService.LoadCompareDiamondsList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message == "Success") {
            if (responseData) {
              setCompareList(responseData);
              setLoading(false);
            } else {
              setCompareList([]);
              setLoading(false);
            }
          } else {
            setCompareList([]);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleDeleteCol = (id) => {
    var filteredArr = compareList.filter((item) => item.intDiamondId !== id);
    setCompareList(filteredArr);
  };

  const handleDelRow = (val) => {
    var filteredArr = keyValue.filter((item) => item !== val);
    setKeyValue(filteredArr);
  };

  const handleViewDetails = (details) => {
    var obj = details;
    obj["pageName"] = CompareKey;
    obj["shapeFilters"] = [];
    // dispatch(handleShowViewPage());
    // dispatch(handleSetDiamondDetails(obj));
    navigate(
      "/viewDiamondDetails" +
        `?isSocialPage=True&DealerID=${obj.gfLinkID}&diamondId=${obj.intDiamondId}&pageNo=1&isAllowed=True&pageName=${obj.pageName}&shapeFilters=${obj.shapeFilters}&txtCutlet=${obj.txtCulet}&txtClarity=${obj.txtClarity}&intColorPriority=${obj.intColorPriority}`
    );
    window.parent.postMessage("submitted form", "*");
  };

  const handleCertModalOpen = (row) => {
    console.log(row);
    setCertRow(row);
    setviewCertModal(true);
  };
  const closeCertModal = () => {
    setCertRow({});
    setviewCertModal(false);
  };

  const hideSpinner = () => {
    setIframeLoading(false);
  };

  return (
    <React.Fragment>
      {/* <span className="back__btn"> {"<< Change Diamond"} </span> */}
      <div className="allprod_section jwlsrch__section app__preview choose__setting">
        <div className="tabs__content border__block">
          <div className="diamond__details filter_section p-0 mt-3">
            <Spin spinning={loading}>
              <div className="row">
                <div className="col-md-12">
                  <div className="compare__table custom__table">
                    <table class="table  table-striped">
                      <tbody>
                        {keyValue.map((val) => (
                          <tr>
                            <th>
                              <div className="headingtable">
                                {val == "image" ? (
                                  ""
                                ) : val == "action" ? (
                                  ""
                                ) : (
                                  <React.Fragment>
                                    <i
                                      class="fa fa-times-circle-o"
                                      aria-hidden="true"
                                      onClick={() => handleDelRow(val)}
                                    ></i>
                                    <span>
                                      {val == "txtShape"
                                        ? "Shape"
                                        : val == "intDiamondId"
                                        ? "SKU"
                                        : val == "fltCarat"
                                        ? "Carat"
                                        : val == "txtColor"
                                        ? "Color"
                                        : val == "fltTable"
                                        ? "Table"
                                        : val == "modifiedDate"
                                        ? "Date Added"
                                        : val == "fltDepth"
                                        ? "Depth"
                                        : val == "txtPolish"
                                        ? "Polish"
                                        : val == "txtSymmetry"
                                        ? "Symmetry"
                                        : val == "txtClarity"
                                        ? "Clarity"
                                        : val == "txtFluorescence"
                                        ? "Fluorescence"
                                        : val == "txtFluorescence"
                                        ? "Fluorescence"
                                        : val == "txtMeasurements"
                                        ? "Measurements"
                                        : val == "txtCertificate"
                                        ? "Certificate"
                                        : val == "txtCutGrade"
                                        ? "Cut Grade"
                                        : val == "realPrice"
                                        ? "Price"
                                        : val}
                                    </span>
                                  </React.Fragment>
                                )}
                              </div>
                            </th>
                            {compareList.map((item) => {
                              return val == "image" ? (
                                <React.Fragment>
                                  <td>
                                    <img
                                      style={{ width: "120px" }}
                                      src={item[val]}
                                    />
                                  </td>
                                </React.Fragment>
                              ) : val == "modifiedDate" ? (
                                <React.Fragment>
                                  <td>
                                    {moment(item[val]).format("MM/DD/YYYY")}
                                  </td>
                                </React.Fragment>
                              ) : val == "action" ? (
                                <React.Fragment>
                                  <td>
                                    <img
                                      src={Eye_icon}
                                      onClick={() => handleViewDetails(item)}
                                      className="mr-2 icon__width Hand__pointer"
                                    />
                                    <img
                                      src={Delete_iconbl}
                                      onClick={() =>
                                        handleDeleteCol(item.intDiamondId)
                                      }
                                      className="icon__width Hand__pointer"
                                    />
                                  </td>
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  <td>
                                    {val == "txtCertificate" ? (
                                      <p
                                        onClick={() => handleCertModalOpen(item)}
                                        className="link-Text"
                                      >
                                        {item[val]}
                                      </p>
                                    ) : (
                                      item[val]
                                    )}
                                  </td>
                                </React.Fragment>
                              );
                            })}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Spin>
            <div className="row">
              <div className="col-md-8">
                <div className="text__block">
                  <p>
                    Use your diamond comparison to save all the details of the
                    diamonds you are considering and view them all together.
                  </p>
                  <p>This makes it easy to choose the right diamond.</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="image__block">
                  <img src={CompareImg} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Certificate"
        visible={viewCertModal}
        style={{ top: 20 }}
        className="cert_modal"
        onCancel={closeCertModal}
        footer={[
          <Button key="back" onClick={closeCertModal}>
            Cancel
          </Button>,
        ]}
      >
        <div className="row">
          <div>
            <Spin spinning={iframeLoading}>
              <iframe
                width="100%"
                height="450"
                onLoad={hideSpinner}
                src={certRow.certificateLink}
              />
            </Spin>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default Compare;
