import { SHOW_VIEW_PAGE, HIDE_VIEW_PAGE, SET_KEY_NAME } from "./types";

export const handleShowViewPage = () => async (dispatch) => {
  try {
    dispatch({
      type: SHOW_VIEW_PAGE,
    });
  } catch (error) {}
};

export const handleHideViewPage = () => async (dispatch) => {
  try {
    dispatch({
      type: HIDE_VIEW_PAGE,
    });
  } catch (error) {}
};

export const handleSetPage = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_KEY_NAME,
      payload: data,
    });
  } catch (error) {}
};
