import { PropertySafetyFilled } from "@ant-design/icons";
import React, { Fragment, useEffect, useState } from "react";
import SelectYourDiamond from "./ApplicationPreview/SelectYourDiamond/SelectYourDiamond";
import ColorPanel from "./ColorPanel";
import Header from "./Header";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

export const Layout = (props) => {
  const dispatch = useDispatch();
  const search = useLocation().search;
  const [interval, setInterval] = useState();

  return (
    <Fragment>
      <div className="form__fields m-2 iframe__block p-0 border-0 bg-transparent">
        <div>
          <SelectYourDiamond />
        </div>
        {props.children}
        <div className="powered__by">
          <p>PoweredBy By Gemfind</p>
        </div>
        {/* <ColorPanel /> */}
      </div>
    </Fragment>
  );
};

export default Layout;
